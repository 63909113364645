import React, { useState, useEffect } from "react";
import moment from "moment";
import { Offcanvas } from "react-bootstrap";
import { setDefaultDocumentsSearchParams } from "@helpers/configMiddlewares/manageDocuments";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import styles from "./DocuSignReport.module.scss";
import { IDocuSignFilterEditorProps } from "@helpers/uiInterfaces/docu-sign-report";
import { IDocuSignReportSearchParams } from "@helpers/interfaces/docuSignReport";

const DocuSignFilterEditor: React.FC<IDocuSignFilterEditorProps> = (props) => {
  const { open, filterData, onClose, onSearch } = props;

  const [_filterData, _setFilterData] =
    useState<IDocuSignReportSearchParams>(filterData);

  useEffect(() => {
    _setFilterData(filterData);
  }, [filterData]);

  function renderControlsSection() {
    const cancelControlAttributes = {
      className: "btn btnorg w100 border-button",
      onClick: onClose,
    };

    const searchControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        onSearch(_filterData);
      },
    };

    const resetControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        const defaultValues = setDefaultDocumentsSearchParams();
        _setFilterData(defaultValues);
        onSearch(defaultValues);
      },
    };

    return (
      <div className={`mdl-footer`}>
        <button {...cancelControlAttributes}>Cancel</button>
        <button {...resetControlAttributes}>Reset</button>
        <button {...searchControlAttributes}>Search</button>
      </div>
    );
  }

  function renderContent() {
    const fromDate = _filterData.fromDate
      ? moment(_filterData.fromDate).format("YYYY-MM-DD")
      : moment().clone().startOf("month").format("YYYY-MM-DD");
    const toDate = _filterData.toDate
      ? moment(_filterData.toDate).format("YYYY-MM-DD")
      : moment().clone().endOf("month").format("YYYY-MM-DD");

    const fromDateControlAttributes = {
      label: "From Date",
      name: "fromDate",
      value: fromDate,
      onChange(value: string) {
        _setFilterData({
          ..._filterData,
          fromDate: value,
        });
      },
    };

    const toDateControlAttributes = {
      label: "To Date",
      name: "toDate",
      value: toDate,
      onChange(value: string) {
        _setFilterData({
          ..._filterData,
          toDate: value,
        });
      },
    };

    return (
      <Offcanvas.Body className={styles.docusignReportFilterEditorMain}>
        <div>
          <FormDateInputControl {...fromDateControlAttributes} />
          <FormDateInputControl {...toDateControlAttributes} />
        </div>

        {renderControlsSection()}
      </Offcanvas.Body>
    );
  }

  return (
    <Offcanvas show={open} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Search & Filter DocuSign Reports</Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default DocuSignFilterEditor;

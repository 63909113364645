import React from "react";

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IOrderSummarySelectedFiltersProps } from "@helpers/uiInterfaces/order-summary";

const OrderSummarySelectedFilters: React.FC<
  IOrderSummarySelectedFiltersProps
> = (props) => {
  const { filters, onFilterChange } = props;

  function handleFilterChange(name: string) {
    let data: any = { ...filters };
    data[name] = "";
    onFilterChange(data);
  }

  function renderRemoveFilterControl(name: string) {
    const removeFilterControlAttributes = {
      icon: faCircleXmark,
      className: "remove-filter-control",
      onClick() {
        handleFilterChange(name);
      },
    };

    return <FontAwesomeIcon {...removeFilterControlAttributes} />;
  }

  function renderFilterChip(label: string, name: string, value: string) {
    const filterChipLabelAttributes = {
      title: `${label}: ${value}`,
    };

    return (
      <>
        {value !== "All" && (
          <div className="filter-chip">
            <label {...filterChipLabelAttributes}>
              {label}: {value}
            </label>
            {renderRemoveFilterControl(name)}
          </div>
        )}
      </>
    );
  }

  function renderSearchNameFilter() {
    if (filters.searchText.trim() === "") {
      return;
    }

    return renderFilterChip("Name", "searchText", filters.searchText);
  }

  function renderMonthFilter() {
    if (!filters.month) {
      return;
    }

    return renderFilterChip("Month", "month", filters.month);
  }

  function renderYearFilter() {
    if (!filters.year) {
      return;
    }

    return renderFilterChip("Year", "year", filters.year);
  }

  function renderOrderStatusFilter() {
    if (!filters.orderStatus) {
      return;
    }

    return renderFilterChip("Order Status", "orderStatus", filters.orderStatus);
  }

  function renderOrderSourceFilter() {
    if (!filters.orderSource) {
      return;
    }

    return renderFilterChip("Order Source", "orderSource", filters.orderSource);
  }

  return (
    <div className="selected-filters">
      {renderMonthFilter()}
      {renderYearFilter()}
      {renderOrderStatusFilter()}
      {renderOrderSourceFilter()}
      {renderSearchNameFilter()}
    </div>
  );
};

export default OrderSummarySelectedFilters;

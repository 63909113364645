import { combineReducers } from '@reduxjs/toolkit';

import MilitaryDutyReducer from './MilitaryDuty';
import ClinicalWorkHistoryReducer from './ClinicalWorkHistory';
import GapHistoryReducer from './GapHistory';
import ProfessionalReferencesReducer from './ProfessionalReferences';

const workHistoryRefReducer = combineReducers({
  militaryDuty: MilitaryDutyReducer,
  clinicalWorkHistory: ClinicalWorkHistoryReducer,
  gapHistory: GapHistoryReducer,
  professionalReferences: ProfessionalReferencesReducer
});

export default workHistoryRefReducer;
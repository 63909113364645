import React, { Suspense, useEffect, useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "@hooks/index";

import employeeEditorTabs from "@constants/json-data/employee-files/employee-files";

import Spinner from "@common/spinner/Spinner";

import styles from "./Emp_AddEditEditor.module.scss";
import {
  IAddEditEmployeeEditorProps,
  IEmployeeEditorTab,
} from "@helpers/uiInterfaces/add-edit-employee";
import { employeeActions } from "@features/employeeFiles/Employee";
import { addEditEmployeesActions } from "@features/employeeFiles/AddEditEmployees";
import { gapsInCredentialingUIActions } from "@features/ui/GapsInCredentialingUI";
import { getEmployeeData } from "@services/employeeFiles";
import { renderPrefix } from "./utilities";

const Employee_AddEditTab = React.lazy(() => import("./Emp_AddEditTab"));
const Edit_EmployeeExclusions = React.lazy(
  () => import("./Edit_EmployeeExclusions")
);
const Edit_EmployeeTraining = React.lazy(
  () => import("./EditEmployeeTraining/Edit_EmployeeTraining")
);
const Edit_EmployeeOnboarding = React.lazy(
  () => import("./Edit_EmployeeOnboarding")
);
const Edit_EmployeeCredentialing = React.lazy(
  () => import("./Edit_EmployeeCredentialing")
);
const Edit_EmployeeGapsInCred = React.lazy(
  () => import("./Edit_EmployeeGapsInCred")
);
const Edit_EmployeePayer = React.lazy(() => import("./Edit_EmployeePayer"));
const Edit_EmployeePortal = React.lazy(
  () => import("./Emp_EditEmployeePortal")
);
const Edit_EmployeePrivileging = React.lazy(
  () => import("./Edit_EmployeePrivileging")
);
const Edit_CredentialingCompletion = React.lazy(
  () => import("./EditEmployeeCompleteCredentialing/Edit_CredentialingCompletion")
);

const AddEditEmployeeEditor: React.FC<IAddEditEmployeeEditorProps> = (
  props
) => {
  const { open, data, isTraining, onClose, updateSelectedData } = props;

  const dispatch = useAppDispatch();

  const employeeState = useAppSelector((state) => state.employeeFiles.employee);

  const [tabIndex, setTabIndex] = useState(1);
  const [showCredentialing, setShowCredentialing] = useState(false);
  const [showAddDocumentSection, setShowAddDocumentSection] = useState(false);
  const employeeData = useAppSelector(
    (state) => state.employeeFiles.employee.employeeData
  );

  useEffect(() => {
    if (isTraining && data) setTabIndex(4);
  }, [isTraining]);

  useEffect(() => {
    if (data) dispatch(getEmployeeData(data.hrmrUserId));
  }, [data]);

  useEffect(() => {
    if (data) {
      const sliceState = {
        employeeName: `${data.firstName} ${data.lastName}`,
        hrmrUserId: data.hrmrUserId,
        enable: true,
      };

      dispatch(
        gapsInCredentialingUIActions.updateGapsInCredentialingState(sliceState)
      );
      dispatch(getEmployeeData(data.hrmrUserId));
    }
  }, [data, tabIndex]);

  useEffect(() => {
    return () => onUnmount();
  }, []);

  function onUnmount() {
    dispatch(employeeActions.resetEditorState());
    dispatch(addEditEmployeesActions.resetState());
  }

  function handleTabsNavigation(_tabIndex: number) {
    // navigate user only when employee record is available

    if (
      !employeeState.employeeData?.hrmrUserId &&
      _tabIndex !== 1 &&
      !isTraining
    ) {
      return;
    }

    if (showCredentialing) {
      setShowCredentialing(false);
    }
    if (showAddDocumentSection) {
      setShowAddDocumentSection(false);
    }
    setTabIndex(_tabIndex);
  }

  function renderEmployeeTab(tab: IEmployeeEditorTab, index: number) {
    let tabClassName = "nav-link";

    if (tabIndex === tab.index) {
      tabClassName += " active";
    }

    if (
      !employeeState.employeeData?.hrmrUserId &&
      tab.index !== 1 &&
      !isTraining
    ) {
      tabClassName += " disable";
    }

    const tabLinkControlAttributes = {
      className: tabClassName,
      role: "tab",
      key: index,
      onClick() {
        handleTabsNavigation(tab.index); // to disable click on tabs if the case if of create employee
      },
    };

    return (
      <li className="nav-item" key={index}>
        <a {...tabLinkControlAttributes}>{tab.label}</a>
      </li>
    );
  }

  function renderTabsContent() {
    const employeeTabContentAttributes = {
      tabIndex,
      data,
      onNavigate(_tabIndex: number) {
        setTabIndex(_tabIndex);
      },
      onClose,
    };

    const addEditEmployeeTabContentAttributes = {
      ...employeeTabContentAttributes,
      updateSelectedData,
    };

    const addEditEmployeeCredentialingTabContentAttributes = {
      ...addEditEmployeeTabContentAttributes,
      setShowCredentialing,
      showCredentialing,
    };

    const addEditEmployeeOnboardingTabContentAttributes = {
      ...addEditEmployeeTabContentAttributes,
      showAddDocumentSection,
      setShowAddDocumentSection,
    };

    switch (tabIndex) {
      case 1:
        return <Employee_AddEditTab {...addEditEmployeeTabContentAttributes} />;
      case 2:
        return <Edit_EmployeePortal {...addEditEmployeeTabContentAttributes} />;
      case 3:
        return <Edit_EmployeeExclusions {...employeeTabContentAttributes} />;
      case 4:
        return <Edit_EmployeeTraining {...employeeTabContentAttributes} />;
      case 5:
        return (
          <Edit_EmployeeOnboarding
            {...addEditEmployeeOnboardingTabContentAttributes}
          />
        );
      case 6:
        return (
          <Edit_EmployeeCredentialing
            {...addEditEmployeeCredentialingTabContentAttributes}
          />
        );
      case 7:
        return <Edit_EmployeeGapsInCred {...employeeTabContentAttributes} />;
      case 8:
        return <Edit_EmployeePayer {...employeeTabContentAttributes} />;
      case 9:
        return <Edit_EmployeePrivileging {...employeeTabContentAttributes} />;
      case 10:
        return (
          <Edit_CredentialingCompletion {...employeeTabContentAttributes} />
        );
      default:
        break;
    }
  }

  function renderTabHeader() {
    return (
      <div className="thirdLevelTabs">
        <div className="scrollbar-2">
          <div className="sub_scrollbar">
            <ul className="nav nav-tabs mt-2" role="tablist">
              {employeeEditorTabs.map(
                (tab: IEmployeeEditorTab, index: number) =>
                  renderEmployeeTab(tab, index)
              )}
            </ul>
          </div>
        </div>

        <div className="tab-content">
          <Suspense fallback={<Spinner />}>{renderTabsContent()}</Suspense>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <Offcanvas.Body>
        <div id={styles.employeeEditorMain}>
          <div className="tab_form_content">
            <div className="inner_tabsWrapper">{renderTabHeader()}</div>
          </div>
        </div>
      </Offcanvas.Body>
    );
  }

  const employeeName = ` - ${employeeData?.firstName || ""} ${
    employeeData?.lastName || ""
  } ${employeeData?.credentialsName ? "," : ""} ${
    employeeData?.credentialsName ? employeeData.credentialsName : ""
  }`;
  const pronoun = renderPrefix(employeeData.pronouns);
  return (
    <Offcanvas
      show={open}
      onHide={onClose}
      placement="end"
      style={{ width: "85vw" }}
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>
          Add/Edit Employee
          {employeeName}{" "}
          <span className={styles.pronoun}>
            {pronoun ? ` (${pronoun})` : ""}
          </span>
        </Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default AddEditEmployeeEditor;

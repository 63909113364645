import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultPrimaryCredentialingContact } from '@helpers/configMiddlewares/serviceSite';
import { IServSitePrimaryCredentialingContactModel } from '@helpers/interfaces/serviceSites/PrimaryCredentialingContact';

const initialState = {
  primaryCredentialingContactData: {} as IServSitePrimaryCredentialingContactModel,
  primaryCredentialingContactDataList: [] as Array<IServSitePrimaryCredentialingContactModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const primaryCredentialingContactData = createSlice({
  name: 'primaryCredentialingContact',
  initialState,
  reducers: {

    updatePrimaryCredentialingContactData: (state, action: PayloadAction<IServSitePrimaryCredentialingContactModel>) => {
      state.primaryCredentialingContactData = action.payload;
    },

    updatePrimaryCredentialingContactdataList: (state, action: PayloadAction<Array<IServSitePrimaryCredentialingContactModel>>) => {
      state.primaryCredentialingContactDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.primaryCredentialingContactData = setDefaultPrimaryCredentialingContact();
    }

  }
});

export const primaryCredentialingContactAction = primaryCredentialingContactData.actions;
export default primaryCredentialingContactData.reducer;
import { combineReducers } from '@reduxjs/toolkit';

import ActivityReducer from './Activity';
import DocumentTypeReducer from './DocumentType';
import ChecklistReducer from './Checklist';

const activityChecklistReducers = combineReducers({
  credentialActivity: ActivityReducer,
  documentType: DocumentTypeReducer,
  credentialChecklist: ChecklistReducer
});

export default activityChecklistReducers;
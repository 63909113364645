import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { stateLicenseAction } from "@features/credentialProfile/perProfInfoTab/StateLicense";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleCreateServiceResponse, handleDeleteServiceResponse, handleServerInteractionError } from "@utilities/services";
import { IDeleteStateLicenseParamsModel, IPerProf_StateLicenseModel } from "@helpers/interfaces/credentialProfile/PerProfInfoTab";

function showLoading(dispatch: any) {
  dispatch(stateLicenseAction.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(stateLicenseAction.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(stateLicenseAction.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(stateLicenseAction.updateEditorLoading(false));
}

export const getStateLicense = (stateLicenseId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    showEditorLoading(dispatch);

    try {
      const response = await axiosClient.get('api/CredentialingProfile/GetStateLicense?StateLicenseId=' + stateLicenseId);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        dispatch(stateLicenseAction.updateStateLicenseData(response.data));
        resolve(response);
      } else {
        dispatch(toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER
        }));
      }

      hideEditorLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });

};

export const getAllStateLicense = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {
    try {

      showLoading(dispatch);

      const response = await axiosClient.get('api/CredentialingProfile/GetStateLicenseAll?HRMRUserId=' + hrmrUserId);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(stateLicenseAction.updateStateLicenseDataList(data));

        resolve(response);
        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};

export const createStateLicense = (data: IPerProf_StateLicenseModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/CreateStateLicense",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleCreateServiceResponse(dispatch, stateLicenseAction, status, response.data);

      hideEditorLoading(dispatch);
      resolve(response);
    }
    catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }

  });

}

export const deleteStateLicense = (data: IDeleteStateLicenseParamsModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {
    try {

      showLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "/api/CredentialingProfile/DeleteStateLicense",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleDeleteServiceResponse(dispatch, status, response.data);

      hideLoading(dispatch);
      resolve(response);
    }
    catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

}
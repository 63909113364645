import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IOrganizationProfileModel } from '@helpers/interfaces/manageProfile/organizationProfile';

const initialState = {
  organizationProfileData: {} as IOrganizationProfileModel,
  error: null as any,
  loading: false
}

const organizationProfileSlice = createSlice({
  name: 'organizationProfile',
  initialState,
  reducers: {

    updateOrganizationProfileData: (state, action: PayloadAction<IOrganizationProfileModel>) => {
      state.organizationProfileData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetTrainingDetailsReportState: (state) => {
      state.loading = false;
      state.error = null;
      state.organizationProfileData = {} as IOrganizationProfileModel;
    }

  }
});

export const organizationProfileActions = organizationProfileSlice.actions;
export default organizationProfileSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMastersDataModel } from '@helpers/interfaces/masters/Masters';
import { IActivityModel } from '@helpers/interfaces/activityChecklist/Activity';
import { IManageDocumentsParentModel } from '@helpers/interfaces/manageDocuments/AddEditDocuments';

import { getApplicationStorage } from '@utilities/storage';
import { setDefaultManageDocumentData } from '@helpers/configMiddlewares/manageDocuments/AddEditCredentialing';

const initialState = {
  employeeList: [] as Array<IMastersDataModel>,
  activityByEmpId: [] as Array<IActivityModel>,
  manageDocumentsRecord: null as IManageDocumentsParentModel | null,
  error: null as any,
  loading: false
}

const addEditDocumentsSlice = createSlice({
  name: 'addEditDocuments',
  initialState,
  reducers: {

    updateEmployeeList: (state, action: PayloadAction<Array<IMastersDataModel>>) => {
      state.employeeList = action.payload;
    },

    updateActivityByEmpId: (state, action: PayloadAction<Array<IActivityModel>>) => {
      state.activityByEmpId = action.payload;
    },

    updateManageDocumentsRecord: (state, action: PayloadAction<IManageDocumentsParentModel>) => {
      state.manageDocumentsRecord = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeeList = [] as Array<IMastersDataModel>;
      state.activityByEmpId = [] as Array<IActivityModel>;
      state.manageDocumentsRecord = setDefaultManageDocumentData();
    }

  }
});

export const addEditDocumentsActions = addEditDocumentsSlice.actions;
export default addEditDocumentsSlice.reducer;
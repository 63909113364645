import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  error: null as any,
  loading: false,
  editorLoading: false,
};

const fileTaskSlice = createSlice({
  name: "fileTask",
  initialState,
  reducers: {
    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
});

export const fileTaskActions = fileTaskSlice.actions;
export default fileTaskSlice.reducer;

import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { IGetManageDocumentsFiltersParams } from "@helpers/uiInterfaces/manage-documents";
import { IDeleteManageDocumentParamsModel } from "@helpers/interfaces/manageDocuments";

import { manageDocumentsActions } from "@features/manageDocuments/ManageDocuments";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleServerInteractionError } from "@utilities/services";
import messages from "@constants/messages";

function showLoading(dispatch: any) {
  dispatch(manageDocumentsActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(manageDocumentsActions.updateLoading(false));
}

export const getActivities = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "api/ManageDocuments/GetActivities"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(manageDocumentsActions.updateActivities(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getDocumentTypeByActivityId =
  (activityId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/ManageDocuments/GetDocumentTypeByActivityId?ActivityId=${activityId}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(
            manageDocumentsActions.updateDocumentTypesByActivityId(data)
          );
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getManageDocumentsPaginatedList =
  (
    pageNumber: number,
    pageSize: number,
    filterParams: IGetManageDocumentsFiltersParams
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          searchName,
          activityId,
          docTypeId,
          includeHiddenDoc,
          fromDate,
          toDate,
          verifyFromDate,
          verifyToDate,
          guidelinesIds,
          locationIds,
          employeeType,
        } = filterParams;

        const hiddenDocValue = includeHiddenDoc
          ? "IncludeHidden"
          : "ExcludeHidden";

        showLoading(dispatch);

        let url = `api/ManageDocuments/GetManageActivitiesPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IncludeHiddenDoc=${hiddenDocValue}&EmployeeType=${employeeType}`;

        if (searchName) {
          url += `&Search=${searchName}`;
        }

        if (activityId) {
          url += `&ActivityId=${activityId}`;
        }

        if (docTypeId) {
          url += `&DocTypeId=${docTypeId}`;
        }

        if (fromDate) {
          url += `&FromDate=${fromDate}`;
        }

        if (toDate) {
          url += `&ToDate=${toDate}`;
        }

        if (verifyFromDate) {
          url += `&VerifyFromDate=${verifyFromDate}`;
        }

        if (verifyToDate) {
          url += `&VerifyToDate=${verifyToDate}`;
        }

        if (guidelinesIds) {
          url += `&GuidelinesIds=${guidelinesIds}`;
        }

        if (locationIds) {
          url += `&LocationIds=${locationIds}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(manageDocumentsActions.updateManageDocumentsList(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getManageDocumentsReport =
  (filterParams: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          name,
          activity,
          docType,
          includeHiddenDoc,
          fromDate,
          toDate,
          verifyFromDate,
          verifyToDate,
          guidelinesIds,
          locationIds,
          employeeType,
        } = filterParams;

        const hiddenDocValue = includeHiddenDoc
          ? "IncludeHidden"
          : "ExcludeHidden";

        let url = `api/ManageDocuments/GetManageActivitiesReport?IncludeHiddenDoc=${hiddenDocValue}`;

        if (name) {
          url += `&Search=${name}`;
        }

        if (employeeType) {
          url += `&EmployeeType=${employeeType}`;
        }

        if (activity) {
          url += `&ActivityId=${activity.activityId}`;
        }

        if (docType) {
          url += `&DocTypeId=${docType.docTypeId}`;
        }

        if (fromDate) {
          url += `&FromDate=${fromDate}`;
        }

        if (toDate) {
          url += `&ToDate=${toDate}`;
        }

        if (verifyFromDate) {
          url += `&VerifyFromDate=${verifyFromDate}`;
        }

        if (verifyToDate) {
          url += `&VerifyToDate=${verifyToDate}`;
        }

        if (guidelinesIds) {
          url += `&GuidelinesIds=${guidelinesIds}`;
        }

        if (locationIds) {
          url += `&LocationIds=${locationIds}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const hideUnhideManageDocumentRecord =
  (data: IDeleteManageDocumentParamsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "/api/ManageDocuments/HideUnhideManageActivityAndDocs",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;
        dispatch(
          toastMessageActions.showToast({
            message: data.isHide
              ? messages.dataSuccessfullyHidden
              : messages.dataSuccessfullyUnHidden,
            type: toastTypes.SUCCESS,
          })
        );
        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getAllManageDocumentsById =
  (id: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/ManageDocuments/GetAllManageDocsSignURL?ManageActId=${id}`
        );
        const data = response.data;

        if (response.status !== 200) {
          dispatch(
            toastMessageActions.showToast({
              message: data.message,
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getManageActivitiesByHRMRUserId =
  (
    id: number,
    pageNumber: number,
    pageSize: number,
    includeHiddenDoc: boolean
  ) =>
  async (dispatch: any) => {
    const hiddenDocValue = includeHiddenDoc ? "IncludeHidden" : "ExcludeHidden";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/ManageDocuments/GetManageActivitiesByHRMRUserId?HRMRUserId=${id}&PageNumber=${pageNumber}&PageSize=${pageSize}&IncludeHiddenDoc=${hiddenDocValue}`
        );
        const data = response.data;

        if (response.status !== 200) {
          dispatch(
            toastMessageActions.showToast({
              message: data.message,
              type: toastTypes.DANGER,
            })
          );
        } else {
          dispatch(manageDocumentsActions.updateManageDocumentListById(data));
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getDocumentTypeByHrmrUserId =
  (id: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/ManageDocuments/GetDocumentTypeByHRMRUserId?HRMRUserId=${id}`
        );
        const data = response.data;

        if (response.status !== 200) {
          dispatch(
            toastMessageActions.showToast({
              message: data.message,
              type: toastTypes.DANGER,
            })
          );
        } else {
          dispatch(manageDocumentsActions.updateDocumentTypeListById(data));
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getManageActDocHistoryLogsByManageActDocId =
  (pageNumber: number, pageSize: number, manageActDocId: number) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/ManageDocuments/ManageActDocHistoryLog?PageNumber=${pageNumber}&PageSize=${pageSize}&ManageActDocId=${manageActDocId}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(
            manageDocumentsActions.updateManageActDocHistoryLogsById(data)
          );
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

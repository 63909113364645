import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultDocumentType } from '@helpers/configMiddlewares/activityChecklist/Activity';
import { ICredentialActivityDocumentTypeModel, IDocumentTypePaginationModel } from '@helpers/interfaces/activityChecklist/DocumentType';

const initialState = {
  documentTypeData: {} as ICredentialActivityDocumentTypeModel,
  documentTypeListData: {} as IDocumentTypePaginationModel,
  error: null as any,
  loading: false
}

const documentTypeSlice = createSlice({
  name: 'documentType',
  initialState,
  reducers: {

    updateDocumentTypeData: (state, action: PayloadAction<ICredentialActivityDocumentTypeModel>) => {
      state.documentTypeData = action.payload;
    },

    updateDocumentTypeListData: (state, action: PayloadAction<IDocumentTypePaginationModel>) => {
      state.documentTypeListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.loading = false;
      state.error = null;
      state.documentTypeData = setDefaultDocumentType();
      state.documentTypeListData = {} as IDocumentTypePaginationModel;
    }

  }
});

export const documentTypeActions = documentTypeSlice.actions;
export default documentTypeSlice.reducer;
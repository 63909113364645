import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPracLocCoveringColleaguesModel } from '@helpers/interfaces/credentialProfile/PracticeLocationInfoTab';
import { setDefaultCoveringColleagues } from '@helpers/configMiddlewares/credentialingProfile/practLocationInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  coveringColleaguesData: {} as IPracLocCoveringColleaguesModel,
  coveringColleaguesListData: [] as Array <IPracLocCoveringColleaguesModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const coveringColleaguesSlice = createSlice({
  name: 'coveringColleagues',
  initialState,
  reducers: {

    updateCoveringColleaguesData: (state, action: PayloadAction<IPracLocCoveringColleaguesModel>) => {
      state.coveringColleaguesData = action.payload;
    },

    updateCoveringColleaguesDataList: (state, action: PayloadAction<Array<IPracLocCoveringColleaguesModel>>) => {
      state.coveringColleaguesListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.coveringColleaguesData = setDefaultCoveringColleagues(userRecord);
    }

  }
});

export const coveringColleaguesAction = coveringColleaguesSlice.actions;
export default coveringColleaguesSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IClinicalWorkHistoryModel } from '@helpers/interfaces/credentialProfile/WorkHistoryRefTab';
import { setDefaultClinicalHistoryData } from '@helpers/configMiddlewares/credentialingProfile/workHistoryRefTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  clinicalWorkHistoryData: {} as IClinicalWorkHistoryModel,
  clinicalWorkHistoryList: [] as Array<IClinicalWorkHistoryModel>,
  error: null as any,
  loading: false,
  editorLoading: false
};

const clinicalWorkHistory = createSlice({
  name: 'clinicalWorkHistory',
  initialState,
  reducers: {

    updateClinicalWorkHistoryData: (state, action: PayloadAction<IClinicalWorkHistoryModel>) => {
      state.clinicalWorkHistoryData = action.payload;
    },

    updateClinicalWorkHistoryDataList: (state, action: PayloadAction<Array<IClinicalWorkHistoryModel>>) => {
      state.clinicalWorkHistoryList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.clinicalWorkHistoryData = setDefaultClinicalHistoryData(userRecord);
    }

  }
});

export const clinicalWorkHistoryActions = clinicalWorkHistory.actions;
export default clinicalWorkHistory.reducer;
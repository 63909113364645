import { IOrderSummaryPaginationModel } from "@helpers/interfaces/psv/orderSummary";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  orderSummaryData: {} as IOrderSummaryPaginationModel,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const psvSlice = createSlice({
  name: "orderSummary",
  initialState,
  reducers: {
    updateOrderSummaryList: (state, action: PayloadAction<any>) => {
      state.orderSummaryData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.orderSummaryData = {} as IOrderSummaryPaginationModel;
      state.loading = false;
      state.error = null;
    },
  },
});

export const psvActions = psvSlice.actions;
export default psvSlice.reducer;

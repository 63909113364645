import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_OtherIdsModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultOtherIDInfo } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  otherIdsData: {} as IPerProf_OtherIdsModel,
  error: null as any,
  loading: false
}

const otherIdsSlice = createSlice({
  name: 'otherIds',
  initialState,
  reducers: {

    updateOtherIds: (state, action: PayloadAction<IPerProf_OtherIdsModel>) => {
      state.otherIdsData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.otherIdsData = setDefaultOtherIDInfo(userRecord);
    }

  }
});

export const otherIdsAction = otherIdsSlice.actions;
export default otherIdsSlice.reducer;
import toastTypes from "@constants/toast-types";
import { fileTaskActions } from "@features/fileTask";
import { toastMessageActions } from "@features/ui/ToastMessage";
import axiosClient from "@store/axiosClient";
import { handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(fileTaskActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(fileTaskActions.updateLoading(false));
}

export const downloadAzureBlobFile =
  (folderName: string, fileName: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);
        const response = await axiosClient.get(
          `api/FileTask/DownloadAzureBlobFile?FolderName=${folderName}&FileName=${fileName}`,
          {
            responseType: "blob",
          }
        );

        if (response.status === 200) {
          hideLoading(dispatch);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }
        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

import { getApplicationStorage } from "@utilities/storage";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

const requestHandler = (request: AxiosRequestConfig) => {

  // don't send authorization token in authenticate service
  if(request.url === 'api/Account/authenticate'){
    return request;
  }

  const applicationStorage = getApplicationStorage();
  const token = applicationStorage?.token;

  if (typeof request.headers === 'undefined') {
    return request;
  }

  // if token is available in the storage, then simple add authorization header in the request
  if (token !== null) {
    request.headers['Authorization'] = 'Bearer ' + token;
  }

  return request;

};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: AxiosError) => {

  const _error = error.response;

  if (typeof _error === 'undefined') {
    return error;
  }

  if (_error.status !== 500) {
    return _error;
  }

  throw new Error('Something went wrong. Internal server error.');

};

axiosClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosClient;
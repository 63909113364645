import { combineReducers } from '@reduxjs/toolkit';

import ActivityValidatorReducer from './ActivityValidator';
import ValidateNPIActivityReducer from './ValidateNPIActivity';
import ValidateFederalDEANumberReducer from './ValidateFederalDEANumber';
import ValidateStateLicenseActivityReducer from './ValidateStateLicenseActivity';
import ValidateNPIABMSActivityReducer from './ValidateNPIABMSActivity';

const activityValidatorReducer = combineReducers({
  activityValidatorState: ActivityValidatorReducer,
  npiValidator: ValidateNPIActivityReducer,
  federalDEAValidator: ValidateFederalDEANumberReducer,
  stateLicenseValidator: ValidateStateLicenseActivityReducer,
  npiABMSValidator: ValidateNPIABMSActivityReducer 
});

export default activityValidatorReducer;
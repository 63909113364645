import JSZip from "jszip";
import moment from "moment";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { read, utils } from "xlsx";

export async function downloadFileWithURLAndName(
  url: string,
  fileName: string
) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.target = "_blank";

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);

  return url;
}

export async function downloadBlob(blob: Blob, name: string) {
  const _blob = new Blob([blob]);
  const blobUrl = URL.createObjectURL(_blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = name;

  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(link);

  return link;
}

export async function downloadEncodedBlob(fileString: string, name: string) {
  try {
    // Decode the base64 encoded string to binary data
    const binaryData = atob(fileString);

    // Convert the binary data to Uint8Array
    const bytes = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array data
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);
    // Dispatch a click event on the link to trigger the download
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Cleanup: remove the link and revoke the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);

    return true; // Indicate success
  } catch (error) {
    console.error("Error downloading PDF:", error);
    return false; // Indicate failure
  }
}

interface SelectedRow {
  uri: string;
  activityName?: string; // Optional property if activity name is provided
  docType?: string; // Optional property if document type is provided
  expiryDate?: string;
}

export const downloadZip = async (
  selectedRows: SelectedRow[],
  userName: string,
  title: string
) => {
  try {
    const zip = new JSZip();

    // Helper function to extract file extension from URL
    const getFileExtension = (url: string) => {
      const path = url.split("?")[0].split("#")[0];
      const parts = path.split(".");
      return parts.length > 1 ? parts.pop() : "unknown";
    };

    // Helper function to sanitize filenames
    const sanitizeFilename = (filename: string) => {
      return filename.replace(/[/\\?%*:|"<>]/g, "_");
    };

    // Process each selected row
    const filePromises = selectedRows.map(async (row, index) => {
      const { uri, activityName, docType, expiryDate } = row;
      const expirydate = expiryDate
        ? moment(expiryDate).format("MM-DD-YYYY")
        : "";

      // Construct filename with unique index to avoid conflicts
      let name = `${userName}_${title}_${activityName || ""}_${
        docType || ""
      }_${expirydate}_${index}`;
      const extension = getFileExtension(uri);
      name = `${name}.${extension}`;

      // Sanitize the filename
      name = sanitizeFilename(name);

      try {
        const response = await fetch(uri);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${uri}: ${response.statusText}`);
        }
        const fileData = await response.blob();
        zip.file(name, fileData);
      } catch (error) {
        console.error(`Error fetching file from ${uri}:`, error);
      }
    });

    // Wait for all file fetches to complete
    await Promise.all(filePromises);

    // Generate ZIP file and download
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const a = document.createElement("a");
    a.href = zipUrl;
    a.download = `${userName}_${title}_Credentialing_File.zip`;
    a.click();
    URL.revokeObjectURL(zipUrl);
  } catch (error) {
    console.error("Error creating ZIP:", error);
  }
};

const addTextToPage = async (
  page: any,
  userName: string,
  documentDate: string
) => {
  // Get the dimensions of the page
  const { width, height } = page.getSize();

  const nameText = `Compliance Watchdog - ${userName} - ${documentDate}`;

  // Define text size and padding from the bottom
  const textSize = 12;
  const paddingFromBottom = 20;

  // Load font (you can use a built-in font or your own)
  const font = await page.doc.embedFont(StandardFonts.Helvetica);

  // Measure text width using font metrics
  const textWidth = font.widthOfTextAtSize(nameText, textSize);

  // Calculate x position to right-align the text
  const xPosition = width - textWidth - 50; // 50 is the padding from the right edge

  // Draw the text on the page
  page.drawText(nameText, {
    x: xPosition,
    y: paddingFromBottom,
    size: textSize,
    color: rgb(0, 0, 0),
    font: font,
  });
};

export const downloadMergedPdf = async (
  urls: Array<string>,
  userName: string,
  dates: Array<string>
) => {
  try {
    const mergedPdfBytes = await mergeFilesToPdf(urls, userName, dates);
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = "Credentialing-file.pdf";
    a.click();
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    console.error("Error creating merged PDF:", error);
  }
};

const mergeFilesToPdf = async (
  urls: Array<string>,
  userName: string,
  dates: Array<string>
) => {
  const pdfDoc = await PDFDocument.create();

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    const date = dates[i];
    const response = await fetch(url);
    const contentType = response.headers.get("Content-Type");

    if (contentType) {
      if (contentType.includes("application/pdf")) {
        const pdfBytes = await response.arrayBuffer();
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach((page) => {
          const dateToAdd = moment(date).format("MM/DD/YYYY");
          pdfDoc.addPage(page);
          // Drawing text on each page after adding it
          addTextToPage(page, userName, dateToAdd);
        });
      } else if (contentType.includes("image")) {
        const imageBytes = await response.arrayBuffer();
        let image;
        if (
          contentType.includes("image/jpeg") ||
          contentType.includes("image/jpg")
        ) {
          image = await pdfDoc.embedJpg(imageBytes);
        } else if (contentType.includes("image/png")) {
          image = await pdfDoc.embedPng(imageBytes);
        } else {
          throw new Error(`Unsupported image type: ${contentType}`);
        }

        const { width, height } = image;
        const page = pdfDoc.addPage([width, height]);
        page.drawImage(image, { x: 0, y: 0, width, height });
        addTextToPage(page, userName, date);
      } else if (
        contentType.includes("application/vnd.ms-excel") ||
        contentType.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        const arrayBuffer = await response.arrayBuffer();
        const workbook = read(arrayBuffer, { type: "array" });
        const sheetNames = workbook.SheetNames;
        for (const sheetName of sheetNames) {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData = utils.sheet_to_html(worksheet);
          const html = `
          <html>
            <body>
              <h1>${sheetName}</h1>
              ${sheetData}
            </body>
          </html>
        `;
          const pdfDocHtml = await PDFDocument.create();
          const page = pdfDocHtml.addPage([600, 800]);
          page.drawText(html);
          const pdfBytes = await pdfDocHtml.save();
          const pdf = await PDFDocument.load(pdfBytes);
          const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => {
            const dateToAdd = moment(date).format("MM/DD/YYYY");
            pdfDoc.addPage(page);
            // Drawing text on each page after adding it
            addTextToPage(page, userName, dateToAdd);
          });
        }
      }
    }
  }

  // Save the merged PDF
  return await pdfDoc.save();
};

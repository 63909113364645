import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { complianceWatchdogSetupActions } from "@features/setupIntegration/ComplianceWatchdog";

import { setDefaultComlianceWatchdogSetupDetails } from "@helpers/configMiddlewares/setupIntegrations/ComplianceWatchdog";

import {
  handleCreateServiceResponse,
  handleServerInteractionError,
} from "@utilities/services";
import { ICompliancewatchdogSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/ComlianceWatchdog";

function showLoading(dispatch: any) {
  dispatch(complianceWatchdogSetupActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(complianceWatchdogSetupActions.updateLoading(false));
}

export const getComlianceWatchdogSetupDetails = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showLoading(dispatch);

      const response = await axiosClient.get(
        "api/OrganizationIntegrationSetup/GetComplianceWatchDogServiceSetupDetail"
      );
      let data = response.data;
      const status = response.status;

      if (status === 200) {
        if (!data) {
          response.data = setDefaultComlianceWatchdogSetupDetails();
          resolve(response);
        } else {
          dispatch(
            complianceWatchdogSetupActions.updateComplianceWatchdogSetupDetails(
              data
            )
          );
          resolve(response);
        }

        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );

      hideLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

export const saveComlianceWatchdogSetupDetails =
  (data: ICompliancewatchdogSetupDetailsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/OrganizationIntegrationSetup/SaveComplianceWatchDogServiceSetup",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          complianceWatchdogSetupActions,
          status,
          response.data
        );

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

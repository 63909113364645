import { IDocCommentUISliceState } from "@helpers/uiInterfaces/manage-documents/doc-comment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  hrmrUserId: 0,
  employeeName: "",
  acitivityName: "",
  activityId: 0,
};

const docCommentUISlice = createSlice({
  name: "docCommentUI",
  initialState,
  reducers: {
    updateDocCommentUIState: (
      state,
      action: PayloadAction<IDocCommentUISliceState>
    ) => {
      state.employeeName = action.payload.employeeName;
      state.hrmrUserId = action.payload.hrmrUserId;
      state.acitivityName = action.payload.activityName;
      state.activityId = action.payload.activityId;
    },

    resetState: (state) => {
      state.employeeName = "";
      state.hrmrUserId = 0;
      state.acitivityName = "";
      state.activityId = 0;
    },
  },
});

export const docCommentUIActions = docCommentUISlice.actions;
export default docCommentUISlice.reducer;

import { IOrderSummaryModel } from "@helpers/interfaces/psv/orderSummary";
import moment from "moment";

export function parseOrderSummaryRows(rows: Array<IOrderSummaryModel>) {
  const _rows = [];

  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];

    const item = {
      ...row,
      orderPlacedDate: row.dateOrderPlaced
        ? moment(row.dateOrderPlaced).format("MM/DD/YYYY")
        : "",
      orderFulfilledDate: row.dateOrderFulfilled
        ? moment(row.dateOrderFulfilled).format("MM/DD/YYYY")
        : "",
    };
    _rows.push(item);
  }

  return _rows;
}

export function createOrderSummaryDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const dateOrderPlaced = item.dateOrderPlaced
      ? moment(item.dateOrderPlaced).format("MM/DD/YYYY")
      : "";
    const dateOrderFulfilled = item.dateOrderFulfilled
      ? moment(item.dateOrderFulfilled).format("MM/DD/YYYY")
      : "";

    const _item = {
      "Employee Name": item.fullName,
      "Primary Source Verification Type": item.primarySourceVerificationType,
      "Source": item.souce,
      "Date Order Placed": dateOrderPlaced,
      "Date order Fulfilled": dateOrderFulfilled,
      "Status": item.status,
      "Amount": item.amount,
    };

    _data.push(_item);
  });

  return _data;
}

import moment from "moment";

import { IActivityModel } from "@helpers/interfaces/activityChecklist/Activity";
import { ICredentialActivityDocumentTypeModel } from "@helpers/interfaces/activityChecklist/DocumentType";
import {
  IDocumentsSearchParams,
  IManageDocumentModel,
} from "@helpers/interfaces/manageDocuments";
import { IManageDocumentChildModel } from "@helpers/interfaces/manageDocuments/AddEditDocuments";

export function parseActivitiesForSelectControl(
  activities: Array<IActivityModel>
) {
  const _activities = [];

  for (let item of activities) {
    const object = {
      label: item.activityName,
      value: item.activityId,
    };

    _activities.push(object);
  }

  return _activities;
}

export function parseDocumentsTypeForSelectControl(
  documentTypes: Array<ICredentialActivityDocumentTypeModel>
) {
  const _documentTypes = [];

  for (let item of documentTypes) {
    const object = {
      label: item.docType,
      value: item.docTypeId,
    };

    _documentTypes.push(object);
  }

  return _documentTypes;
}

export function getActivityByActivityId(
  activities: Array<IActivityModel>,
  activityId: number
) {
  const activity = activities.find((item) => item.activityId === activityId);

  if (activity) {
    return activity;
  }

  return null;
}

export function getDocumentTypeByDocTypeId(
  documentTypes: Array<ICredentialActivityDocumentTypeModel>,
  docTypeId: number
) {
  const docType = documentTypes.find((item) => item.docTypeId === docTypeId);

  if (docType) {
    return docType;
  }

  return null;
}

export function extractFilterParams(filterData: IDocumentsSearchParams) {
  const data = {
    searchName: filterData.name,
    activityId: filterData.activity?.activityId,
    docTypeId: filterData.docType?.docTypeId,
    fromDate: filterData.fromDate,
    toDate: filterData.toDate,
    includeHiddenDoc: filterData.includeHiddenDoc,
    verifyFromDate: filterData.verifyFromDate,
    verifyToDate: filterData.verifyToDate,
    guidelinesIds: filterData.guidelinesIds,
    locationIds: filterData.locationIds,
    employeeType: filterData.employeeType,
  };

  return data;
}

export function parseManageDocumentsList(
  manageDocuments: Array<IManageDocumentModel>
) {
  const data = [];

  for (let document of manageDocuments) {
    const item = {
      ...document,
      multipleDocumentType: createMultipleValuesCollection(
        document.manageActiviyDocs,
        "docType",
        "STRING"
      ),
      multipleActivityNumber: createMultipleValuesCollection(
        document.manageActiviyDocs,
        "activityNumber",
        "STRING"
      ),
      multipleVerifiedDate: createMultipleValuesCollection(
        document.manageActiviyDocs,
        "verifiedDate",
        "DATE"
      ),
      multipleExpiryDate: createMultipleValuesCollection(
        document.manageActiviyDocs,
        "expiryDate",
        "DATE"
      ),
    };

    data.push(item);
  }

  return data;
}

function createMultipleValuesCollection(
  collections: Array<IManageDocumentChildModel>,
  key: string,
  type: string
) {
  const _data = [];

  for (let collection of collections) {
    if (type === "STRING") {
      let _value = collection[key as keyof IManageDocumentChildModel];

      // concatenating file name (custom doc type) into a docType label
      if (key === "docType" && collection.fileName) {
        _value += ` | ${collection.fileName}`;
      }

      _data.push(_value);
    } else if (type === "DATE") {
      const value = collection[key as keyof IManageDocumentChildModel];
      const date = value ? moment(value).format("MM/DD/YYYY") : "";

      _data.push(date);
    }
  }

  return _data;
}

export function createManageDocumentsDatasetReport(data: Array<any>) {
  const _data: Array<any> = [];

  data.forEach((item, index) => {
    const verifiedDate = item.verifiedDate
      ? moment(item.verifiedDate).format("MM/DD/YYYY")
      : "";
    const expiryDate = item.expiryDate
      ? moment(item.expiryDate).format("MM/DD/YYYY")
      : "";
    const activityDate = item.activityDate
      ? moment(item.activityDate).format("MM/DD/YYYY")
      : "";

    const _item = {
      "Employee Name": item.employeeName,
      "Credentialing Activity": item.activityName,
      "Document Type": item.docType,
      "Custom Document Type": item.customDocType,
      "Activity Number": item.activityNumber,
      "Verified Date": verifiedDate,
      "Expiry Date": expiryDate,
      "Activity Date": activityDate,
      "Updated By": item.updatedByName,
      "Note": item.description,
    };

    _data.push(_item);
  });

  return _data;
}

export async function downloadMultipleDocuments(
  documents: Array<IManageDocumentChildModel>
) {
  for (let _document of documents) {
    const fileURL = _document.uri;
    if (fileURL) {
      const anchor = document.createElement("a");
      anchor.href = fileURL;
      anchor.download = _document.fileName;
      anchor.target = "_blank";

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(fileURL);
    }
  }

  return null;
}

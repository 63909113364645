import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGapsInCredParentModel } from '@helpers/interfaces/manageDocuments/GapsInCred';

const initialState = {
  gapsInCredListData: [] as Array<IGapsInCredParentModel>,
  error: null as any,
  loading: false
}

const gapsInCredSlice = createSlice({
  name: 'gapsInCred',
  initialState,
  reducers: {

    updateGapsInCredListData: (state, action: PayloadAction<Array<IGapsInCredParentModel>>) => {
      state.gapsInCredListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.gapsInCredListData = [] as Array<IGapsInCredParentModel>
    }

  }
});

export const gapsInCredActions = gapsInCredSlice.actions;
export default gapsInCredSlice.reducer;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IEmpTrainingByDept,
  IEmpTrainingList,
  IEmpTrainingListParentModel,
} from "@helpers/interfaces/employeeFiles";

const initialState = {
  assignTrainingWithSessionList: [] as Array<IEmpTrainingList>,
  trainingList: [] as Array<IEmpTrainingListParentModel>,
  trainingListByDept: [] as Array<IEmpTrainingByDept>,
  error: null as any,
  loading: false,
};

const editEmployeesTrainingSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateAssignTrainingWithSessionListList: (
      state,
      action: PayloadAction<Array<IEmpTrainingList>>
    ) => {
      state.assignTrainingWithSessionList = action.payload;
    },

    updateTrainingList: (
      state,
      action: PayloadAction<Array<IEmpTrainingListParentModel>>
    ) => {
      state.trainingList = action.payload;
    },

    updateTrainingListByDept: (
      state,
      action: PayloadAction<Array<IEmpTrainingByDept>>
    ) => {
      state.trainingListByDept = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.assignTrainingWithSessionList = [] as Array<IEmpTrainingList>;
      state.trainingList = [] as Array<IEmpTrainingListParentModel>;
    },
  },
});

export const editEmployeesTrainingActions = editEmployeesTrainingSlice.actions;
export default editEmployeesTrainingSlice.reducer;

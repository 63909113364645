import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { gapsInCredActions } from "@features/manageDocuments/GapsInCred";

import { handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(gapsInCredActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(gapsInCredActions.updateLoading(false));
}

export const getGapsInCredList = (organizationId: string, hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {
    try {

      showLoading(dispatch);

      const response = await axiosClient.get(`api/ManageDocuments/GetGapInCredRecords?OrgId=${organizationId}&HRMRUserId=${hrmrUserId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(gapsInCredActions.updateGapsInCredListData(data));

        resolve(response);
        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};
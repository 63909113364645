const applicationTableColumnsTypes = {
  STRING: "string",
  DATE: "date",
  YESNO: "yesNo",
  MULTIPLE_VALUES: "MULTIPLE_VALUES",
  MULTIPLE_MOBILE_VALUE: "MULTIPLE_MOBILE_VALUE",
  CUSTOM: "CUSTOM",
  BOLD_STRING: "BOLD_STRING",
};

export default applicationTableColumnsTypes;

import { IDocuSignReportSearchParams } from "@helpers/interfaces/docuSignReport";
import moment from "moment";

export function setDefaultDocuSignReportSearchParams(): IDocuSignReportSearchParams {
  const data = {
    fromDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().clone().endOf("month").format("YYYY-MM-DD"),
  };

  return data;
}

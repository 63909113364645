import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDocuSignReportModel,
  IDocuSignReportPaginationModel,
} from "@helpers/interfaces/reports/DocuSignReport";

const initialState = {
  docuSignReportListData: {} as IDocuSignReportPaginationModel,
  docuSignExportReportListData: {} as any,
  error: null as any,
  loading: false,
};

const docuSignReport = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateDocuSignReportListData: (
      state,
      action: PayloadAction<IDocuSignReportPaginationModel>
    ) => {
      state.docuSignReportListData = action.payload;
    },

    updateDocuSignExportReportListData: (
      state,
      action: PayloadAction<IDocuSignReportModel>
    ) => {
      state.docuSignExportReportListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.error = null;
    },
  },
});

export const docuSignReportActions = docuSignReport.actions;
export default docuSignReport.reducer;

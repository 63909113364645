import toastTypes from "@constants/toast-types";
import { toastMessageActions } from "@features/ui/ToastMessage";
import axiosClient from "@store/axiosClient";
import { handleServerInteractionError } from "@utilities/services";
import { editEmployeesExclusionActions } from "@features/employeeFiles/EditEmployeeExclusions";

function showLoading(dispatch: any) {
  dispatch(editEmployeesExclusionActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(editEmployeesExclusionActions.updateLoading(false));
}

export const getEmployeeExclusionData =
  (id: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/Exclusion/CheckEmpExclusions?ExclusionId=${id}`
        );
        const data = response.data;
        resolve(response);

        if (response.status === 200) {
          dispatch(
            editEmployeesExclusionActions.updateEmployeeExclusionList(data)
          );
          return hideLoading(dispatch);
        }
        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getEmployeeExclusionLogsData =
  (id: number, exclusionFor: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/Exclusion/GetAllExclusionsLogs?ExclusionId=${id}&ExclusionFor=${exclusionFor}`
        );
        const data = response.data;
        resolve(response);
        if (response.status === 200) {
          dispatch(
            editEmployeesExclusionActions.updateEmployeeExclusionLogsList(data)
          );
          return hideLoading(dispatch);
        }
        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getDetailedExclusionData =
  (id: number, exclusionFor: string, exclusionType: string) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/Exclusion/GetExclusionDetails?ExclusionId=${id}&ExclusionFor=${exclusionFor}&ExclusionType=${exclusionType}`
        );
        const data = response.data;
        resolve(response);
        if (response.status === 200) {
          dispatch(
            editEmployeesExclusionActions.updateEmployeeExclusionData(data)
          );
          return hideLoading(dispatch);
        }
        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const changeExclusionStatus =
  (
    id: number,
    exclusionFor: string,
    isExclusion: boolean,
    neverAnExclusion: boolean,
    exclusionStatus: string,
    exclusionType: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/Exclusion/ChangeExclusionStatus?ExclusionId=${id}&ExclusionFor=${exclusionFor}&IsExclusion=${isExclusion}&NeverAnExclusion=${neverAnExclusion}&ExclusionStatus=${exclusionStatus}&ExclusionType=${exclusionType}`
        );
        resolve(response);
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: "Exclusion Updated Successfully!",
              type: toastTypes.SUCCESS,
            })
          );
          return hideLoading(dispatch);
        }
        dispatch(
          toastMessageActions.showToast({
            message: response.data,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const requestExclusionReport =
  (
    exclusionFor: string,
    exclusionType: string,
    year: number,
    autoEmailingId: number,
    isEmailSent: boolean
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/Exclusion/RequestExclusionReport?ExclusionFor=${exclusionFor}&ExclusionType=${exclusionType}&Year=${year}&AutoEmailingId=${autoEmailingId}&IsEmailSent=${isEmailSent}`
        );
        resolve(response);
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: `Thank you for requesting the ${exclusionType} Exclusion Report. We will email you this report within 24 hours.`,
              type: toastTypes.SUCCESS,
            })
          );
          return hideLoading(dispatch);
        }
        dispatch(
          toastMessageActions.showToast({
            message: response.data,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getAllExclusionTypes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    showLoading(dispatch);
    try {
      const response = await axiosClient.get(
        `api/Exclusion/GetAllExclusionsTypeMaster`
      );
      const data = response.data;
      resolve(response);
      if (response.status === 200) {
        dispatch(editEmployeesExclusionActions.updateExclusionTypesList(data));
        return hideLoading(dispatch);
      }
      dispatch(
        toastMessageActions.showToast({
          message: data,
          type: toastTypes.DANGER,
        })
      );
      hideLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

export function uploadExclusionTemplate(formFile: FormData) {
  return async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.post(
          "api/Exclusion/UploadExclusionTemplate",
          formFile,
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        if (response.status === 200) {
          dispatch(
            toastMessageActions.showToast({
              message: "File Uploaded Sucessfully",
              type: toastTypes.SUCCESS,
            })
          );
        }
        resolve(response);
        // dispatch(masterDataActions.getCityState(response.data));
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };
}

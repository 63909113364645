import axiosClient from "@store/axiosClient";

import { IDeleteProfessionalReferencesParamsModel, IProfessionalReferencesModel } from "@helpers/interfaces/credentialProfile/WorkHistoryRefTab";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { professionalReferencesActions } from "@features/credentialProfile/workHistoryRefTab/ProfessionalReferences";

import { handleCreateServiceResponse, handleServerInteractionError, handleDeleteServiceResponse } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(professionalReferencesActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(professionalReferencesActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(professionalReferencesActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(professionalReferencesActions.updateEditorLoading(false));
}

export const getAllProfessionalReferences = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient.get(`api/CredentialingProfile/GetProfessionalReferencesAll?HRMRUserId=${hrmrUserId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(professionalReferencesActions.updateProfessionalReferencesDataList(data));

        resolve(response);
        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};

export const getProfessionalReferences = (professionalReferencesId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient.get(`api/CredentialingProfile/GetProfessionalReferences?ProfessionalReferencesId=${professionalReferencesId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(professionalReferencesActions.updateProfessionalReferencesData(data));

        resolve(response);
        return hideEditorLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideEditorLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });

};

export const createProfessionalReference = (data: IProfessionalReferencesModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/CreateProfessionalReferences",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleCreateServiceResponse(dispatch, professionalReferencesActions, status, response.data);

      hideEditorLoading(dispatch);
      resolve(response);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }

  });
};

export const deleteProfessionalReferences = (data: IDeleteProfessionalReferencesParamsModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/DeleteProfessionalReferences",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleDeleteServiceResponse(dispatch, status, response.data);

      hideLoading(dispatch);
      resolve(response);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }

  });

};
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPerProf_OtherIdsCAQHProviderRosterResultModel } from "@helpers/interfaces/credentialProfile/PerProfInfoTab";

const initialState = {
  caqhProviderResultData: {} as IPerProf_OtherIdsCAQHProviderRosterResultModel,
  error: null as any,
  loading: false,
};

const caqhIntegrationSlice = createSlice({
  name: "caqhIntegration",
  initialState,
  reducers: {
    updateCAQHProvideResultData: (
      state,
      action: PayloadAction<IPerProf_OtherIdsCAQHProviderRosterResultModel>
    ) => {
      state.caqhProviderResultData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.caqhProviderResultData =
        {} as IPerProf_OtherIdsCAQHProviderRosterResultModel;
    },
  },
});

export const caqhIntegrationActions = caqhIntegrationSlice.actions;
export default caqhIntegrationSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IValidateActivityModel } from "@helpers/interfaces/activityValidator";

const initialState = {
  validateStateLicenseActivityData: {} as IValidateActivityModel,
  error: null as any,
  loading: false
};

const validateStateLicenseSlice = createSlice({
  name: "validateStateLicense",
  initialState,
  reducers: {
    updateValidateStateLicenseActivityData: (state, action: PayloadAction<IValidateActivityModel>) => {
      state.validateStateLicenseActivityData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.error = null;
      state.loading = false;
    }
  },
});

export const validateStateLicenseActions = validateStateLicenseSlice.actions;
export default validateStateLicenseSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IValidateActivityModel } from "@helpers/interfaces/activityValidator";

const initialState = {
  validateNPIActivityData: {} as IValidateActivityModel,
  error: null as any,
  loading: false
};

const validateNPIActivitySlice = createSlice({
  name: "validateNPIActivity",
  initialState,
  reducers: {
    updateValidateNPIActivityData: (state, action: PayloadAction<IValidateActivityModel>) => {
      state.validateNPIActivityData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.error = null;
      state.loading = false;
    }
  },
});

export const validateNPIActivityActions = validateNPIActivitySlice.actions;
export default validateNPIActivitySlice.reducer;

import React from 'react';

import { IProfileAvatarProps } from '@helpers/uiInterfaces/common';

import { extractAvatarCharactersFromName, generateRandomColorCodes } from '@utilities/index';

import styles from './ProfileAvatar.module.scss';

const ProfileAvatar: React.FC<IProfileAvatarProps> = React.memo((props) => {

  const { name, profileURL, dynamicColor, className, isProfileImageExists } = props;

  if (isProfileImageExists === true) {

    const profileImageAttributes = {
      className: `${styles.profileImage} rounded-circle`,
      src: profileURL,
    };

    return (
      <div className={styles.profileImageContainer}>
        <img {...profileImageAttributes} alt='profile-image' />
      </div>
    );
  }

  const avatarCharacters = extractAvatarCharactersFromName(name);
  let userProfileAvatarClassName = styles.userProfileAvatar;

  if (typeof className !== 'undefined') {
    userProfileAvatarClassName += ` ${className}`;
  }

  const userProfileAvatarAttributes = {
    className: userProfileAvatarClassName,
    style: {
      backgroundColor: dynamicColor === true ? generateRandomColorCodes() : 'default'
    }
  };

  return <div {...userProfileAvatarAttributes}>{avatarCharacters}</div>;

});

export default ProfileAvatar;
import { useAppSelector } from "@hooks/index";

import { clearApplicationStorage } from "@utilities/storage";

import Call from "@images/call.png";
import user from "@images/user.png";
import { Link } from "react-router-dom";
import routesNames from "@constants/routes-names";
import { createCustomDomainUrl } from "@utilities/index";
import { useEffect, useRef } from "react";

const WATCHDOG_APP_URL = process.env.REACT_APP_WATCHDOG_APP_URL;

const Navbar = () => {
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );
  const DOMAIN_CUSTOM_PATH = createCustomDomainUrl(userRecord.customDomain);
  const userName = `${userRecord.firstName} ${userRecord.lastName}`;
  const hamburgerRef = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      // Check if hamburger icon is visible on small screens
      if (window.innerWidth < 768 && hamburgerRef.current) {
        // Programmatically trigger the click event on the hamburger icon
        hamburgerRef.current.click();
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  function renderLogoutControl() {
    const logoutURL =
      userRecord.useCustomDomain &&
      userRecord.customDomain &&
      userRecord.logoPath
        ? `${DOMAIN_CUSTOM_PATH}/Account/LogOff`
        : `${WATCHDOG_APP_URL}/Account/LogOff`;

    const logoutControlAttributes = {
      href: logoutURL,
      onClick() {
        clearApplicationStorage();
      },
    };

    return (
      <li>
        <a {...logoutControlAttributes}>
          <i className="fa fa-lock" aria-hidden="true" /> Logout
        </a>
      </li>
    );
  }

  let manageProfileLink = `${WATCHDOG_APP_URL}/Account/Manage/${userRecord.id}`;
  const requestSupportPath =
    userRecord.useCustomDomain && userRecord.customDomain && userRecord.logoPath
      ? `${DOMAIN_CUSTOM_PATH}/IncidentTracking/Index/${userRecord.id}`
      : `${process.env.REACT_APP_WATCHDOG_APP_URL}/IncidentTracking/Index/${userRecord.id}`;

  return (
    <nav className="main-header navbar navbar-expand">
      <div className="container-fluid m-container">
        <div className="menuBar d-flex align-items-center">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link hamburger_icon"
                data-widget="pushmenu"
                href="#"
                role="button"
                ref={hamburgerRef}
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
          <div className="top_header">
            <h1>
              {userRecord?.organizationName ? userRecord.organizationName : ""}
            </h1>
          </div>
        </div>
        {/* Right navbar links */}
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li>
              <div className="ticket">
                <a href={requestSupportPath}>Request Support</a>
              </div>
            </li>
            {/* User Account: style can be found in dropdown.less */}
            <li>
              <img src={Call} className="float-start" />
              <div className="float-start">
                CALL US <span>(800) 409-0096</span>
              </div>
            </li>
            <li>
              <div className="task-notifi">
                <img
                  src="assest/images/task-notification.png"
                  className="float-start"
                />
                <div className="float-start">
                  Open <span>Task(s)</span>
                </div>
                <a href="#" className="count">
                  <span>0</span>
                </a>
              </div>
            </li>
            <li className="dropdown user user-menu">
              <div className="float-start">
                <a
                  href="#"
                  className={`dropdown-toggle d-flex caretContent`}
                  data-toggle="dropdown"
                >
                  <img src={user} className="float-start" />
                  Welcome <br />
                  {userRecord?.firstName ? userRecord.firstName : "User"}
                  <span className="caret" />
                </a>
                <ul className="dropdown-menu">
                  <li className="user-name">
                    <i className="fa fa-user" /> {userName}
                  </li>
                  <li>
                    <a href={manageProfileLink}>
                      <i className="fa fa-edit" /> Manage Profile
                    </a>
                  </li>
                  <li>
                    <Link to={routesNames.manageProfileRoute}>
                      <i className="fa fa-edit" /> Organizational Profile
                    </Link>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-key fa-fw" aria-hidden="true" />{" "}
                      Change Password
                    </a>
                  </li> */}
                  {renderLogoutControl()}
                  {/* Menu Body */}
                </ul>
              </div>
            </li>
            {/* Control Sidebar Toggle Button */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

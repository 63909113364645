import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { credentialProfileActions } from "@features/credentialProfile/CredentialProfile";

import { handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(credentialProfileActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(credentialProfileActions.updateLoading(false));
}

export const getCredentialProfileTabs = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {
      showLoading(dispatch);

      const response = await axiosClient.get(`/api/CredentialingProfile/GetTabValidatior?HRMRUserId=${hrmrUserId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        dispatch(credentialProfileActions.updateCredentialProfileTabs(data));
        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }

  });

};
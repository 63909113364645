import { combineReducers } from '@reduxjs/toolkit';

import PersonalInfoReducer from './PersonalInfo';
import GeneralInfoReducer from './GeneralInfo';
import HomeAddressReducer from './HomeAddress';
import FederalDEACDSReducer from './FederalDEACDS';
import StateLicenseReducer from './StateLicense';
import OtherIdsReducer from './OtherIds';
import CAQHIntegrationReducer from './CAQHIntegration';
import OptionalInfoReducer from './OptionalInfo';

const perProfInfoReducer = combineReducers({
  personalInfo: PersonalInfoReducer,
  generalInfo: GeneralInfoReducer,
  homeAddress: HomeAddressReducer,
  federalDEACDS: FederalDEACDSReducer,
  stateLicense: StateLicenseReducer,
  otherIds: OtherIdsReducer,
  caqhIntegration: CAQHIntegrationReducer,
  optionalInfo: OptionalInfoReducer
});

export default perProfInfoReducer;
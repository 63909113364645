import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultPractitionerDetail } from '@helpers/configMiddlewares/serviceSite';
import { IServSitePractitionerDetailsModel } from '@helpers/interfaces/serviceSites/PractitionerDetails';

const initialState = {
  practitionerDetailData: {} as IServSitePractitionerDetailsModel,
  practitionerDetailsDataList: [] as Array<IServSitePractitionerDetailsModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const practitionerDetailsSlice = createSlice({
  name: 'practitionerDetails',
  initialState,
  reducers: {

    updatePractitionerDetailData: (state, action: PayloadAction<IServSitePractitionerDetailsModel>) => {
      state.practitionerDetailData = action.payload;
    },

    updatePractitionerDetailsDataList: (state, action: PayloadAction<Array<IServSitePractitionerDetailsModel>>) => {
      state.practitionerDetailsDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.practitionerDetailData = setDefaultPractitionerDetail();
    }

  }
});

export const practitionerDetailsAction = practitionerDetailsSlice.actions;
export default practitionerDetailsSlice.reducer;
import React from "react";

import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import { useAppSelector } from "@hooks/index";

import routesNames from "@constants/routes-names";

import { IBreadcrumbProps } from "helpers/uiInterfaces/common";

import initialRoutes from "@routes/routes-data/initial-routes";

import { ReactComponent as ChevronRightIcon } from "@images/chevron-right-icon.svg";

import { isLastBreadcrumb } from "./utilities";

import styles from "./Breadcrumbs.module.scss";
import { createCustomDomainUrl } from "@utilities/index";

const Breadcrumbs: React.FC<IBreadcrumbProps> = (props) => {
  const { data } = props;

  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const breadcrumbs = useBreadcrumbs(initialRoutes);
  const DOMAIN_CUSTOM_PATH = createCustomDomainUrl(userRecord.customDomain);

  function renderBreadcrumbLink(
    path: string,
    breadcrumb: any,
    index: number,
    _breadcrumbs: Array<any>
  ) {
    if (path === routesNames.dashboardRoute) {
      const dashboardWatchdogPath =
        userRecord.useCustomDomain &&
        userRecord.customDomain &&
        userRecord.logoPath
          ? `${DOMAIN_CUSTOM_PATH}/Dashboard/Index/${userRecord.id}`
          : `${process.env.REACT_APP_WATCHDOG_APP_URL}/Dashboard/Index/${userRecord.id}`;

      const breadcrumbControlAttributes = {
        key: path,
        href: dashboardWatchdogPath,
        className: styles.breadcrumbLink,
      };

      return <a {...breadcrumbControlAttributes}>{breadcrumb}</a>;
    }

    if (isLastBreadcrumb(index, _breadcrumbs) === true) {
      return (
        <label className={`${styles.breadcrumbLink} ${styles.inactive}`}>
          {breadcrumb}
        </label>
      );
    }

    const breadcrumbControlAttributes = {
      key: path,
      to: path,
      className: styles.breadcrumbLink,
    };

    return <NavLink {...breadcrumbControlAttributes}>{breadcrumb}</NavLink>;
  }

  function renderBreadcrumbSeparator(index: number, _breadcrumbs: Array<any>) {
    if (isLastBreadcrumb(index, _breadcrumbs) === true) {
      return;
    }

    return <ChevronRightIcon className={styles.chevronIcon} />;
  }

  function renderBreadcrumbs() {
    if (typeof data !== "undefined") {
      return data.map((item, index) => (
        <div key={index} className={styles.breadCrumbItem}>
          {renderBreadcrumbLink(item.path, item.breadcrumb, index, data)}
          {renderBreadcrumbSeparator(index, data)}
        </div>
      ));
    }

    return breadcrumbs.map(({ match, breadcrumb }, index) => (
      <div key={index} className={styles.breadCrumbItem}>
        {renderBreadcrumbLink(match.pathname, breadcrumb, index, breadcrumbs)}
        {renderBreadcrumbSeparator(index, breadcrumbs)}
      </div>
    ));
  }

  return <div className={styles.breadcrumbsMain}>{renderBreadcrumbs()}</div>;
};

export default Breadcrumbs;

import pageTitles from "@constants/page-titles";
import routesNames from "@constants/routes-names";

export function getPageTitleByLocation(location: any) {
  const pathName = location.pathname;

  switch (pathName) {
    case routesNames.dashboardRoute:
      return pageTitles.dashboard;

    case routesNames.credentialingProfileRoute:
      return pageTitles.credentialingProfile;

    case routesNames.npdbRoute:
      return pageTitles.npdb;

    case routesNames.activityChecklistRoute:
      return pageTitles.activityChecklist;

    case routesNames.manageDocumentsRoute:
      return pageTitles.manageDocuments;

    case routesNames.authenticateRoute:
      return pageTitles.authenticate;

    case routesNames.departmentsRoute:
      return pageTitles.departments;

    case routesNames.serviceSitesRoute:
      return pageTitles.serviceSites;

    case routesNames.organizationalContactsRoute:
      return pageTitles.organizationalContacts;

    case routesNames.trainingLibraryRoute:
      return pageTitles.trainingLibrary;

    case routesNames.trainingReportRoute:
      return pageTitles.trainingReport;

    case routesNames.expirablesRoute:
      return pageTitles.expirables;

    case routesNames.manageProfileRoute:
      return pageTitles.manageProfile;

    case routesNames.employeeCredentialingProfileRoute:
      return pageTitles.employeeCredentialingProfile;

    case routesNames.employeeFiles:
      return pageTitles.employeeFiles;

    case routesNames.clinicalStaff:
      return pageTitles.clinicalStaff;

    case routesNames.nonClinicalStaff:
      return pageTitles.nonClinicalStaff;

    case routesNames.setupIntegrationsRoute:
      return pageTitles.setupIntegrations;

    case routesNames.dashboardInitialRoute:
      return pageTitles.dashboardInitial;

    case routesNames.dashboardReCredRoute:
      return pageTitles.dashboardReCred;

    default:
      return pageTitles.default;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServSiteOfficeManagerModel } from '@helpers/interfaces/serviceSites/OfficeManager';
import { setDefaultOfficeManagerData } from '@helpers/configMiddlewares/serviceSite';

const initialState = {
  officeManagerData: {} as IServSiteOfficeManagerModel,
  error: null as any,
  loading: false
}

const officeManagerSlice = createSlice({
  name: 'officeManager',
  initialState,
  reducers: {

    updateOfficeManagerData: (state, action: PayloadAction<IServSiteOfficeManagerModel>) => {
      state.officeManagerData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.officeManagerData = setDefaultOfficeManagerData();
    }

  }
});

export const officeManagerAction = officeManagerSlice.actions;
export default officeManagerSlice.reducer;
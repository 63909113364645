import { useState, useEffect } from "react";
import ContentHeader from "@common/ContentHeader";
import orderSummaryInstructions from "@constants/instructions/order-summary";
import styles from "./OrderSummary.module.scss";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import orderSummaryListColumns from "@constants/json-data/table-columns/order-summary/orderSummary";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import {
  getAllOrderSummaryList,
  getOrderSummaryRptExport,
} from "@services/psv";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@hooks/index";
import {
  createOrderSummaryDatasetReport,
  parseOrderSummaryRows,
} from "./utilities";
import moment from "moment";
import { exportToExcel } from "@utilities/import-export-excel";
import { useLocation, useParams } from "react-router-dom";
import routesNames from "@constants/routes-names";
import { IOrderSummaryModel } from "@helpers/interfaces/psv/orderSummary";
import DocumentsCredentialingEditor from "@pages/manageDocuments/documentsCredentialingEditor/DocumentsCredentialingEditor";
import { gapsInCredentialingUIActions } from "@features/ui/GapsInCredentialingUI";
import editorTypes from "@constants/editor-types";
import { docCommentUIActions } from "@features/ui/DocCommentUI";
import { getActivityData } from "@services/activity-checklist/Activity";
import { downloadAzureBlobFile } from "@services/file-task";
import { downloadBlob, downloadEncodedBlob } from "@utilities/file-download";
import orderSummaryFolderNames from "@constants/order-summary-folder-names";
import { setDefaultOrderSummaryFilterData } from "@helpers/configMiddlewares/order-summary";
import OrderSummaryFilterEditor from "./OrderSummaryFilterEditor";
import { IOrderSummarySearchAndFilterParams } from "@helpers/interfaces/order-summary";
import BootstrapSpinner from "react-bootstrap/Spinner";
import SelectedFilters from "./OrderSummarySelectedFilters";
import { getAllManageDocumentsById } from "@services/manage-documents";
import { downloadMultipleDocuments } from "@pages/manageDocuments/utilities";
import CredUserProfile from "@pages/credentialingProfile/CredUserProfile";
import {
  getNPDBAgentDetails,
  downloadNPDBReport,
} from "@services/setupIntegrations/NPDB";
import SearchField from "@common/search-field/SearchField";

const DEFAULT_PAGE_LIMIT = 10;

const OrderSummary = () => {
  const dispatch = useDispatch<any>();

  const { hrmrUserId } = useParams();
  const { state } = useLocation();
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const _hrmrUserId: number =
    hrmrUserId && !Number.isNaN(+hrmrUserId)
      ? +hrmrUserId
      : userRecord.hrmrUserId;

  const _isClinicalStaff: boolean = state?.isClinicalStaff!;
  const [paginationState, setPaginationState] = useState({
    page: 1,
    limit: DEFAULT_PAGE_LIMIT,
    filterData: setDefaultOrderSummaryFilterData(),
  });
  const [displayFilterEditor, setDisplayFilterEditor] = useState(false);
  const [openDocumentEditor, setOpenDocumentEditor] = useState(false);
  const [reportExportLoading, setReportExportLoading] = useState(false);
  const [selectedManageDocument, setSelectedManageDocument] =
    useState<IOrderSummaryModel | null>(null); // used to store information of currently editing document
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [refreshPage, setRefreshPage] = useState(false);

  const data = state
    ? [
        {
          path: routesNames.dashboardRoute,
          breadcrumb: "Dashboard",
        },
        {
          path: _isClinicalStaff
            ? routesNames.clinicalStaff
            : routesNames.nonClinicalStaff,
          breadcrumb: _isClinicalStaff
            ? "Licensed Staff"
            : "Non Licensed Staff",
        },
        {
          path: routesNames.orderSummary,
          breadcrumb: "OrderSummary",
        },
      ]
    : [
        {
          path: routesNames.dashboardRoute,
          breadcrumb: "Dashboard",
        },
        {
          path: routesNames.orderSummary,
          breadcrumb: "OrderSummary",
        },
      ];

  const contentHeaderAttributes = {
    title: "Order Summary",
    instructions: orderSummaryInstructions,
    renderExtraContent: _hrmrUserId === 0 ? "" : <CredUserProfile />,
    breadcrumbData: data,
  };
  const orderSummaryState = useAppSelector((state) => state.orderSummary);
  const orderSummaryList = orderSummaryState.orderSummaryData?.page;

  useEffect(() => {
    loadOrderSummaryData(
      1,
      DEFAULT_PAGE_LIMIT,
      "",
      "",
      "",
      "",
      _hrmrUserId,
      ""
    );
  }, [_hrmrUserId, refreshPage]);

  async function loadOrderSummaryData(
    page: number,
    limit: number,
    month: string,
    year: string,
    orderStatus: string,
    orderSource: string,
    hrmrUserId: number,
    searchText: string
  ) {
    await dispatch(
      getAllOrderSummaryList(
        page,
        limit,
        month,
        year,
        orderStatus,
        orderSource,
        hrmrUserId,
        searchText
      )
    );

    const filterData = {
      month: month,
      year: year,
      orderStatus: orderStatus,
      orderSource: orderSource,
      hrmrUserId: hrmrUserId,
      searchText: searchText,
    };

    setPaginationState({
      ...paginationState,
      page,
      limit,
      filterData,
    });
  }

  function showLoading(id: number) {
    setLoading(true);
    setSelectedId(id);
  }

  function hideLoading() {
    setLoading(false);
    setSelectedId(null);
  }

  const handleExportReport = async () => {
    setReportExportLoading(true);

    const response = await dispatch(
      getOrderSummaryRptExport(_hrmrUserId.toString())
    );
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "Order Summary Report" + timestamp;
      const reportDataset = createOrderSummaryDatasetReport(response.data);

      await exportToExcel(reportDataset, fileName, "Report");

      setReportExportLoading(false);
    } else {
      setReportExportLoading(false); // To stop loading indicator when there's any error while getting export data from api
    }
  };

  const handleDownloadReport = async (
    data: IOrderSummaryModel,
    index: number
  ) => {
    showLoading(index);
    const res = await dispatch(
      downloadAzureBlobFile(
        orderSummaryFolderNames.HRSA_CREDENTIALING,
        data.filePath
      )
    );

    if (res.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = `${timestamp}.pdf`;
      await downloadBlob(res.data, fileName);
    }
    hideLoading();
  };

  const handleDowloadDocumentsClick = async (
    data: IOrderSummaryModel,
    index: number
  ) => {
    showLoading(index);
    const response = await dispatch(
      getAllManageDocumentsById(data.actDocVerIds)
    );

    await downloadMultipleDocuments(response.data);
    hideLoading();
  };

  const handleGenerateNPDBReport = async (
    data: IOrderSummaryModel,
    index: number
  ) => {
    showLoading(index);
    const response = await dispatch(getNPDBAgentDetails(data.organizationId));
    if (response.status === 200) {
      handleDowloadNPDBDocumentsClick(data, index);
    }
    hideLoading();
  };

  const handleDowloadNPDBDocumentsClick = async (
    data: IOrderSummaryModel,
    index: number
  ) => {
    showLoading(index);
    const response = await dispatch(
      downloadNPDBReport(
        data.organizationId,
        data.npdbScanningId,
        data.hrmrUserId,
        data.source
      )
    );
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = `${timestamp}.pdf`;
      await downloadEncodedBlob(response.data.fileContents, fileName);
      setRefreshPage(!refreshPage);
    }
    hideLoading();
  };

  const dispatchAddEditDocActions = async (
    data: IOrderSummaryModel,
    index: number
  ) => {
    showLoading(index);
    const res = await dispatch(getActivityData(data.activityId));
    if (res.status === 200) {
      const params = {
        enable: true,
        employeeName: data.fullName,
        hrmrUserId: data.hrmrUserId,
      };

      const docCommentParams = {
        employeeName: data.fullName,
        hrmrUserId: data.hrmrUserId,
        activityName: res.data.activityName,
        activityId: data.activityId,
      };

      dispatch(
        gapsInCredentialingUIActions.updateGapsInCredentialingState(params)
      );
      dispatch(docCommentUIActions.updateDocCommentUIState(docCommentParams));

      setOpenDocumentEditor(true);
      setSelectedManageDocument(data);
      hideLoading();
    }
  };

  function renderTableActionElements(data: IOrderSummaryModel, index: number) {
    if (loading === true && selectedId === index) {
      return <BootstrapSpinner animation="border" size="sm" />;
    }
    if (data.source === "PSV" && data.status === "Order Completed") {
      const linkControlAttributes = {
        className: "table-link-button",
        onClick() {
          handleDowloadDocumentsClick(data, index);
        },
      };
      return (
        <div>
          <button {...linkControlAttributes}>Download</button>
        </div>
      );
    }

    if (
      (data.source === "NPDB" || data.source === "NPDB CQ") &&
      data.responseReport &&
      data.status === "Complete"
    ) {
      const linkControlAttributes = {
        className: "table-link-button",
        onClick() {
          handleDowloadNPDBDocumentsClick(data, index);
        },
      };

      return (
        <div>
          <button {...linkControlAttributes}>Download</button>
        </div>
      );
    }

    if (
      (data.source === "NPDB" || data.source === "NPDB CQ") &&
      !data.responseReport &&
      data.status === "Complete"
    ) {
      const linkControlAttributes = {
        className: "table-link-button",
        onClick() {
          handleGenerateNPDBReport(data, index);
        },
      };

      return (
        <div>
          <button {...linkControlAttributes}>Generate Report</button>
        </div>
      );
    }

    if (data.source === "NPDB" && data.status === "InComplete") {
      return <></>;
    }

    if (
      (data.source === "NPDB" || data.source === "PSV") &&
      data.status === "Pending"
    ) {
      return <></>;
    }

    if (data.source === "PSV" && data.status === "Ready") {
      const linkControlAttributes = {
        className: "table-link-button",
        onClick() {
          handleDownloadReport(data, index);
        },
      };
      return (
        <div>
          <button {...linkControlAttributes}>Download Report</button>
        </div>
      );
    }

    if (data.source === "PSV" && data.status === "Updated") {
      const linkControlAttributes = {
        className: "table-link-button",
      };
      return (
        <div>
          <button {...linkControlAttributes}>Download Updated Report</button>
        </div>
      );
    }

    if (data.source === "PSV" && data.status === "Error") {
      return <></>;
    }

    if (data.source === "PSV" && data.status === "Order Placed") {
      const linkControlAttributes = {
        className: "table-link-button",
        onClick() {
          dispatchAddEditDocActions(data, index);
        },
      };
      return (
        <div>
          <button {...linkControlAttributes}>Document(s)</button>
        </div>
      );
    }
  }

  const renderTableElement = () => {
    const { filterData } = paginationState;
    const { month, year, orderSource, orderStatus, searchText } = filterData;
    if (!orderSummaryList || orderSummaryState.loading === true) {
      return <Spinner />;
    }

    if (orderSummaryList?.length === 0) {
      return <NoContentSection />;
    }

    const paginatedTableAttributes = {
      columns: orderSummaryListColumns,
      rows: parseOrderSummaryRows(orderSummaryList),
      actionElement: renderTableActionElements,
      currentPage: paginationState.page,
      totalRecord: orderSummaryState.orderSummaryData?.paging?.totalItems,
      rowsPerPage: paginationState.limit,
      onPageLimitChange(limit: number) {
        loadOrderSummaryData(
          1,
          limit,
          month,
          year,
          orderStatus,
          orderSource,
          _hrmrUserId,
          searchText
        );
      },
      onPageChange(page: number) {
        loadOrderSummaryData(
          page,
          paginationState.limit,
          month,
          year,
          orderStatus,
          orderSource,
          _hrmrUserId,
          searchText
        );
      },
    };

    return (
      <div className="mt-4 px-3">
        <PaginatedTable {...paginatedTableAttributes} />
      </div>
    );
  };

  const closeCredentialingEditor = () => {
    dispatch(gapsInCredentialingUIActions.resetState());
    setOpenDocumentEditor(false);
    setRefreshPage(!refreshPage);
    setSelectedManageDocument(null);
  };

  function renderCredentialingEditor() {
    if (openDocumentEditor === false) {
      return;
    }

    const credentialingEditorAttributes = {
      open: openDocumentEditor,
      data: selectedManageDocument,
      mode: selectedManageDocument ? editorTypes.EDIT : editorTypes.ADD,
      onClose: closeCredentialingEditor,
      onSave() {},
    };

    return <DocumentsCredentialingEditor {...credentialingEditorAttributes} />;
  }

  function renderFiltersEditor() {
    if (displayFilterEditor === false) {
      return;
    }

    const orderSummaryFiltersEditorAttributes = {
      open: displayFilterEditor,
      filterData: paginationState.filterData,
      onSearch(data: IOrderSummarySearchAndFilterParams) {
        loadOrderSummaryData(
          paginationState.page,
          paginationState.limit,
          data.month,
          data.year,
          data.orderStatus,
          data.orderSource,
          _hrmrUserId,
          data.searchText
        );
        setDisplayFilterEditor(false);
      },
      onClose() {
        setDisplayFilterEditor(false);
      },
    };

    return (
      <OrderSummaryFilterEditor {...orderSummaryFiltersEditorAttributes} />
    );
  }

  function renderExportReportControl() {
    let contentNode: any = "Export Report";

    if (reportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="m-2"
        />
      );
    }

    const exportControlAttributes = {
      title: "Export report",
      disabled: reportExportLoading,
      className: `btn btnorg float-end w120`,
      onClick: handleExportReport,
    };

    return <button {...exportControlAttributes}>{contentNode}</button>;
  }

  function handleSearchFieldControl(searchText: string = "") {
    setPaginationState((prevState) => ({
      ...prevState,
      filterData: {
        ...prevState.filterData,
        searchText: searchText,
      },
    }));
    loadOrderSummaryData(
      paginationState.page,
      paginationState.limit,
      paginationState.filterData.month,
      paginationState.filterData.year,
      paginationState.filterData.orderStatus,
      paginationState.filterData.orderSource,
      _hrmrUserId,
      searchText
    );
  }

  function renderSearchFieldControl() {
    const searchFieldControlAttributes = {
      placeholder: "Search by First or Last Name",
      value: paginationState.filterData.searchText,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {
        setPaginationState((prevState) => ({
          ...prevState,
          filterData: {
            ...prevState.filterData,
            search: searchText,
          },
        }));
      },
    };

    return <SearchField {...searchFieldControlAttributes} />;
  }

  function renderHeader() {
    const searchAndFilterControlAttributes = {
      className: `btn btnorg float-end`,
      onClick() {
        setDisplayFilterEditor(true);
      },
    };

    const selectedFiltersAttributes = {
      filters: paginationState.filterData,
      onFilterChange(filters: IOrderSummarySearchAndFilterParams) {
        loadOrderSummaryData(
          1,
          paginationState.limit,
          filters.month,
          filters.year,
          filters.orderStatus,
          filters.orderSource,
          _hrmrUserId,
          filters.searchText
        );
      },
    };

    return (
      <div className="row align-items-center">
        <div className="col-md-5 col-xs-12 col-sm-12 d-flex">
          <SelectedFilters {...selectedFiltersAttributes} />
        </div>
        <div className="col-md-7 col-xs-12 col-sm-12">
          <div className={styles.btnContainer}>
            {renderSearchFieldControl()}
            <button {...searchAndFilterControlAttributes}>
              Search & Filter
            </button>
            {renderExportReportControl()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id={styles.orderSummaryMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className={`container-fluid ${styles.orderSummaryContainer}`}>
        {renderHeader()}
        {renderTableElement()}
        {renderCredentialingEditor()}
        {renderFiltersEditor()}
      </div>
    </div>
  );
};

export default OrderSummary;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IActivityModel } from "@helpers/interfaces/activityChecklist/Activity";
import { ICredentialActivityDocumentTypeModel } from "@helpers/interfaces/activityChecklist/DocumentType";
import {
  IDocTypeByHrmrUserId,
  IManageDocumentHistoryDocsPaginationModel,
  IManageDocumentsPaginationModel,
} from "@helpers/interfaces/manageDocuments";

const initialState = {
  organizationActivities: [] as Array<IActivityModel>,
  documentTypesByActivityId: [] as Array<ICredentialActivityDocumentTypeModel>,
  documentTypeDataList: [] as Array<IDocTypeByHrmrUserId>,
  manageDocumentDataList: {} as IManageDocumentsPaginationModel,
  manageDocumentsList: {} as IManageDocumentsPaginationModel,
  manageActDocHistoryLogs: {} as IManageDocumentHistoryDocsPaginationModel,
  error: null as any,
  loading: false,
};

const manageDocumentsSlice = createSlice({
  name: "manageDocuments",
  initialState,
  reducers: {
    updateActivities: (state, action: PayloadAction<Array<IActivityModel>>) => {
      state.organizationActivities = action.payload;
    },

    updateDocumentTypesByActivityId: (
      state,
      action: PayloadAction<Array<ICredentialActivityDocumentTypeModel>>
    ) => {
      state.documentTypesByActivityId = action.payload;
    },

    updateManageDocumentsList: (
      state,
      action: PayloadAction<IManageDocumentsPaginationModel>
    ) => {
      state.manageDocumentsList = action.payload;
    },

    updateManageDocumentListById: (
      state,
      action: PayloadAction<IManageDocumentsPaginationModel>
    ) => {
      state.manageDocumentDataList = action.payload;
    },

    updateDocumentTypeListById: (
      state,
      action: PayloadAction<Array<IDocTypeByHrmrUserId>>
    ) => {
      state.documentTypeDataList = action.payload;
    },

    updateManageActDocHistoryLogsById: (
      state,
      action: PayloadAction<IManageDocumentHistoryDocsPaginationModel>
    ) => {
      state.manageActDocHistoryLogs = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
    },
  },
});

export const manageDocumentsActions = manageDocumentsSlice.actions;
export default manageDocumentsSlice.reducer;

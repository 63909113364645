import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGapHistoryModel } from '@helpers/interfaces/credentialProfile/WorkHistoryRefTab';
import { setDefaultGapHistoryData } from '@helpers/configMiddlewares/credentialingProfile/workHistoryRefTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  gapHistoryData: {} as IGapHistoryModel,
  gapHistoryDataList: [] as Array<IGapHistoryModel>,
  error: null as any,
  loading: false,
  editorLoading: false
};

const gapHistory = createSlice({
  name: 'gapHistory',
  initialState,
  reducers: {

    updateGapHistoryData: (state, action: PayloadAction<IGapHistoryModel>) => {
      state.gapHistoryData = action.payload;
    },

    updateGapHistoryDataList: (state, action: PayloadAction<Array<IGapHistoryModel>>) => {
      state.gapHistoryDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.gapHistoryData = setDefaultGapHistoryData(userRecord);
    }

  }
});

export const gapHistoryActions = gapHistory.actions;
export default gapHistory.reducer;
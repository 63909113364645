import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAddActivityModel, ICredentialChecklistModel, ICredentialChecklistPaginationModel } from '@helpers/interfaces/activityChecklist/Checklist';

import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  checklistData: {} as ICredentialChecklistModel,
  allChecklistData: {} as ICredentialChecklistPaginationModel,
  checklistActivities: [] as Array<IAddActivityModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const checklistSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {

    updateChecklistData: (state, action: PayloadAction<ICredentialChecklistModel>) => {
      state.checklistData = action.payload;
    },

    updateAllChecklistData: (state, action: PayloadAction<ICredentialChecklistPaginationModel>) => {
      state.allChecklistData = action.payload;
    },

    updateAllAddActivityData: (state, action: PayloadAction<Array<IAddActivityModel>>) => {
      state.checklistActivities = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    }

  }
});

export const checklistActions = checklistSlice.actions;
export default checklistSlice.reducer;
import { combineReducers } from "@reduxjs/toolkit";

import ToastMessageReducer from "./ToastMessage";
import CredentialProfileTabsReducer from "./CredentialProfileTabs";
import CommonInterfacesReducer from "./CommonInterfaces";
import GapsInCredentialingReducer from "./GapsInCredentialingUI";
import DocCommentReducer from "./DocCommentUI";

const uiReducers = combineReducers({
  toastMessage: ToastMessageReducer,
  credentialProfileTabs: CredentialProfileTabsReducer,
  commonInterfaces: CommonInterfacesReducer,
  gapsInCredentialing: GapsInCredentialingReducer,
  docComment: DocCommentReducer,
});

export default uiReducers;

import { INPDBSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/NPDB";

export function setDefaultNPDBSetupDetails(): INPDBSetupDetailsModel {

  const data = {
    npdbid: 0,
    entityDBID: '',
    agentDBID: '',
    npdbUserID: '',
    npdbPassword: '',
    type: 'Entity',
    fein: '',
    itin: '',
    name: '',
    title: '',
    phoneNumber: '',
    phoneExtension: '',
    organizationName: '',
    typeCode: '',
    orgTypeCode: ''
  };

  return data;
}

import { IOrderSummarySearchAndFilterParams } from "@helpers/interfaces/order-summary";

export function setDefaultOrderSummaryFilterData(): IOrderSummarySearchAndFilterParams {
  const data = {
    month: "",
    year: "",
    orderStatus: "",
    orderSource: "",
    searchText: "",
  };

  return data;
}

import { combineReducers } from '@reduxjs/toolkit';

import GraduateTypeReducer from './GraduateType';
import TrainingDetailsReducer from './TrainingDetails';
import UndergraduateReducer from './Undergraduate';

const eduTrainReducer = combineReducers({
  graduateType: GraduateTypeReducer,
  trainingDetails: TrainingDetailsReducer,
  undergraduate: UndergraduateReducer
});

export default eduTrainReducer;
const orderSourceOptions = [
  {
    label: "NPDB",
    value: "NPDB",
  },
  {
    label: "NPDB CQ",
    value: "NPDB CQ",
  },
  {
    label: "PSV",
    value: "PSV",
  },
];

export default orderSourceOptions;

import { combineReducers } from "@reduxjs/toolkit";

import ManageDocumentsReducer from "./ManageDocuments";
import AddEditCredentialingReducer from "./AddEditDocuments";
import GapsInCredReducer from "./GapsInCred";
import DocCommentReducer from "./DocComment";

const manageDocumentsReducers = combineReducers({
  manageDocumentsState: ManageDocumentsReducer,
  addEditDocuments: AddEditCredentialingReducer,
  gapsInCred: GapsInCredReducer,
  docComment: DocCommentReducer,
});

export default manageDocumentsReducers;

import { combineReducers } from "@reduxjs/toolkit";

import EmployeeReducer from "./Employee";
import AddEditEmployeesReducer from "./AddEditEmployees";
import EditEmployeeExclusions from "./EditEmployeeExclusions";
import EditEmployeeOnboarding from "./EditEmployeeOnboarding";
import EditEmployeeTraining from "./EditEmployeeTraining";
import EditEmployeePayer from "./EditEmployeePayerPrivileging";

const employeeFileReducer = combineReducers({
  employee: EmployeeReducer,
  addEditEmployees: AddEditEmployeesReducer,
  editEmployeeExclusions: EditEmployeeExclusions,
  editEmployeeOnboarding: EditEmployeeOnboarding,
  editEmployeeTraining: EditEmployeeTraining,
  editEmployeePayer: EditEmployeePayer,
});

export default employeeFileReducer;

interface IDataItem {
  value: number,
  label: string
}

function isEqual (label: string, searchString: string) {

  return label
    .toLowerCase()
    .includes(searchString.toLowerCase());

}

function filterByLabelName(data: Array<IDataItem>, filterKey: string) {

  let collection = data.filter((item) => isEqual(item.label, filterKey));

  return collection;

}

export function handleFilterChange(data: Array<IDataItem>, filterKey?: string) {

  if(!filterKey){
    return data;
  }

  if (filterKey.trim() === '') {
    return data;
  }

  return filterByLabelName(data, filterKey);

}

export function findLabelByValue(data: Array<IDataItem>, value: string){

  let label = '';

  if(value.trim() === ''){
    return label;
  }

  for(let item of data){

    if(String(item.value) === value){
      label = item.label;
      break;
    }

  }

  return label;

}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_FederalDEACDSModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultFederalDEACDSInfo } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  federalDEACDSData: {} as IPerProf_FederalDEACDSModel ,
  federalDEACDSDataList: [] as Array <IPerProf_FederalDEACDSModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const federalDEACDS = createSlice({
  name: 'federalDEACDS',
  initialState,
  reducers: {

    updateFederalDEACDSData: (state, action: PayloadAction<IPerProf_FederalDEACDSModel>) => {
      state.federalDEACDSData = action.payload;
    },

    updateFederalDEACDSDataList: (state, action: PayloadAction<Array<IPerProf_FederalDEACDSModel>>) => {
      state.federalDEACDSDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.federalDEACDSData = setDefaultFederalDEACDSInfo(userRecord);
    }

  }
});

export const federalDEACDSAction = federalDEACDS.actions;
export default federalDEACDS.reducer;
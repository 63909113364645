import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultPartnerAssociatesData } from '@helpers/configMiddlewares/serviceSite';
import { IServSitePartnerAssociatesModel } from '@helpers/interfaces/serviceSites/PartnerAssociates';

const initialState = {
  partnerAssociatesData: {} as IServSitePartnerAssociatesModel,
  partnerAssociatesDataList: [] as Array<IServSitePartnerAssociatesModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const partnerAssociatesSlice = createSlice({
  name: 'partnerAssociatesSlice',
  initialState,
  reducers: {

    updatePartnerAssociatesData: (state, action: PayloadAction<IServSitePartnerAssociatesModel>) => {
      state.partnerAssociatesData = action.payload;
    },

    updatePartnerAssociatesDataList: (state, action: PayloadAction<Array<IServSitePartnerAssociatesModel>>) => {
      state.partnerAssociatesDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.partnerAssociatesData = setDefaultPartnerAssociatesData();
    }

  }
});

export const partnerAssociatesAction = partnerAssociatesSlice.actions;
export default partnerAssociatesSlice.reducer;
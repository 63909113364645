export function parseExclusionSelectControlsData(data: Array<any>) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.displayName,
      value: item.exclusionTypeId,
    };

    _data.push(object);
  }

  return _data;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultOfficeHoursData } from '@helpers/configMiddlewares/serviceSite';
import { IServSiteOfficeHoursModel } from '@helpers/interfaces/serviceSites/OfficeHours';

const initialState = {
  officeHoursData: {} as IServSiteOfficeHoursModel,
  error: null as any,
  loading: false
}

const officeHoursSlice = createSlice({
  name: 'officeHours',
  initialState,
  reducers: {

    updateOfficeHoursData: (state, action: PayloadAction<IServSiteOfficeHoursModel>) => {
      state.officeHoursData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.officeHoursData = setDefaultOfficeHoursData();
    }

  }
});

export const officeHoursAction = officeHoursSlice.actions;
export default officeHoursSlice.reducer;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IManageDocumentsPaginationModel,
  IManageExpirableCount,
} from "@helpers/interfaces/manageDocuments";

const initialState = {
  expirablesDocumentsList: {} as IManageDocumentsPaginationModel,
  expirableCount: {} as IManageExpirableCount,
  expirableFrequency: "0" as string,
  error: null as any,
  loading: false,
};

const expirablesSlice = createSlice({
  name: "expirables",
  initialState,
  reducers: {
    updateExpirablesDocumentsList: (
      state,
      action: PayloadAction<IManageDocumentsPaginationModel>
    ) => {
      state.expirablesDocumentsList = action.payload;
    },

    updateExpirableCount: (
      state,
      action: PayloadAction<IManageExpirableCount>
    ) => {
      state.expirableCount = action.payload;
    },

    updateExpirableFrequency: (state, action: PayloadAction<string>) => {
      state.expirableFrequency = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const expirablesActions = expirablesSlice.actions;
export default expirablesSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITrainingsParentModel, ITrainLib_AssignDepartmentModel, ITrainLib_ScheduleTrainingList } from '@helpers/interfaces/trainingLibrary';

const initialState = {
  trainingLibraryList: [] as Array<ITrainingsParentModel>,
  trainingDepartmentList: [] as Array<ITrainLib_AssignDepartmentModel>,
  trainingSchedulerList: [] as Array<ITrainLib_ScheduleTrainingList>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const trainingLibrarySlice = createSlice({
  name: 'trainingLibrary',
  initialState,
  reducers: {

    updateTrainingLibraryList: (state, action: PayloadAction<Array<ITrainingsParentModel>>) => {
      state.trainingLibraryList = action.payload;
    },

    updateTrainingDepartmentList: (state, action: PayloadAction<Array<ITrainLib_AssignDepartmentModel>>) => {
      state.trainingDepartmentList = action.payload;
    },

    updateTrainingSchedulerList: (state, action: PayloadAction<Array<ITrainLib_ScheduleTrainingList>>) => {
      state.trainingSchedulerList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetTrainLib_AssignDepartmentList: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.trainingDepartmentList = [];
    },

    resetTrainLib_TrainingSchedulerList: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.trainingSchedulerList = [];
    }

  }
});

export const trainingLibraryActions = trainingLibrarySlice.actions;
export default trainingLibrarySlice.reducer;
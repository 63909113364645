import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGapsInCredentialingSliceState } from '@helpers/uiInterfaces/manage-documents/gaps-in-cred';

const initialState = {
  enable: false,
  hrmrUserId: 0,
  employeeName: ''
};

const gapsInCredentialingUISlice = createSlice({
  name: 'gapsInCredentialingUI',
  initialState,
  reducers: {

    updateGapsInCredentialingState: (state, action: PayloadAction<IGapsInCredentialingSliceState>) => {
      state.employeeName = action.payload.employeeName;
      state.hrmrUserId = action.payload.hrmrUserId;
      state.enable = action.payload.enable;
    },

    resetState: (state) => {
      state.employeeName = '';
      state.hrmrUserId = 0;
      state.enable = false;
    }

  }
});

export const gapsInCredentialingUIActions = gapsInCredentialingUISlice.actions;
export default gapsInCredentialingUISlice.reducer;
import {
  IDashboardInitialAndReCredDataModel,
  IDashboardInitialAndReCredSearchAndFilterParams,
  IEmployeeModel,
  IEmployeeSearchAndFilterParams,
} from "@helpers/interfaces/employeeFiles";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";

export function setDefaultEmployeeFilesFilterData(): IEmployeeSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
  };

  return data;
}

export function setDefaultEmployeeData(): IEmployeeModel {
  const data = {
    hrmrUserId: 0,
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    employeeType: "",
    gender: "0",
    tempColExist_UserId: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "Email",
    userName: "",
    password: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    totalCount: 0,
    totalExpDocsCount: 0,
    locations: "",
    pronouns: "0",
    credContactName: "",
    credPrimaryEmail: "",
    credSecondaryEmail: "",
    credPhone: "",
    credPhoneExt: "",
    credFax: "",
    credAddress: "",
    credCityId: 0,
    credStateId: 0,
    credZip: "",
    file: null,
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredFilterData(): IDashboardInitialAndReCredSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredData(): IDashboardInitialAndReCredDataModel {
  const data = {
    hrmrUserId: 0,
    tempColExist_UserId: "",
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    gender: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "",
    userName: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    locations: "",
    employeeType: "",
    totalExpDocsCount: 0,
    totalCount: 0,
    isNPDBReport: false,
    statusOfApplication: "",
    hospitalName: "",
    notificationSchedule: "",
    createdBy: "",
    createdDate: null,
    updatedBy: "",
    updatedDate: null,
    userId: "",
    organizationId: "",
  };

  return data;
}

import { combineReducers } from '@reduxjs/toolkit';

import AdmittingArrangementsReducer from './AdmittingArrangements';
import HospitalPrivileges from './HospitalPrivileges';

const hospitalAffiliationsReducer = combineReducers({
  admittingArrangements: AdmittingArrangementsReducer,
  hospitalPrivileges: HospitalPrivileges
});

export default hospitalAffiliationsReducer;
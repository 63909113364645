import React, { useState } from "react";

import moment from "moment";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IGapsInCredDocumentItemProps } from "@helpers/uiInterfaces/manage-documents/gaps-in-cred";

import FileViewerDialog from "@common/file-viewer/FileViewerDialog";

import { downloadFileWithURLAndName } from "@utilities/file-download";

import styles from "./GapsInCred.module.scss";

const GapsInCredDocumentItem: React.FC<IGapsInCredDocumentItemProps> = (
  props
) => {
  const { data } = props;

  const [displayFileViewer, setDisplayFileViewer] = useState(false);

  function renderFileViewer() {
    if (displayFileViewer === false) {
      return;
    }

    const fileViewerDialogAttributes = {
      fileName: data.filePath,
      filePath: data.uri,
      onClose() {
        setDisplayFileViewer(false);
      },
    };

    return <FileViewerDialog {...fileViewerDialogAttributes} />;
  }

  function renderDocumentItemLabels() {
    let expiryDateLabelClassName = styles.expiryDate;
    const expiryDate = data.expiryDate
      ? moment(data.expiryDate).format("MM/DD/YYYY")
      : "**Not available**";

    const isDocumentExpired = moment() > moment(data.expiryDate);

    if (isDocumentExpired === true) {
      expiryDateLabelClassName += ` ${styles.expired}`;
    }

    return (
      <div className={styles.documentItemLabels}>
        <label className={styles.documentType}>
          {data.docType} {data.fileName ? "|" : ""} {data.fileName}
        </label>
        <label className={expiryDateLabelClassName}>
          Expiry Date: {expiryDate}
        </label>
        {data.docRequired === 2 && (
          <label className={styles.notApplicableLable}>
            Required: Not Applicable
          </label>
        )}
      </div>
    );
  }

  function renderDocumentItemControls() {
    const viewDocumentControlAttributes = {
      title: "View Document",
      className: !data.uri
        ? `${styles.disabledDocumentControl} ${styles.documentControl}`
        : styles.documentControl,
      disabled: !data.uri,
      onClick() {
        setDisplayFileViewer(true);
      },
    };

    const downloadDocumentControlAttributes = {
      title: "Download Document",
      className: !data.uri
        ? `${styles.disabledDocumentControl} ${styles.documentControl}`
        : styles.documentControl,
      disabled: !data.uri,
      onClick() {
        downloadFileWithURLAndName(data.uri, data.fileName);
      },
    };

    return (
      <div className={styles.documentItemControls}>
        <button {...viewDocumentControlAttributes}>
          <FontAwesomeIcon icon={faEye} />
        </button>
        <button {...downloadDocumentControlAttributes}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.gapsInCredDocumentItemMain}>
      {renderDocumentItemLabels()}
      {renderDocumentItemControls()}
      {renderFileViewer()}
    </div>
  );
};

export default GapsInCredDocumentItem;

import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';

import { ISearchFieldProps } from '@helpers/uiInterfaces/common';

import styles from './SearchField.module.scss';

const SearchField: React.FC<ISearchFieldProps> = (props) => {

  const { placeholder, value, onSearch, onClear, onChange } = props;

  const [searchText, setSearchText] = useState(value);

  useEffect(() => {
    setSearchText(value);
  }, [value]);

  function handleKeyDownEvent(event: React.KeyboardEvent<HTMLInputElement>) {

    if (event.key !== 'Enter') {
      return;
    }

    handleSearch();

  }

  function handleSearch() {

    // if(searchText.trim() === ''){
    //   return;
    // }

    onSearch(searchText.trim());
  }

  function renderClearFieldControl() {

    if (searchText.trim() === '') {
      return;
    }

    const clearFieldControlAttributes = {
      className: styles.clearFieldControl,
      onClick() {
        setSearchText('');
        onClear();
      }
    };

    return (
      <button {...clearFieldControlAttributes}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    );
  }

  const searchInputControlAttributes = {
    type: 'text',
    placeholder,
    className: styles.searchInput,
    value: searchText,
    onChange(event: React.ChangeEvent<HTMLInputElement>) {
      setSearchText(event.target.value);
      if (typeof onChange !== 'undefined') { onChange(event.target.value) }
    },
    onKeyDown: handleKeyDownEvent
  };

  const searchButtonControlAttributes = {
    className: styles.searchButtonControl,
    onClick: handleSearch
  };

  return (
    <div className={styles.searchFieldMain}>
      <input {...searchInputControlAttributes} />
      {renderClearFieldControl()}
      <button {...searchButtonControlAttributes}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </div>
  );
};

export default SearchField;
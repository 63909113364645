import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMastersDataModel } from "@helpers/interfaces/masters/Masters";

const initialState = {
  employeeList: [] as Array<IMastersDataModel>,
  error: null as any,
  loading: false,
};

const addEditEmployeesSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateEmployeeList: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.employeeList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeeList = [] as Array<IMastersDataModel>;
    },
  },
});

export const addEditEmployeesActions = addEditEmployeesSlice.actions;
export default addEditEmployeesSlice.reducer;

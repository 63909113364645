import React from "react";

import moment from "moment";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SelectedFilters.module.scss";
import { ISelectedFiltersProps } from "@helpers/uiInterfaces/docu-sign-report";

const SelectedFilters: React.FC<ISelectedFiltersProps> = (props) => {
  const { filters, onFilterChange } = props;

  function handleFilterChange(name: string) {
    let data: any = { ...filters };
    if (name === "fromDate") {
      data[name] = moment().clone().startOf("month").format("YYYY-MM-DD");
    } else if (name === "toDate") {
      data[name] = moment().clone().endOf("month").format("YYYY-MM-DD");
    } else data[name] = "";
    onFilterChange(data);
  }

  function renderFilterChip(label: string, name: string, value: string) {
    const filterChipLabelAttributes = {
      ["data-bs-toggle"]: "tooltip",
      ["data-bs-placement"]: "bottom",
      title: `${label}: ${value}`,
    };

    const removeFilterControlAttributes = {
      icon: faCircleXmark,
      className: styles.removeFilterControl,
      onClick() {
        handleFilterChange(name);
      },
    };

    return (
      <div className={styles.filterChip}>
        <label {...filterChipLabelAttributes}>
          {label}: {value}
        </label>
        <FontAwesomeIcon {...removeFilterControlAttributes} />
      </div>
    );
  }

  function renderFromDateFilter() {
    if (filters.fromDate === "" || filters.fromDate === null) {
      return;
    }

    const fromDate = moment(filters.fromDate).format("MM/DD/YYYY");
    return renderFilterChip("From", "fromDate", fromDate);
  }

  function renderToDateFilter() {
    if (filters.toDate === "" || filters.toDate === null) {
      return;
    }

    const toDate = moment(filters.toDate).format("MM/DD/YYYY");
    return renderFilterChip("To", "toDate", toDate);
  }

  return (
    <div id={styles.selectedFiltersMain}>
      {renderFromDateFilter()}
      {renderToDateFilter()}
    </div>
  );
};

export default SelectedFilters;

import { IActivityModel } from "@helpers/interfaces/activityChecklist/Activity";
import { ICredentialActivityDocumentTypeModel } from "@helpers/interfaces/activityChecklist/DocumentType";

export function setDefaultActivityData(): IActivityModel {
  const data = {
    activityId: 0,
    activityName: "",
    isActive: true,
    isConfidential: false,
    isRequired: false,
    sendAlert: false,
    expFrequency: "30", //default option for 30 days frequency,
    sendAlertSupervisor: false,
    sendAlertBillingSupervisor: false,
    description: "",
    activitysequence: 0,
  };

  return data;
}

export function setDefaultDocumentType(): ICredentialActivityDocumentTypeModel {
  const data = {
    docTypeId: 0,
    docType: "",
    activityId: 0,
    appVerificationId: 0,
    renewalFreq: "0",
    isOrderReq: false,
    isExpiration: false,
    isActivityNumber: false,
    guidelinesIds: "",
    isDocRequired: false,
    isDocuSignMapped: false,
    typeOfDocuSign: null,
  };

  return data;
}

const orderStatusOptions = [
  {
    label: "Complete",
    value: "Complete",
  },
  {
    label: "InComplete",
    value: "InComplete",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Partial",
    value: "Partial",
  },
  {
    label: "Ready",
    value: "Ready",
  },
  {
    label: "Applicant Pending",
    value: "Applicant Pending",
  },
  {
    label: "Updated",
    value: "Updated",
  },
  {
    label: "Error",
    value: "Error",
  },
  {
    label: "Canceled",
    value: "Canceled",
  },
  {
    label: "Order Placed",
    value: "Order Placed",
  },
  {
    label: "Order Completed",
    value: "Order Completed",
  },
];

export default orderStatusOptions;

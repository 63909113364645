import axiosClient from "@store/axiosClient";
import toastTypes from "@constants/toast-types";
import messages from "@constants/messages";
import {
  IDepartmentModel,
  IHideUnhideDepartmentParamsModel,
  ICreateDepartmentChecklistParamsModel,
} from "@helpers/interfaces/departments/Department";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { departmentActions } from "@features/departments/Department";
import {
  handleCreateServiceResponse,
  handleOtherServiceResponses,
  handleServerInteractionError,
} from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(departmentActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(departmentActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(departmentActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(departmentActions.updateEditorLoading(false));
}

export const createDepartment =
  (data: IDepartmentModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/ManageDocuments/CreateDepartment",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          departmentActions,
          status,
          response.data
        );

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const hideUnhideDepartment =
  (data: IHideUnhideDepartmentParamsModel, hideMessage: boolean) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/ManageDocuments/HideUnhideDepartment",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;
        if (hideMessage === false) {
          handleOtherServiceResponses(
            dispatch,
            status,
            response.data,
            messages.statusChangedSuccessfully
          );
        }

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getDepartmentsList =
  (
    pageNumber: number,
    pageSize: number,
    includeHidden: boolean,
    search?: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);
        let url = `api/ManageDocuments/GetDepartmentPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IncludeHidden=${includeHidden}`;
        if (search) {
          url += `&Search=${search}`;
        }
        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(departmentActions.updateDepartmenListData(data));
          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getAllDepartmentChecklists =
  (DepartmentId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showEditorLoading(dispatch);

        let url = `api/ManageDocuments/GetDepartmentChecklists?DepartmentId=${DepartmentId}`;

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(departmentActions.updateAllDepartmentChecklistData(data));

          resolve(response);
          return hideEditorLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const createDepartmentChecklist =
  (data: ICreateDepartmentChecklistParamsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showEditorLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/ManageDocuments/ManageDepartmentChecklist",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          departmentActions,
          status,
          response.data
        );

        hideEditorLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const getAllDepartmentsListAtOnce = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showLoading(dispatch);

      const url = "api/ManageDocuments/GetDepartmentList";

      const response = await axiosClient.get(url);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        dispatch(departmentActions.updateAllDepartmentList(data));

        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data,
          type: toastTypes.DANGER,
        })
      );

      hideLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

import { combineReducers } from '@reduxjs/toolkit';

import PracticeLocationReducer from './PracticeLocation';
import CoveringColleaguesReducer from './CoveringColleagues';

const practiceLocationInfoReducer = combineReducers({
  practiceLocation: PracticeLocationReducer,
  coveringColleagues: CoveringColleaguesReducer
});

export default practiceLocationInfoReducer;
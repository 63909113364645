import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { graduateTypeAction } from "@features/credentialProfile/eduTrainTab/GraduateType";

import { IDeleteUsOrCanadianSchoolParamsModel, IUSCanadianModel } from "@helpers/interfaces/credentialProfile/EduTrainTab";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleCreateServiceResponse, handleDeleteServiceResponse, handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(graduateTypeAction.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(graduateTypeAction.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(graduateTypeAction.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(graduateTypeAction.updateEditorLoading(false));
}

export const getUSCanadianSchool = (uCSchoolId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient.get('api/CredentialingProfile/GetUSCanadianSchool?UCSchooId=' + uCSchoolId);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(graduateTypeAction.updateUSCanadianData(data));

        resolve(response);

      } else {

        dispatch(toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER
        }));

      }

      hideEditorLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });

};

export const getUSCanadianSchoolAll = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient.get('api/CredentialingProfile/GetUSCanadianSchoolAll?HRMRUserId=' + hrmrUserId);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(graduateTypeAction.updateUSCanadianDataList(data));

        resolve(response);
        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};

export const createUSCanadianSchool = (data: IUSCanadianModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/CreateUSCanadianSchool",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleCreateServiceResponse(dispatch, graduateTypeAction, status, response.data);

      hideEditorLoading(dispatch);
      resolve(response);
    }
    catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }

  });

};

export const deleteUSCanadianSchool = (data: IDeleteUsOrCanadianSchoolParamsModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/DeleteUSCanadianSchool",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleDeleteServiceResponse(dispatch, status, response.data);

      hideLoading(dispatch);
      resolve(response);
    }
    catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }

  });

};
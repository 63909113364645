import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfMedical_CertificationsModel } from '@helpers/interfaces/credentialProfile/ProfMedical';
import { setDefaultCertificationsData } from '@helpers/configMiddlewares/credentialingProfile/profMedicalSpecialtyTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  certificationsData: {} as IProfMedical_CertificationsModel,
  error: null as any,
  loading: false
}

const certifications = createSlice({
  name: 'certifications',
  initialState,
  reducers: {

    updateCertificationsData: (state, action: PayloadAction<IProfMedical_CertificationsModel>) => {
      state.certificationsData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.certificationsData = setDefaultCertificationsData(userRecord);
    }

  }
});

export const certificationsActions = certifications.actions;
export default certifications.reducer;
const pageLimit = [
  {
    label: 5,
    value: 5
  },
  {
    label: 10,
    value: 10
  },
  {
    label: 20,
    value: 20
  },
  {
    label: 50,
    value: 50
  },
  {
    label: 100,
    value: 100
  }
];

export default pageLimit;
const orderSummaryFolderNames = {
  HRSA_CREDENTIALING: "HRSACredentialing",
  NPI_PAGE_CONTENT: "NPIPageContent",
  SRA_REPORTS: "SRAReports/Docusign Audit log",
  UNDER_GRADUATE_SCHOOL: "CredProEATUndergraduateDocuments/DocuSignTemplates",
  US_CANADIAN_SCHOOL: "CredProEATGraduateTypeDocuments/DocuSignTemplates",
  TRAINING_DETAILS: "CredProEATInternshipDocuments/DocuSignTemplates",
  HOSPITAL_AFFILIATIONS: "CredProHospitalRefDocuments/DocuSignTemplates",
  PROFESSIONAL_LIABILITY_INSURANCE_CARRIER:
    "CredProProfLibDocuments/DocuSignTemplates",
  WORK_HISTORY: "CredProWorkHistoryDocuments/DocuSignTemplates",
  PROFESSIONAL_REFERENCES: "CredProProfRefDocuments/DocuSignTemplates",
  PROVIDER_PROFILE_PICTURE: "ProviderProfilePicture",
  COMPOSITE_TEMPLATE_BASE: "PPRepositroy/Portals/e2ohealth/",
  Ds_EMP_PAYER: "PayerDocuments/Template_",
  Ds_EMP_PRIVILEGING: "PrivilegingDocuments/Template_",
  Ds_EMP_ONBOARDING: "Employee/Template_",
};

export default orderSummaryFolderNames;

import { combineReducers } from '@reduxjs/toolkit';

import PracticeInterestsReducer from './PracticeInterests';
import SpecialtyReducers from './Specialty';
import CertificationsReducer from './Certifications';

const profMedicalSpecialtyReducer = combineReducers({
  practiceInterests: PracticeInterestsReducer,
  specialty: SpecialtyReducers,
  certifications: CertificationsReducer
});

export default profMedicalSpecialtyReducer;
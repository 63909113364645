import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultLanguagesData } from '@helpers/configMiddlewares/serviceSite';
import { IServSiteLanguagesModel } from '@helpers/interfaces/serviceSites/Languages';

const initialState = {
  languagesData: {} as IServSiteLanguagesModel,
  error: null as any,
  loading: false
}

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {

    updateLanguagesData: (state, action: PayloadAction<IServSiteLanguagesModel>) => {
      state.languagesData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.languagesData = setDefaultLanguagesData();
    }

  }
});

export const languagesAction = languagesSlice.actions;
export default languagesSlice.reducer;
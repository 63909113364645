import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ICompositeDocuSignModel,
  IDocuSignEmailedTemplatesModel,
  IDocuSignModel,
} from "@helpers/interfaces/docuSign";

const initialState = {
  docuSignTemplatesList: [] as Array<IDocuSignModel>,
  docuSignTemplates: [] as Array<ICompositeDocuSignModel>,
  docuSignEmailedTemplates: [] as Array<IDocuSignEmailedTemplatesModel>,
  error: null as any,
  emailedTemplateloading: false,
  loading: false,
  editorLoading: false,
};

const docuSignSlice = createSlice({
  name: "docuSign",
  initialState,
  reducers: {
    updateDocuSignTemplatesList: (
      state,
      action: PayloadAction<Array<IDocuSignModel>>
    ) => {
      state.docuSignTemplatesList = action.payload;
    },

    updateDocuSignTemplates: (
      state,
      action: PayloadAction<Array<ICompositeDocuSignModel>>
    ) => {
      state.docuSignTemplates = action.payload;
    },

    updateDocuSignEmailedTemplates: (
      state,
      action: PayloadAction<Array<IDocuSignEmailedTemplatesModel>>
    ) => {
      state.docuSignEmailedTemplates = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEmailedTemplateLoading: (state, action: PayloadAction<boolean>) => {
      state.emailedTemplateloading = action.payload;
    },

    resetState: (state) => {
      state.docuSignEmailedTemplates = [];
      state.docuSignTemplates = [];
      state.docuSignTemplatesList = [];
      state.loading = false;
      state.editorLoading = false;
      state.error = null;
      state.emailedTemplateloading = false;
    },
  },
});

export const docuSignActions = docuSignSlice.actions;
export default docuSignSlice.reducer;

import { combineReducers } from "@reduxjs/toolkit";

import OrganizationContactsReducer from "./OrganizationContacts";
import OrganizationWaiverReducer from "./OrganizationWaiver";

const organizationReducer = combineReducers({
  organizationContacts: OrganizationContactsReducer,
  organizationWaiver: OrganizationWaiverReducer,
});

export default organizationReducer;

import credentialProfileTabStatus from "@constants/credential-profile-tab-status";

import { ICredentialProfileTabsData } from "@helpers/uiInterfaces/credentialing-profile";

import { ICredentialProfileTab } from "@helpers/interfaces/credentialProfile";
import { IEmployeeModel } from "@helpers/interfaces/employeeFiles";
import genderPrefixData from "@constants/json-data/masters-data/gender-prefix-data";
interface IValidatorMap {
  [key: number]: boolean;
}

export function parseCredentialProfileTabs(
  validatorData: Array<ICredentialProfileTab>,
  tabs: Array<ICredentialProfileTabsData>,
  index: number,
  initialMount: boolean,
  empPortal: boolean
) {
  let _tabs = [...tabs];

  if (validatorData.length === 0) {
    return _tabs;
  }

  const validatorMapData = createValidatorMapData(validatorData);

  if (initialMount === true) {
    _tabs = manipulateWholeTabsData(validatorMapData, tabs);
    return _tabs;
  }

  _tabs = manipulateSpecificTabsData(validatorMapData, tabs, index, empPortal);
  return _tabs;
}

function manipulateWholeTabsData(
  validatorMapData: IValidatorMap,
  tabs: Array<ICredentialProfileTabsData>
) {
  const _tabs = [];
  for (let tab of tabs) {
    if (validatorMapData.hasOwnProperty(tab.index) === true) {
      const valid = validatorMapData[tab.index];
      const object = {
        ...tab,
        status: valid
          ? credentialProfileTabStatus.COMPLETE
          : credentialProfileTabStatus.INCOMPLETE,
      };

      _tabs.push(object);
    } else {
      _tabs.push(tab);
    }
  }
  return _tabs;
}

function createValidatorMapData(validatorData: Array<ICredentialProfileTab>) {
  let validatorMapData: IValidatorMap = {};

  for (let item of validatorData) {
    validatorMapData[item.tabId] = item.isValid;
  }

  return validatorMapData;
}

function manipulateSpecificTabsData(
  validatorMapData: IValidatorMap,
  tabs: Array<ICredentialProfileTabsData>,
  index: number,
  empPortal: boolean
) {
  let _tabs = [...tabs];
  const specificIndex = empPortal
    ? index === 5
      ? index - 2
      : index === 7
        ? index - 3
        : index - 1
    : index - 1;

  _tabs[specificIndex].status =
    validatorMapData[index] === true
      ? credentialProfileTabStatus.COMPLETE
      : credentialProfileTabStatus.INCOMPLETE;

  return _tabs;
}

export function getUserFullName(personalInfo: IEmployeeModel) {
  let userFullName = "";

  if (personalInfo.firstName) {
    userFullName = personalInfo.firstName;
  }

  if (personalInfo.middleName) {
    userFullName += ` ${personalInfo.middleName}`;
  }

  if (personalInfo.lastName) {
    userFullName += ` ${personalInfo.lastName}`;
  }

  if (personalInfo.suffix) {
    userFullName += ` ${personalInfo.suffix}`;
  }

  return userFullName;
}

export const renderPrefix = (prefix: string) => {
  return genderPrefixData.find((data) => data.value === prefix?.toString())
    ?.label;
};

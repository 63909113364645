import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { employeeActions } from "@features/employeeFiles/Employee";

import {
  IDashboardInitialAndReCredSearchAndFilterParams,
  IEmployeeSearchAndFilterParams,
} from "@helpers/interfaces/employeeFiles";

import {
  handleCreateServiceResponse,
  handleServerInteractionError,
} from "@utilities/services";
import { checkIfAllLocationAndDepartmentSelected } from "@utilities/index";

function showLoading(dispatch: any) {
  dispatch(employeeActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(employeeActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(employeeActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(employeeActions.updateEditorLoading(false));
}

export const getAllEmployeeList =
  (
    pageNumber: number,
    pageSize: number,
    filtersData: IEmployeeSearchAndFilterParams
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const { employeeType, search, employeeStatus, locations, department } =
          filtersData;

        const filteredLocations = locations.filter(
          (location) => location.selected
        );

        const filteredDepartments = department.filter(
          (department) => department.selected
        );

        const locationData = checkIfAllLocationAndDepartmentSelected(
          locations,
          filteredLocations
        );

        const departmentData = checkIfAllLocationAndDepartmentSelected(
          department,
          filteredDepartments
        );

        let url = `api/Employee/GetEmployeePageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IsActive=${employeeType}&EmployeeStatus=${employeeStatus}`;

        if (search) {
          url += `&Search=${search}`;
        }

        if (locationData) {
          url += `&Locations=${locationData}`;
        }

        if (departmentData) {
          url += `&Departments=${departmentData}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(employeeActions.updateEmployeeList(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getEmployeeData =
  (HRMRUserId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showEditorLoading(dispatch);

      try {
        const response = await axiosClient.get(
          "api/Employee/GetEmployee?HRMRUserId=" + HRMRUserId
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(employeeActions.updateEmployeeData(response.data));
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const upsertEmployee = (data: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showEditorLoading(dispatch);
      const response = await axiosClient.post(
        "api/Employee/AddEditEmployee",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const status = response.status;

      handleCreateServiceResponse(
        dispatch,
        employeeActions,
        status,
        response.data
      );

      hideEditorLoading(dispatch);
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });
};

export const getEmployeeFilesReport = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `api/Employee/GetEmployeeExport`;

      const response = await axiosClient.get(url);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const checkDuplicateEmployee = (data: any) => async (dispatch: any) => {
  const { hrmrUserId, firstName, lastName, email } = data;
  return new Promise(async (resolve, reject) => {
    try {
      showEditorLoading(dispatch);
      const response = await axiosClient.post(
        `api/Employee/CheckDuplicateEmployee?HRMRUserId=${hrmrUserId}&FirstName=${firstName}&LastName=${lastName}&Email=${email}`
      );
      hideEditorLoading(dispatch);
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });
};

export const getAllDashboardInitialList =
  (
    pageNumber: number,
    pageSize: number,
    filtersData: IDashboardInitialAndReCredSearchAndFilterParams
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const { employeeType, search, employeeStatus, locations, department } =
          filtersData;

        const filteredLocations = locations.filter(
          (location) => location.selected
        );

        const filteredDepartments = department.filter(
          (department) => department.selected
        );

        const locationData = checkIfAllLocationAndDepartmentSelected(
          locations,
          filteredLocations
        );

        const departmentData = checkIfAllLocationAndDepartmentSelected(
          department,
          filteredDepartments
        );

        let url = `api/Employee/GetDashboardInitialPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IsActive=${employeeType}&EmployeeStatus=${employeeStatus}`;

        if (search) {
          url += `&Search=${search}`;
        }

        if (locationData) {
          url += `&Locations=${locationData}`;
        }

        if (departmentData) {
          url += `&Departments=${departmentData}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(employeeActions.updateDashboardInitialDataList(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getAllDashboardReCredList =
  (
    pageNumber: number,
    pageSize: number,
    filtersData: IDashboardInitialAndReCredSearchAndFilterParams
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const { employeeType, search, employeeStatus, locations, department } =
          filtersData;

        const filteredLocations = locations.filter(
          (location) => location.selected
        );

        const filteredDepartments = department.filter(
          (department) => department.selected
        );

        const locationData = checkIfAllLocationAndDepartmentSelected(
          locations,
          filteredLocations
        );

        const departmentData = checkIfAllLocationAndDepartmentSelected(
          department,
          filteredDepartments
        );

        let url = `api/Employee/GetDashboardReCredPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IsActive=${employeeType}&EmployeeStatus=${employeeStatus}`;

        if (search) {
          url += `&Search=${search}`;
        }

        if (locationData) {
          url += `&Locations=${locationData}`;
        }

        if (departmentData) {
          url += `&Departments=${departmentData}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(employeeActions.updateDashboardReCredDataList(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getProviderCountReport =
  (isActive: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = `api/Employee/GetEmployeeCountByCredentialsExport?IsActive=${isActive}`;

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFileCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import { IFileViewerDialogProps } from "@helpers/uiInterfaces/common";

import { getExtensionFromFileName } from "@utilities/index";

import styles from "./FileViewer.module.scss";

const FileViewerDialog: React.FC<IFileViewerDialogProps> = (props) => {
  const { fileName, onClose, filePath } = props;

  function renderFileHasIssuesSection(message: string) {
    return (
      <div className={styles.fileHasIssuesSection}>
        <FontAwesomeIcon
          icon={faFileCircleExclamation}
          className={styles.fileIcon}
        />
        <p>File can't be displayed. {message}</p>
      </div>
    );
  }

  function renderImageViewer() {
    return (
      <div className={styles.imageViewerContainer}>
        <img src={filePath} className={styles.image} />
        <p>{fileName}</p>
      </div>
    );
  }

  function renderPdfViewer() {
    const pdfIFrameAttributes = {
      src: filePath,
      width: "100%",
      height: "99%", // just to avoid extra scrollbar on ui
    };

    return <iframe {...pdfIFrameAttributes} />;
  }

  function renderTextFileViewer() {
    const textFileViewerAttributes = {
      data: filePath,
      width: "100%",
      height: "99%", // just to avoid extra scrollbar on ui
    };

    return (
      <div className={styles.textFileViewerSection}>
        <object {...textFileViewerAttributes}>Not supported</object>
      </div>
    );
  }

  function renderMSOfficeFileViewer() {
    const url = `https://view.officeapps.live.com/op/embed.aspx?src=${filePath}`;

    const msOfficeFileIFrameAttributes = {
      src: url,
      width: "100%",
      height: "99%", // just to avoid extra scrollbar on ui
    };

    return <iframe {...msOfficeFileIFrameAttributes} />;
  }

  function renderContent() {
    const fileExtension = getExtensionFromFileName(fileName);

    if (fileName === null || fileName === "") {
      return renderFileHasIssuesSection("File not uploaded!");
    }

    if (fileExtension === "pdf") {
      return renderPdfViewer();
    }

    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "webp" ||
      fileExtension === "gif"
    ) {
      return renderImageViewer();
    }

    if (fileExtension === "txt") {
      return renderTextFileViewer();
    }

    if (
      fileExtension === "xls" ||
      fileExtension === "xlsx" ||
      fileExtension === "doc" ||
      fileExtension === "docx" ||
      fileExtension === "rtf" ||
      fileExtension === "ppt" ||
      fileExtension === "pptx"
    ) {
      return renderMSOfficeFileViewer();
    }

    return renderFileHasIssuesSection("File format not supported!");
  }

  function renderCloseControl() {
    const closeControlAttributes = {
      className: styles.closeControl,
      onClick(event: any) {
        event.stopPropagation(); // avoiding parent (backdrop) function call on the content
        onClose();
      },
    };

    return (
      <button {...closeControlAttributes}>
        <FontAwesomeIcon icon={faClose} />
      </button>
    );
  }

  const fileViewerContentAttributes = {
    className: styles.fileViewerDialogContent,
    onClick(event: any) {
      event.stopPropagation(); // avoiding parent (backdrop) function call on the content
    },
  };

  return (
    <div className={styles.fileViewerDialogMain} onClick={onClose}>
      <div className={styles.fileViewerWrapper}>
        <div {...fileViewerContentAttributes}>{renderContent()}</div>

        {renderCloseControl()}
      </div>
    </div>
  );
};

export default FileViewerDialog;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IEmployeeCredentialsRecordModel } from "@helpers/interfaces/activityValidator";

const initialState = {
  employeeCredentialingRecord: {} as IEmployeeCredentialsRecordModel,
  error: null as any,
  loading: false
};

const activityValidatorSlice = createSlice({
  name: "activityValidator",
  initialState,
  reducers: {
    updateEmployeeCredentialingRecord: (state, action: PayloadAction<IEmployeeCredentialsRecordModel>) => {
      state.employeeCredentialingRecord = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.error = null;
      state.loading = false;
      state.employeeCredentialingRecord = {} as IEmployeeCredentialsRecordModel;
    }
  }
});

export const activityValidatorActions = activityValidatorSlice.actions;
export default activityValidatorSlice.reducer;

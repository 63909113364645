import { ICompliancewatchdogSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/ComlianceWatchdog";

export function setDefaultComlianceWatchdogSetupDetails(): ICompliancewatchdogSetupDetailsModel {
  const data = {
    cwServiceId: 0,
    isONorOff: false,
  };

  return data;
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IOrganizationAlertData,
  IOrganizationContactModel,
  IOrganizationContactsPaginationModel,
} from "@helpers/interfaces/organizations";

const initialState = {
  organizationContactsList: [] as Array<IOrganizationContactModel>,
  organizationContactsPaginatedList: {} as IOrganizationContactsPaginationModel,
  organizationContactData: {} as IOrganizationContactModel,
  organizationAlertData: {} as IOrganizationAlertData,
  error: null as any,
  editorLoading: false,
  loading: false,
};

const organizationContactsSlice = createSlice({
  name: "organizationContacts",
  initialState,
  reducers: {
    updateOrganizationContactsList: (
      state,
      action: PayloadAction<Array<IOrganizationContactModel>>
    ) => {
      state.organizationContactsList = action.payload;
    },

    updateOrganizationContactsPaginatedList: (
      state,
      action: PayloadAction<IOrganizationContactsPaginationModel>
    ) => {
      state.organizationContactsPaginatedList = action.payload;
    },

    updateOrganizationContactData: (
      state,
      action: PayloadAction<IOrganizationContactModel>
    ) => {
      state.organizationContactData = action.payload;
    },

    updateOrganizationalContactAlert: (
      state,
      action: PayloadAction<IOrganizationAlertData>
    ) => {
      state.organizationAlertData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.editorLoading = false;
      state.error = null;
      state.organizationContactData = {} as IOrganizationContactModel;
    },
  },
});

export const organizationContactsAction = organizationContactsSlice.actions;
export default organizationContactsSlice.reducer;

const genderPrefixData = [
  {
    label: "He/Him/His",
    value: "1",
  },
  {
    label: "She/Her/Hers",
    value: "2",
  },
  {
    label: "They/Them/Their",
    value: "3",
  },
  {
    label: "Ze/Zir/Zirs",
    value: "4",
  },
  {
    label: "Ze/Hir/Hirs",
    value: "5",
  },
];

export default genderPrefixData;

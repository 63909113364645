import React from "react";

import { Modal } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import { IErrorDialogProps } from "@helpers/uiInterfaces/common";

import styles from "./ErrorDialog.module.scss";
import { createCustomDomainUrl } from "@utilities/index";
import { useAppSelector } from "@hooks/index";

const ErrorDialog: React.FC<IErrorDialogProps> = (props) => {
  const { open, message } = props;
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  const DOMAIN_CUSTOM_PATH = createCustomDomainUrl(userRecord.customDomain);

  function renderModalHeader() {
    return (
      <div className={styles.dialogHeader}>
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className={styles.errorIcon}
        />
        <h4 className={styles.errorHeading}>Error</h4>
      </div>
    );
  }

  function renderMessage() {
    let _message = message;

    if (!message) {
      _message = "Something went wrong. Internal server error!";
    }

    const goBackControlAttributes = {
      href:
        userRecord.useCustomDomain &&
        userRecord.customDomain &&
        userRecord.logoPath
          ? DOMAIN_CUSTOM_PATH
          : process.env.REACT_APP_WATCHDOG_APP_URL,
      className: `btn btnorg ${styles.goBackControl}`,
    };

    return (
      <div className={styles.dialogContent}>
        <p className={styles.errorMessage}>{_message}</p>
        <a {...goBackControlAttributes}>Go back to Main Application</a>
      </div>
    );
  }

  const modalAttributes = {
    show: open,
  };

  return (
    <Modal {...modalAttributes} backdrop="static">
      <div id={styles.errorDialogMain}>
        {renderModalHeader()}
        {renderMessage()}
      </div>
    </Modal>
  );
};

export default ErrorDialog;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDocuSignFoldersModel, IDocuSignSetupDetailsModel } from '@helpers/interfaces/setupIntegrations/DocuSign';

const initialState = {
  docuSignSetupDetails: {} as IDocuSignSetupDetailsModel,
  docuSignFolders: {} as IDocuSignFoldersModel,
  error: null as any,
  loading: false
}

const docuSignSetupSlice = createSlice({
  name: 'docuSignSetup',
  initialState,
  reducers: {

    updateDocuSignSetupDetails: (state, action: PayloadAction<IDocuSignSetupDetailsModel>) => {
      state.docuSignSetupDetails = action.payload;
    },

    updateDocuSignFolders: (state, action: PayloadAction<IDocuSignFoldersModel>) => {
      state.docuSignFolders = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.docuSignSetupDetails = {} as IDocuSignSetupDetailsModel;
    }

  }
});

export const docuSignSetupActions = docuSignSetupSlice.actions;
export default docuSignSetupSlice.reducer;
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../store/RootReducers';

const middleware = [thunkMiddleware];

function createStore() {

  if (process.env.NODE_ENV === 'production') {

    const store = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middleware),
      devTools: false, // Enable Redux DevTools
    });

    return store;

  } else {

    const store = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(...middleware),
      devTools: true, // Enable Redux DevTools
    });

    return store;

  }

}

const _store = createStore();

export default _store;
export type RootState = ReturnType<typeof _store.getState>;
export type AppDispatch = typeof _store.dispatch;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompliancewatchdogSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/ComlianceWatchdog";

const initialState = {
  complianceWatchdogSetupDetails: {} as ICompliancewatchdogSetupDetailsModel,
  error: null as any,
  loading: false,
};

const compliancewatchdogSetupSlice = createSlice({
  name: "comlianceWatchdogSetup",
  initialState,
  reducers: {
    updateComplianceWatchdogSetupDetails: (
      state,
      action: PayloadAction<ICompliancewatchdogSetupDetailsModel>
    ) => {
      state.complianceWatchdogSetupDetails = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.complianceWatchdogSetupDetails =
        {} as ICompliancewatchdogSetupDetailsModel;
    },
  },
});

export const complianceWatchdogSetupActions =
  compliancewatchdogSetupSlice.actions;
export default compliancewatchdogSetupSlice.reducer;

const documentsCredentialingInstructions = [
  'Use this section to manage verified documents.',
  'Select the employee name and credentialing activity. Use HRSA examples of documentation provided for reference.',
  'Enter the verified date.',
  'Click on the add document section to upload documents',
  'Add notes to provide relevant details about the document uploaded',
  'Save the page to store all uploaded documents',
  'Check Gaps report to check which documents have not been added'
];

export default documentsCredentialingInstructions;
import { IDocumentsSearchParams } from "@helpers/interfaces/manageDocuments";

export function setDefaultDocumentsSearchParams(): IDocumentsSearchParams {
  const data = {
    name: "",
    activity: null,
    docType: null,
    includeHiddenDoc: false,
    fromDate: "",
    toDate: "",
    verifyFromDate: "",
    verifyToDate: "",
    guidelinesIds: "",
    locationIds: "",
    employeeType: "1",
  };

  return data;
}

import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import { IHospAffil_AdmittingArrangementsModel, IHospAffil_HospitalPrivilegesModel } from "@helpers/interfaces/credentialProfile/HospitalAffiliations";

export function setDefaultAdmittingArrangements(userRecord: IUserRecordModel): IHospAffil_AdmittingArrangementsModel {

  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    hospitalPrivilages: false,
    arrangmentsDoYouHave: ''
  };

  return data;
}

export function setDefaultHospitalPrivileges(_userRecord: IUserRecordModel): IHospAffil_HospitalPrivilegesModel {

  const data = {
    hospitalAffiliationsId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    isPrimary: false,
    isActive: false,
    hospitalName: '',
    number: '',
    street: '',
    building: '',
    country: 0,
    state: 0,
    city: 0,
    zipCode: '',
    telePhone: '',
    fax: '',
    email: '',
    departmentName: '',
    lastName: '',
    firstName: '',
    mi: '',
    afflicationStartDate: null,
    afflicationEndDate: null,
    unrestrictedPrivileges: false,
    privilegesTemporary: false,
    privilegeStatus: '',
    whatPercentage: '',
    doYouHaveHospitalPrivileges: false,
    explain: ''
  };

  return data;
}
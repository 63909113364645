import React from "react";

import accmeLogo from "@images/accme-certified-logo.png";
import californiaPrimaryCareLogo from "@images/california-primary-care-association-logo.png";
import ncqaLogo from "@images/ncqa-logo.jpg";

import styles from "./Footer.module.scss";
import moment from "moment";

const Footer = () => {
  const accmeLogoAttributes = {
    src: accmeLogo,
    className: `${styles.logo} ${styles.accmeLogo}`,
  };

  const californiaPrimaryLogoAttributes = {
    src: californiaPrimaryCareLogo,
    className: styles.logo,
  };

  const ncqaLogoAttributes = {
    src: ncqaLogo,
    className: styles.logo,
  };

  const currentYear = moment().year();

  return (
    <footer className={`main-footer ${styles.footerMain}`}>
      <div className={styles.logoContainer}>
        <img {...ncqaLogoAttributes} alt="application-logo" />
        <img {...accmeLogoAttributes} alt="application-logo" />
        <img {...californiaPrimaryLogoAttributes} alt="application-logo" />
      </div>
      <label className={styles.copyrightLabel}>
        © {currentYear} - e2o Health. All rights reserved
      </label>
    </footer>
  );
};

export default Footer;

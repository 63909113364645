import { IAccordianProps } from "@helpers/uiInterfaces/common";
import Accordion from "react-bootstrap/Accordion";
import styles from "./Accordian.module.scss";

const AccordianComponent: React.FC<IAccordianProps> = (props) => {
  const { accordianData, defaultActiveKey } = props;
  return (
    <Accordion
      defaultActiveKey={[defaultActiveKey || ""]}
      alwaysOpen
      id="custom-accordian"
      className={styles.accordian}
    >
      {accordianData.map((data, i) => (
        <Accordion.Item eventKey={String(i)} key={i}>
          <Accordion.Header as={"h4"} bsPrefix={styles.customAaccordionHeader}>
            {data.accordianHeading}
          </Accordion.Header>
          <Accordion.Body>{data.accordianData}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default AccordianComponent;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_HomeAddressModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultHomeAddressData } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  homeAddressData: {} as IPerProf_HomeAddressModel,
  error: null as any,
  loading: false
}

const homeAddressSlice = createSlice({
  name: 'homeAddress',
  initialState,
  reducers: {

    updateHomeAddress: (state, action: PayloadAction<IPerProf_HomeAddressModel>) => {
      state.homeAddressData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.homeAddressData = setDefaultHomeAddressData(userRecord);
    }

  }
});

export const homeAddressAction = homeAddressSlice.actions;
export default homeAddressSlice.reducer;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IDocCommentPaginationModel,
  IDocCommentParentModel,
} from "@helpers/interfaces/manageDocuments/DocComment";

const initialState = {
  docCommentListData: {} as IDocCommentPaginationModel,
  error: null as any,
  loading: false,
};

const docCommentSlice = createSlice({
  name: "gapsInCred",
  initialState,
  reducers: {
    updateDocCommentListData: (
      state,
      action: PayloadAction<IDocCommentPaginationModel>
    ) => {
      state.docCommentListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.docCommentListData = {} as IDocCommentPaginationModel;
    },
  },
});

export const docCommentActions = docCommentSlice.actions;
export default docCommentSlice.reducer;

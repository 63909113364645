import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultBillingContactData } from '@helpers/configMiddlewares/serviceSite';
import { IServSiteBillingContactModel } from '@helpers/interfaces/serviceSites/BillingContact';

const initialState = {
  billingContactData: {} as IServSiteBillingContactModel,
  billingContactsDataList: [] as Array<IServSiteBillingContactModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const billingContactSlice = createSlice({
  name: 'billingContact',
  initialState,
  reducers: {

    updateBillingContactData: (state, action: PayloadAction<IServSiteBillingContactModel>) => {
      state.billingContactData = action.payload;
    },

    updateBillingContactDataList: (state, action: PayloadAction<Array<IServSiteBillingContactModel>>) => {
      state.billingContactsDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.billingContactData = setDefaultBillingContactData();
    }

  }
});

export const billingContactAction = billingContactSlice.actions;
export default billingContactSlice.reducer;
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { parseSelectControlsData } from "@utilities/index";
import orderStatusOptions from "@constants/json-data/order-summary/order-status-options";
import orderSourceOptions from "@constants/json-data/order-summary/order-source-options";
import monthOptions from "@constants/json-data/masters-data/month";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import SearchField from "@common/search-field/SearchField";
import styles from "./OrderSummary.module.scss";
import {
  getAllEmployeeStatus,
  getYearOptions,
} from "@services/master/master.services";
import { IOrderSummaryFilterEditorProps } from "@helpers/uiInterfaces/order-summary";
import { setDefaultOrderSummaryFilterData } from "@helpers/configMiddlewares/order-summary";

const OrderSummaryFilterEditor: React.FC<IOrderSummaryFilterEditorProps> = (
  props
) => {
  const { open, filterData, onSearch, onClose } = props;

  const dispatch = useDispatch<any>();

  const [_filterData, _setFilterData] = useState(filterData);
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    loadDependencies();
  }, []);

  useEffect(() => {
    _setFilterData(filterData);
  }, [filterData]);

  async function loadDependencies() {
    getYearOptionsData();
    await dispatch(getAllEmployeeStatus());
  }

  const getYearOptionsData = async () => {
    const response = await dispatch(getYearOptions());
    if (response.status === 200) {
      setYearOptions(response.data);
    }
  };

  function handleSearchFieldControl(searchText: string = "") {
    _setFilterData((latestFilterData) => {
      let data = {
        ...latestFilterData,
        search: searchText,
      };

      onSearch(data);

      return data;
    });
  }

  function handleSelectControlChange(name: string, value: string) {
    _setFilterData((latestFilterData) => {
      return {
        ...latestFilterData,
        [name]: value,
      };
    });
  }

  function renderSearchFieldControl() {
    const searchFieldControlAttributes = {
      placeholder: "Search by First or Last Name",
      value: _filterData.searchText,
      onSearch: handleSearchFieldControl,
      onClear: handleSearchFieldControl,
      onChange(searchText: string) {
        _setFilterData({
          ..._filterData,
          searchText: searchText,
        });
      },
    };

    return (
      <div className="form-group">
        <div className="lbl">
          <label className="d-flex align-center">Search By First or Last Name</label>
        </div>
        <SearchField {...searchFieldControlAttributes} />
      </div>
    );
  }

  function renderControlsSection() {
    const cancelControlAttributes = {
      className: "btn btnorg w100 border-button",
      onClick: onClose,
    };

    const searchControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        onSearch(_filterData);
      },
    };

    const resetControlAttributes = {
      className: "btn btnorg w100",
      onClick() {
        const defaultValues = setDefaultOrderSummaryFilterData();
        _setFilterData(defaultValues);
        onSearch(defaultValues);
      },
    };

    return (
      <div className="mdl-footer">
        <button {...cancelControlAttributes}>Cancel</button>
        <button {...resetControlAttributes}>Reset</button>
        <button {...searchControlAttributes}>Search</button>
      </div>
    );
  }

  function renderContent() {
    const sourceControlAttributes = {
      label: "Select Source",
      name: "orderSource",
      disableDefaultSelectOption: true,
      data: orderSourceOptions,
      value: _filterData.orderSource,
      onChange: handleSelectControlChange,
    };

    const monthControlAttributes = {
      label: "Select Month",
      name: "month",
      disableDefaultSelectOption: true,
      data: monthOptions,
      value: _filterData.month,
      onChange: handleSelectControlChange,
    };

    const yearControlAttributes = {
      label: "Select Year",
      name: "year",
      disableDefaultSelectOption: true,
      data: parseSelectControlsData(yearOptions),
      value: _filterData.year,
      onChange: handleSelectControlChange,
    };

    const statusControlAttributes = {
      label: "Select Status",
      name: "orderStatus",
      disableDefaultSelectOption: true,
      data: orderStatusOptions,
      value: _filterData.orderStatus,
      onChange: handleSelectControlChange,
    };

    return (
      <Offcanvas.Body className={styles.orderSummaryFilterEditorMain}>
        <div>
          {renderSearchFieldControl()}
          <FormSelectInputControl {...sourceControlAttributes} />
          <FormSelectInputControl {...monthControlAttributes} />
          <FormSelectInputControl {...yearControlAttributes} />
          <FormSelectInputControl {...statusControlAttributes} />
        </div>

        {renderControlsSection()}
      </Offcanvas.Body>
    );
  }

  return (
    <Offcanvas show={open} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Search & Filter Order Summary List</Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default OrderSummaryFilterEditor;

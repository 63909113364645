import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const docuSignReportsColumns: Array<IApplicationTableColumn> = [
  {
    id: "docuSignSentDate",
    label: "DocuSign Sent Date",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "docuSignSignDate",
    label: "DocuSign Sign Date",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "envelopeId",
    label: "Envelope Id",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "isDeleteLabel",
    label: "Is Delete",
    type: applicationTableColumnsTypes.YESNO,
  },
  {
    id: "moduleName",
    label: "Module Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "organizationName",
    label: "Organization Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "providerName",
    label: "Provider Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "templateId",
    label: "Template Id",
    type: applicationTableColumnsTypes.STRING,
  },
];

export default docuSignReportsColumns;

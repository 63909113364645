import axiosClient from "@store/axiosClient";

import { IClinicalWorkHistoryModel, IDeleteClinicalWorkHistoryParamsModel } from "@helpers/interfaces/credentialProfile/WorkHistoryRefTab";

import toastTypes from "@constants/toast-types";

import { clinicalWorkHistoryActions } from "@features/credentialProfile/workHistoryRefTab/ClinicalWorkHistory";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleCreateServiceResponse, handleServerInteractionError, handleDeleteServiceResponse } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(clinicalWorkHistoryActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(clinicalWorkHistoryActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(clinicalWorkHistoryActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(clinicalWorkHistoryActions.updateEditorLoading(false));
}

export const getAllClinicalWorkHistory = (hrmrUserId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient.get(`api/CredentialingProfile/GetWorkHistoryAll?HRMRUserId=${hrmrUserId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(clinicalWorkHistoryActions.updateClinicalWorkHistoryDataList(data));

        resolve(response);
        return hideLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });

};

export const getClinicalWorkHistory = (workHistoryId: number) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient.get(`api/CredentialingProfile/GetWorkHistory?WorkHistoryId=${workHistoryId}`);
      const data = response.data;
      const status = response.status;

      if (status === 200) {

        dispatch(clinicalWorkHistoryActions.updateClinicalWorkHistoryData(data));

        resolve(response);
        return hideEditorLoading(dispatch);

      }

      dispatch(toastMessageActions.showToast({
        message: data.title,
        type: toastTypes.DANGER
      }));

      hideEditorLoading(dispatch);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });

};

export const createClinicalWorkHistory = (data: IClinicalWorkHistoryModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showEditorLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/CreateWorkHistory",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleCreateServiceResponse(dispatch, clinicalWorkHistoryActions, status, response.data);

      hideEditorLoading(dispatch);
      resolve(response);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }

  });
};

export const deleteClinicalWorkHistory = (data: IDeleteClinicalWorkHistoryParamsModel) => async (dispatch: any) => {

  return new Promise(async (resolve, reject) => {

    try {

      showLoading(dispatch);

      const response = await axiosClient({
        method: "post",
        url: "api/CredentialingProfile/DeleteWorkHistory",
        data: `${JSON.stringify(data)}`
      });
      const status = response.status;

      handleDeleteServiceResponse(dispatch, status, response.data);

      hideLoading(dispatch);
      resolve(response);

    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }

  });

};
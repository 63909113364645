import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";

const initialState = {
  userRecord: {} as IUserRecordModel,
  domainName: "" as string,
  error: null as any,
  loading: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateUserRecord: (state, action: PayloadAction<IUserRecordModel>) => {
      state.userRecord = action.payload;
    },

    updateDomainName: (state, action: PayloadAction<string>) => {
      state.domainName = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetUserRecord: (state) => {
      state.userRecord = {} as IUserRecordModel;
      state.domainName = "";
      state.loading = false;
      state.error = null;
    },
  },
});

export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;

import React from "react";

import { IFormCheckboxControlProps } from "helpers/uiInterfaces/form-controls";

const FormCheckboxControl: React.FC<IFormCheckboxControlProps> = (props) => {
  const {
    label,
    name,
    checked,
    disabled,
    marginTop,
    disableTopBottomMargin,
    onChange,
  } = props;

  const checkboxInputControlAttributes = {
    className: "form-check-input mr-2",
    type: "checkbox",
    checked: checked ? checked : false,
    id: name,
    name,
    disabled,
    onChange,
  };

  return (
    <div className={disableTopBottomMargin ? "" : marginTop ? "mb-3" : "my-3"}>
      <label>
        <input {...checkboxInputControlAttributes} /> {"   "} {label}
      </label>
    </div>
  );
};

export default FormCheckboxControl;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IOrganizationWavierModel } from "@helpers/interfaces/organizations";

const initialState = {
  organizationWavierData: {} as IOrganizationWavierModel,
  error: null as any,
  loading: false,
};

const organizationWavierSlice = createSlice({
  name: "organizationWaiver",
  initialState,
  reducers: {
    updateOrganizationWavierData: (
      state,
      action: PayloadAction<IOrganizationWavierModel>
    ) => {
      state.organizationWavierData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.organizationWavierData = {} as IOrganizationWavierModel;
    },
  },
});

export const organizationWavierAction = organizationWavierSlice.actions;
export default organizationWavierSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUSCanadianModel } from '@helpers/interfaces/credentialProfile/EduTrainTab'
import { setDefaultUSCanadianData } from '@helpers/configMiddlewares/credentialingProfile/eduTrainTab';

import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  usCanadianData: {} as IUSCanadianModel,
  usCanadianDataList: [] as Array<IUSCanadianModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const graduateTypeSlice = createSlice({
  name: 'graduateType',
  initialState,
  reducers: {

    updateUSCanadianData: (state, action: PayloadAction<IUSCanadianModel>) => {
      state.usCanadianData = action.payload
    },

    updateUSCanadianDataList: (state, action: PayloadAction<Array<IUSCanadianModel>>) => {
      state.usCanadianDataList = action.payload
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.error = null;
      state.editorLoading = false;
      state.usCanadianData = setDefaultUSCanadianData(userRecord);
    }

  }
});

export const graduateTypeAction = graduateTypeSlice.actions;
export default graduateTypeSlice.reducer;
import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { mastersActions } from "@features/masters/Masters";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleServerInteractionError } from "@utilities/services";

export const getCountries = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("api/Master/GetAllCountry");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateCountries(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getStateByCountryId =
  (countryId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `/api/Master/GetAllState?CountryId=${countryId}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateStates(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getCitiesByStateId =
  (stateId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `/api/Master/GetAllCityByStateId?StateId=${stateId}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateCities(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export function getCityData() {
  return async (dispatch: any) => {
    try {
      const response = await axiosClient.get("api/Master/GetAllCity");
      dispatch(mastersActions.getCityState(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export const getAllSchoolCodes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("/api/Master/GetAllSchoolCodes");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateSchoolCodes(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getProviderTypes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllProviderTypeCode"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateProviderTypes(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAllSpecialtyCodes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllSpecialtyCodes"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateSpecialty(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getLocationData = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        `/api/Master/GetAllLocationsByOrgId`
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateLocationData(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getProviderTitle = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("/api/Master/GetAllProviderTitle");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateProviderTitle(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getLicenseStatusCodes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllLicenseStatusCodes"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateLicenseStatusCodes(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getLicenseFieldCodes = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllLicenseFieldCodes"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateLicenseFieldCodes(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getProviderLanguageCode = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllProviderLanguageCodes"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateProviderLanguageCode(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAppVerifications = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllAppVerification"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateAppVerification(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getOrganizationalTitles = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "/api/Master/GetAllOrganizationTitles"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateOrganizationalTitles(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAllMedicalSpecialtyTaxonomy =
  (searchText: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `/api/Master/GetAllSpecialtyTaxonomy?Search=${searchText}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateMedicalSpecialtyTaxonomyData(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getAllEmployeeStatus = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("api/Master/GetAllEmployeeStatus");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateAllEmployeeStatus(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAllVendors = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("api/Master/GetAllVendor");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateAllVendorsData(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAllPrivateAssignCategory =
  (assesmentType: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosClient.get(
          `api/Master/GetAllPrivateAssignCategory?assesmentType=${assesmentType}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(mastersActions.updateAllPrivateAssignCategory(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }

        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
      }
    });
  };

export const getYearOptions = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get("api/Training/GetYearList");
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateYearOptions(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getOrganizationtypeCode = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "api/Master/GetOrganizationTypeCode"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateOrganizationtypeCodes(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

export const getAllCredentialerUserList = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosClient.get(
        "api/Master/GetAllCrdentialerUsersList"
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(mastersActions.updateAllCredentialerUserList(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );
      }

      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
    }
  });
};

import React from "react";

import routesNames from "@constants/routes-names";

import { IRoute } from "helpers/uiInterfaces/common";
import DocuSignReport from "@pages/reports/DocuSignReport/DocuSignReport";
import PSV from "@pages/PSV/PSV";
import OrderSummary from "@pages/OrderSummary/OrderSummary";
import DashboardInitialAndReCred from "@pages/dashboardInitialAndReCred/DashboardInitialAndReCred";
import ImportExport from "@pages/importExclusion/ImportExclusionData";

const Dashboard = React.lazy(() => import("@pages/Dashboard"));
const Authenticate = React.lazy(
  () => import("@pages/authenticate/Authenticate")
);
const CredentialingProfile = React.lazy(
  () => import("@pages/credentialingProfile/Credentialing")
);
const NPDB = React.lazy(() => import("@pages/NPDB/NPDB"));
const ActivityChecklist = React.lazy(
  () => import("@pages/activityChecklist/ActivityChecklist")
);
const DepartmentList = React.lazy(
  () => import("@pages/departments/DepartmentList")
);
const ManageDocuments = React.lazy(
  () => import("@pages/manageDocuments/ManageDocuments")
);
const ServiceSites = React.lazy(
  () => import("@pages/serviceSites/ServiceSites")
);
const OrganizationalContacts = React.lazy(
  () => import("@pages/organizationalContacts/OrganizationalContacts")
);
const TrainingLibrary = React.lazy(
  () => import("@pages/trainingLibrary/TrainingLibrary")
);
const TrainingReport = React.lazy(
  () => import("@pages/trainingReport/TrainingReport")
);
const TrainingDetailsReport = React.lazy(
  () => import("@pages/trainingReport/TrainingDetailsReport")
);
const Expirables = React.lazy(() => import("@pages/expirables/Expirables"));
const ManageProfile = React.lazy(
  () => import("@pages/manageProfile/ManageProfile")
);
const EmployeeCredentialingProfile = React.lazy(
  () =>
    import("@pages/employeeCredentialingProfile/EmployeeCredentialingProfile")
);
const EmployeeFiles = React.lazy(
  () => import("@pages/employeeFiles/EmployeeFiles")
);
const ClinicalStaff = React.lazy(
  () => import("@pages/clinicalStaff/ClinicalStaff")
);
const NonClinicalStaff = React.lazy(
  () => import("@pages/nonClinicalStaff/NonClinicalStaff")
);
const SetupIntegrations = React.lazy(
  () => import("@pages/setupIntegrations/SetupIntegrations")
);

const initialRoutes: Array<IRoute> = [
  {
    path: routesNames.dashboardRoute,
    breadcrumb: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: routesNames.authenticateRoute,
    breadcrumb: "Authenticate",
    element: <Authenticate />,
  },
  {
    path: routesNames.credentialingProfileRoute,
    breadcrumb: "Credentialing Profile",
    element: <CredentialingProfile />,
  },
  {
    path: routesNames.npdbRoute,
    breadcrumb: "NPDB",
    element: <NPDB />,
  },
  {
    path: routesNames.psvRoute,
    breadcrumb: "PSV",
    element: <PSV />,
  },
  {
    path: routesNames.orderSummary,
    breadcrumb: "Order Summary",
    element: <OrderSummary />,
  },
  {
    path: routesNames.activityChecklistRoute,
    breadcrumb: "Activity Checklist",
    element: <ActivityChecklist />,
  },
  {
    path: routesNames.departmentsRoute,
    breadcrumb: "Departments",
    element: <DepartmentList />,
  },
  {
    path: routesNames.manageDocumentsRoute,
    breadcrumb: "Manage Documents",
    element: <ManageDocuments />,
  },
  {
    path: routesNames.serviceSitesRoute,
    breadcrumb: "Service Sites",
    element: <ServiceSites />,
  },
  {
    path: routesNames.organizationalContactsRoute,
    breadcrumb: "Organizational Contacts",
    element: <OrganizationalContacts />,
  },
  {
    path: routesNames.trainingLibraryRoute,
    breadcrumb: "Training Library",
    element: <TrainingLibrary />,
  },
  {
    path: routesNames.trainingReportRoute,
    breadcrumb: "Training Report",
    element: <TrainingReport />,
  },
  {
    path: routesNames.trainingDetailsReportRoute,
    breadcrumb: "Details",
    element: <TrainingDetailsReport />,
  },
  {
    path: routesNames.expirablesRoute,
    breadcrumb: "Expirables",
    element: <Expirables />,
  },
  {
    path: routesNames.manageProfileRoute,
    breadcrumb: "Manage Profile",
    element: <ManageProfile />,
  },
  {
    path: routesNames.employeeFiles,
    breadcrumb: "Employee Files",
    element: <EmployeeFiles />,
  },
  {
    path: routesNames.employeeCredentialingProfileRoute,
    breadcrumb: "Employee Credentialing Profile",
    element: <EmployeeCredentialingProfile />,
  },
  {
    path: routesNames.docuSignReport,
    breadcrumb: "DocuSign Report",
    element: <DocuSignReport />,
  },
  {
    path: routesNames.clinicalStaff,
    breadcrumb: "Licensed Staff",
    element: <ClinicalStaff />,
  },
  {
    path: routesNames.nonClinicalStaff,
    breadcrumb: "Non Licensed Staff",
    element: <NonClinicalStaff />,
  },
  {
    path: routesNames.setupIntegrationsRoute,
    breadcrumb: "Setup Integrations",
    element: <SetupIntegrations />,
  },
  {
    path: routesNames.dashboardInitialRoute,
    breadcrumb: "Dashboard Initial",
    element: <DashboardInitialAndReCred />,
  },
  {
    path: routesNames.dashboardReCredRoute,
    breadcrumb: "Dashboard Re-Cred",
    element: <DashboardInitialAndReCred />,
  },
  {
    path: routesNames.importExclusionData,
    breadcrumb: "Import Exclusion Data",
    element: <ImportExport />,
  },
];

export default initialRoutes;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITrainingReportPaginationModel } from '@helpers/interfaces/trainingReport';

const initialState = {
  trainingReportListData: {} as ITrainingReportPaginationModel,
  trainingDetailsReportListData: {} as ITrainingReportPaginationModel,
  error: null as any,
  loading: false
}

const trainingReportSlice = createSlice({
  name: 'trainingReport',
  initialState,
  reducers: {

    updateTrainingReportListData: (state, action: PayloadAction<ITrainingReportPaginationModel>) => {
      state.trainingReportListData = action.payload;
    },

    updateTrainingDetailsReportListData: (state, action: PayloadAction<ITrainingReportPaginationModel>) => {
      state.trainingDetailsReportListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetTrainingDetailsReportState: (state) => {
      state.loading = false;
      state.error = null;
      state.trainingDetailsReportListData = {} as ITrainingReportPaginationModel;
    }

  }
});

export const trainingReportActions = trainingReportSlice.actions;
export default trainingReportSlice.reducer;
const pageTitles = {
  default: "Hipaa Compliance",
  dashboard: "Dashboard | Hipaa Compliance",
  credentialingProfile: "Credentialing Profile",
  npdb: "NPDB",
  activityChecklist: "Activity Checklist",
  manageDocuments: "Manage Documents",
  authenticate: "Authenticate | Hipaa Compliance",
  departments: "Departments",
  serviceSites: "Organization's Service Site(s)",
  organizationalContacts: "Organizational Contacts",
  trainingLibrary: "Training Library",
  trainingReport: "Training Report",
  trainingDetailsReport: "Training Details Report",
  expirables: "Expirables",
  manageProfile: "Manage Profile",
  employeeCredentialingProfile: "Employee Credentialing Profile",
  employeeFiles: "Employee Files",
  docuSignReport: "DocuSign Report",
  clinicalStaff: "Licensed Staff",
  nonClinicalStaff: "Non Licensed Staff",
  setupIntegrations: "Setup Integrations",
  dashboardInitial: "Dashboard Initial",
  dashboardReCred: "Dashboard Re-Cred",
};

export default pageTitles;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IHospAffil_AdmittingArrangementsModel } from '@helpers/interfaces/credentialProfile/HospitalAffiliations';
import { setDefaultAdmittingArrangements } from '@helpers/configMiddlewares/credentialingProfile/hospitalAffilicationsTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  admittingArrangementsData: {} as IHospAffil_AdmittingArrangementsModel,
  error: null as any,
  loading: false
}

const admittingArrangements = createSlice({
  name: 'admittingArrangements',
  initialState,
  reducers: {

    updateAdmittingArrangementsData: (state, action: PayloadAction<IHospAffil_AdmittingArrangementsModel>) => {
      state.admittingArrangementsData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.admittingArrangementsData = setDefaultAdmittingArrangements(userRecord);
    }

  }
});

export const admittingArrangementsActions = admittingArrangements.actions;
export default admittingArrangements.reducer;
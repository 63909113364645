import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultAccessibilitiesData } from '@helpers/configMiddlewares/serviceSite';
import { IServSiteAccessibilitiesModel } from '@helpers/interfaces/serviceSites/Accessibilities';

const initialState = {
  accessiblitiesData: {} as IServSiteAccessibilitiesModel,
  error: null as any,
  loading: false
}

const accessiblitiesSlice = createSlice({
  name: 'accessiblities',
  initialState,
  reducers: {

    updateAccessibilities: (state, action: PayloadAction<IServSiteAccessibilitiesModel>) => {
      state.accessiblitiesData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.accessiblitiesData = setDefaultAccessibilitiesData();
    }

  }
});

export const accessiblitiesAction = accessiblitiesSlice.actions;
export default accessiblitiesSlice.reducer;
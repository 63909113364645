import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { handleServerInteractionError } from "@utilities/services";
import { docuSignReportActions } from "@features/reports/DocuSignReports";

function showLoading(dispatch: any) {
  dispatch(docuSignReportActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(docuSignReportActions.updateLoading(false));
}

export const getAllDocuSignReports =
  (
    pageNumber: number,
    pageSize: number,
    fromDate: Date | string,
    toDate: Date | string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient.get(
          `api/DocuSign/GetDocuSignReport?PageNumber=${pageNumber}&PageSize=${pageSize}&FromDate=${fromDate}&ToDate=${toDate}`
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(docuSignReportActions.updateDocuSignReportListData(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const exportDocuSignReports =
  (fromDate: Date | string, toDate: Date | string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient.get(
          `api/DocuSign/GetDocuSignReportExport?FromDate=${fromDate}&ToDate=${toDate}`
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(
            docuSignReportActions.updateDocuSignExportReportListData(data)
          );

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

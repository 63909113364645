import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";
import messages from "@constants/messages";

import { IUnderGraduateModel } from "@helpers/interfaces/credentialProfile/EduTrainTab";
import { setDefaultUndergraduateRecord } from "@helpers/configMiddlewares/credentialingProfile/eduTrainTab";

import { undergraduateAction } from "@features/credentialProfile/eduTrainTab/Undergraduate";
import { toastMessageActions } from "@features/ui/ToastMessage";

import { handleServerInteractionError } from "@utilities/services";
import { getApplicationStorage } from "@utilities/storage";

function showLoading(dispatch: any) {
  dispatch(undergraduateAction.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(undergraduateAction.updateLoading(false));
}

export const getUnderGraduateSchool =
  (hrmrUserId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const userRecord = getApplicationStorage();
        const response = await axiosClient.get(
          `api/CredentialingProfile/GetUndergraduateSchool?HRMRUserId=${hrmrUserId}`
        );
        let data = response.data;
        const status = response.status;

        if (status === 200) {
          if (!data) {
            response.data = setDefaultUndergraduateRecord(userRecord);
            resolve(response);
          } else {
            dispatch(undergraduateAction.updateUnderGraduateData(data));
            resolve(response);
          }

          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const createUnderGraduateSchool =
  (data: IUnderGraduateModel, calledByRequestReference: boolean) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/CredentialingProfile/CreateUndergraduateSchool",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        if (status === 200 && calledByRequestReference === false) {
          dispatch(
            toastMessageActions.showToast({
              message: messages.dataSuccessfullySaved,
              type: toastTypes.SUCCESS,
            })
          );
        }

        if (status !== 200) {
          dispatch(
            toastMessageActions.showToast({
              message: "Something went wrong!",
              type: toastTypes.DANGER,
            })
          );
        }

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IEmpDetailedData,
  IEmpExclusionData,
  IEmpExclusionLogsData,
  IExclusionTypes,
} from "@helpers/interfaces/employeeFiles";

const initialState = {
  employeeExclusionList: [] as Array<IEmpExclusionData>,
  employeeExclusionLogsList: [] as Array<IEmpExclusionLogsData>,
  employeeExclusionData: [] as Array<IEmpDetailedData>,
  exclusionTypes: [] as Array<IExclusionTypes>,
  error: null as any,
  loading: false,
};

const editEmployeesExclusionSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateEmployeeExclusionList: (
      state,
      action: PayloadAction<Array<IEmpExclusionData>>
    ) => {
      state.employeeExclusionList = action.payload;
    },

    updateEmployeeExclusionLogsList: (
      state,
      action: PayloadAction<Array<IEmpExclusionLogsData>>
    ) => {
      state.employeeExclusionLogsList = action.payload;
    },

    updateEmployeeExclusionData: (
      state,
      action: PayloadAction<Array<IEmpDetailedData>>
    ) => {
      state.employeeExclusionData = action.payload;
    },

    updateExclusionTypesList: (
      state,
      action: PayloadAction<Array<IExclusionTypes>>
    ) => {
      state.exclusionTypes = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeeExclusionList = [] as Array<IEmpExclusionData>;
      state.employeeExclusionLogsList = [] as Array<IEmpExclusionLogsData>;
    },
  },
});

export const editEmployeesExclusionActions =
  editEmployeesExclusionSlice.actions;
export default editEmployeesExclusionSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IEmpPPShareDocData,
  IEmpShareDocReviewAndApprovalData,
} from "@helpers/interfaces/employeeFiles";

const initialState = {
  employeePPShareDocList: [] as Array<IEmpPPShareDocData>,
  employeePPShareDocReviewAndApprovalList:
    [] as Array<IEmpShareDocReviewAndApprovalData>,
  error: null as any,
  loading: false,
};

const editEmployeesOnboardingSlice = createSlice({
  name: "addEditEmployees",
  initialState,
  reducers: {
    updateEmployeePPShareDocList: (
      state,
      action: PayloadAction<Array<IEmpPPShareDocData>>
    ) => {
      state.employeePPShareDocList = action.payload;
    },

    updateEmployeePPShareDocReviewAndApprovalList: (
      state,
      action: PayloadAction<Array<IEmpShareDocReviewAndApprovalData>>
    ) => {
      state.employeePPShareDocReviewAndApprovalList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.employeePPShareDocList = [] as Array<IEmpPPShareDocData>;
    },
  },
});

export const editEmployeesOnboardingActions =
  editEmployeesOnboardingSlice.actions;
export default editEmployeesOnboardingSlice.reducer;

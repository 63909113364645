import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadExclusionTemplate } from "@services/employeeFiles/EditEmployeeExclusions";
import { getAllExclusionTypes } from "@services/employeeFiles/EditEmployeeExclusions";
import { useAppSelector } from "@hooks/index";
import styles from "./ImportExclusionData.module.scss";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import { parseExclusionSelectControlsData } from "./utilities";
import ContentHeader from "@common/ContentHeader";
import importExportInstructions from "@constants/instructions/importExclusionDataInstructions";
import { downloadAzureBlobFile } from "@services/file-task";
import { downloadBlob } from "@utilities/file-download";
import { toastMessageActions } from "@features/ui/ToastMessage";
import toastTypes from "@constants/toast-types";
import BootstrapSpinner from "react-bootstrap/Spinner";

const ImportExclusionData = () => {
  const [selectedFile, setSelectedFile] = useState(String);
  const [selectedExclusion, setSelectedExclusion] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<any>();
  const exclusionState = useAppSelector(
    (state) => state.employeeFiles.editEmployeeExclusions
  );

  useEffect(() => {
    dispatch(getAllExclusionTypes());
  }, [dispatch]);

  const filechangeHandler = (event: any) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  };

  const handleDownloadTemplate = async () => {
    const res = await dispatch(
      downloadAzureBlobFile("ExclusionTemplate", "ExclusionTemplate.xls")
    );
    if (res.status === 200) {
      await downloadBlob(res.data, "ExclusionTemplate.xls");
    }
  };

  const handleSelectExclusionType = (name: string, value: string) => {
    setSelectedExclusion(value);
  };

  const handleSubmission = async (e: any) => {
    if (!selectedFile) {
      dispatch(
        toastMessageActions.showToast({
          message: "Please choose a file first",
          type: toastTypes.DANGER,
        })
      );
    } else if (!selectedExclusion) {
      dispatch(
        toastMessageActions.showToast({
          message: "Please choose a exclusion first",
          type: toastTypes.DANGER,
        })
      );
    } else {
      setLoading(true);
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("eTypeText", selectedExclusion);
      const res = await dispatch(uploadExclusionTemplate(formData));
      if (res.status === 200) {
        setLoading(false);
      }
    }
  };

  const exclusionSelectControlAttributes = {
    label: "",
    name: "exclusionType",
    data: parseExclusionSelectControlsData(exclusionState.exclusionTypes),
    value: selectedExclusion,
    onChange: handleSelectExclusionType,
  };

  const contentHeaderAttributes = {
    title: "Import Exclusion Data",
    instructions: importExportInstructions,
  };

  const downloadTemplateControlAttributes = {
    className: `btn btnorg`,
    onClick() {
      handleDownloadTemplate();
    },
  };

  return (
    <div id={styles.importExportMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className="clearfix" />
      <div className="grey_bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="midContent_wrapper">
                <div className="user_Import_block">
                  <div className="headings">
                    <h4>Import Users</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-5 col-lg-5 col-xl-4">
                      <div className="download_csv_div text-center">
                        <p>
                          Download the XLS template to include the users you
                          would like to add then uplaod the XLS
                        </p>
                        <button {...downloadTemplateControlAttributes}>
                          Download Button
                        </button>
                      </div>
                    </div>
                    <div className="col-md-1 col-lg-1 text-center">
                      <div className="or_txt">
                        <span>Or</span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-5 col-xl-4">
                      <div className="upload_csv_div">
                        <p className="text-center">
                          Uplaod your Excel or XLS File here
                        </p>
                        <div className="row align-items-center justify-content-center">
                          <form
                            onSubmit={handleSubmission}
                            className={styles.formContent}
                          >
                            <div
                              className={`col-md-8 col-lg-6 ${styles.selectBox}`}
                            >
                              <FormSelectInputControl
                                {...exclusionSelectControlAttributes}
                              />
                              <div className="file_btn_div">
                                {
                                  <label
                                    htmlFor="file-upload"
                                    className="custom-file-upload"
                                  >
                                    Browse File
                                  </label>
                                }
                                <input
                                  id="file-upload"
                                  name="upload_cont_img"
                                  type="file"
                                  onChange={filechangeHandler}
                                  accept=".xlsx,.xls"
                                />
                              </div>
                            </div>
                            <div className="col-md-10 col-lg-10 text-center">
                              <div className="fileUploadBtn mt-3 mb-3">
                                <button className="btn btnorg">
                                  {loading ? (
                                    <BootstrapSpinner
                                      animation="border"
                                      size="sm"
                                    />
                                  ) : (
                                    "Upload File"
                                  )}
                                </button>
                              </div>
                              Note : if your excel have large data than it took
                              take time
                            </div>
                          </form>
                        </div>
                        <div className="row" style={{ marginTop: 5 }}>
                          <div className="col-lg-1" />
                          <div className="col-md-10 col-lg-10">
                            <div id="file-upload-filename" />
                          </div>
                          <div className="col-lg-1" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="spacer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportExclusionData;

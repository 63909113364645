import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  INPDBAgentDetailsModel,
  INPDBPersonalInfoModel,
  INPDBSetupDetailsModel,
  INPDBSetupLogsModel,
} from "@helpers/interfaces/setupIntegrations/NPDB";

const initialState = {
  npdbSetupDetails: {} as INPDBSetupDetailsModel,
  npdbSetupLogs: [] as Array<INPDBSetupLogsModel>,
  npdbPersonalInfo: {} as INPDBPersonalInfoModel,
  npdbAgentDetails: {} as INPDBAgentDetailsModel,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const npdbSetupSlice = createSlice({
  name: "npdbSetup",
  initialState,
  reducers: {
    updateNPDBSetupDetails: (
      state,
      action: PayloadAction<INPDBSetupDetailsModel>
    ) => {
      state.npdbSetupDetails = action.payload;
    },

    updateNPDBSetupLogs: (
      state,
      action: PayloadAction<Array<INPDBSetupLogsModel>>
    ) => {
      state.npdbSetupLogs = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    updateNPDBPersonalInfo: (
      state,
      action: PayloadAction<INPDBPersonalInfoModel>
    ) => {
      state.npdbPersonalInfo = action.payload;
    },

    updateNPDBAgentDetails: (
      state,
      action: PayloadAction<INPDBAgentDetailsModel>
    ) => {
      state.npdbAgentDetails = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.editorLoading = false;
      state.error = null;
      state.npdbSetupDetails = {} as INPDBSetupDetailsModel;
      state.npdbPersonalInfo = {} as INPDBPersonalInfoModel;
      state.npdbSetupLogs = [];
    },
  },
});

export const npdbSetupActions = npdbSetupSlice.actions;
export default npdbSetupSlice.reducer;

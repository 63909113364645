const credentialProfileTabIds = {
  perProfInfoTab: 1,
  eduTrainingTab: 2,
  profMedicalSpecialtyInfoTab: 3,
  practiceLocationInfoTab: 4,
  hospitalAffiliationsTab: 5,
  profLiabilityInsuranceTab: 6,
  workHistoryRefTab: 7,
  disclosuresTab: 8
};

export default credentialProfileTabIds;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAMASetupDetailsModel } from '@helpers/interfaces/setupIntegrations/AMA';

const initialState = {
  amaSetupDetails: {} as IAMASetupDetailsModel,
  error: null as any,
  loading: false
}

const amaSetupSlice = createSlice({
  name: 'amaSetup',
  initialState,
  reducers: {

    updateAMASetupDetails: (state, action: PayloadAction<IAMASetupDetailsModel>) => {
      state.amaSetupDetails = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.amaSetupDetails = {} as IAMASetupDetailsModel;
    }

  }
});

export const amaSetupActions = amaSetupSlice.actions;
export default amaSetupSlice.reducer;
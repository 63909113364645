import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IValidateActivityModel } from "@helpers/interfaces/activityValidator";

const initialState = {
  validateNPIABMSActivityData: {} as IValidateActivityModel,
  error: null as any,
  loading: false
};

const validateNPIABMSActivitySlice = createSlice({
  name: "validateNPIABMSActivity",
  initialState,
  reducers: {
    updateValidateNPIABMSActivityData: (state, action: PayloadAction<IValidateActivityModel>) => {
      state.validateNPIABMSActivityData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetEditorState: (state) => {
      state.error = null;
      state.loading = false;
    }
  },
});

export const validateNPIABMSActivityActions = validateNPIABMSActivitySlice.actions;
export default validateNPIABMSActivitySlice.reducer;

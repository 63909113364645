import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfMedical_SpecialtyModel } from '@helpers/interfaces/credentialProfile/ProfMedical';
import { setDefaultMedicalSpecialtyData } from '@helpers/configMiddlewares/credentialingProfile/profMedicalSpecialtyTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  specialtyCertification: {} as IProfMedical_SpecialtyModel,
  specialtyCertificationList: [] as Array<IProfMedical_SpecialtyModel>,
  error: null as any,
  loading: false,
  editorLoading: false
};

const medicalSpecialty = createSlice({
  name: 'medicalSpecialty',
  initialState,
  reducers: {

    updateSpecialtyCertification: (state, action: PayloadAction<IProfMedical_SpecialtyModel>) => {
      state.specialtyCertification = action.payload;
    },

    updateSpecialtyCertificationList: (state, action: PayloadAction<Array<IProfMedical_SpecialtyModel>>) => {
      state.specialtyCertificationList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.error = null;
      state.editorLoading = false;
      state.specialtyCertification = setDefaultMedicalSpecialtyData(userRecord);
    }

  }
});

export const medicalSpecialtyActions = medicalSpecialty.actions;
export default medicalSpecialty.reducer;
import React, { useState } from "react";

import { IContentHeaderProps } from "helpers/uiInterfaces/common";

import InstructionsDialog from "./instructions-dialog/InstructionsDialog";
import Breadcrumbs from "./breadcrumbs/Breadcrumbs";

const ContentHeader: React.FC<IContentHeaderProps> = (props) => {
  const {
    title,
    instructions,
    renderInstructionsElement,
    breadcrumbData,
    renderExtraContent,
    breadcrumbs,
  } = props;

  const [displayInstructionsDialog, setDisplayInstructionsDialog] =
    useState(false);

  function openInstructionsDialog() {
    setDisplayInstructionsDialog(true);
  }

  function closeInstructionsDialog() {
    setDisplayInstructionsDialog(false);
  }

  function renderInstructionsDialog() {
    if (displayInstructionsDialog === false) {
      return;
    }

    const instructionDialogAttributes = {
      instructions,
      renderInstructionsElement,
      open: displayInstructionsDialog,
      onClose: closeInstructionsDialog,
    };

    return <InstructionsDialog {...instructionDialogAttributes} />;
  }

  function renderInstructionsControl() {
    if (
      typeof instructions === "undefined" &&
      typeof renderInstructionsElement === "undefined"
    ) {
      return;
    }

    const instructionsControlAttribute = {
      className: "InfobarBtn btn",
      onClick: openInstructionsDialog,
    };

    return (
      <button {...instructionsControlAttribute}>
        <span className="icon-info" />
      </button>
    );
  }

  function renderExtraContentElement() {
    if (typeof renderExtraContent === "undefined") {
      return;
    }

    return renderExtraContent;
  }

  function renderBreadcrumbs() {
    if (breadcrumbs === false) {
      return;
    }

    return <Breadcrumbs data={breadcrumbData} />;
  }

  return (
    <div className="content-header">
      <div className="container-fluid">
        <div className="topBar customebreadcum">
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="m-0">{title}</h1>
              {renderBreadcrumbs()}
            </div>

            <div>
              <div className="right_section">
                {/* used to render credential profile element (user name and profile image) */}
                {renderExtraContentElement()}

                <div className="infomation">{renderInstructionsControl()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderInstructionsDialog()}
    </div>
  );
};

export default ContentHeader;

import { IDocuSignReportModel } from "@helpers/interfaces/reports/DocuSignReport";
import { notApplicableDateFunc } from "@utilities/index";

export function parseDocuSignReportsRows(rows: Array<IDocuSignReportModel>) {
  const _rows = [];

  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];

    const item = {
      ...row,
      docuSignSentDate: row.docSentDate
        ? notApplicableDateFunc(row.docSentDate)
        : "",
      docuSignSignDate: row.docuSignDate
        ? notApplicableDateFunc(row.docuSignDate)
        : "",
      isDeleteLabel: row.isDelete ? "Yes" : "No",
    };
    _rows.push(item);
  }

  return _rows;
}

export function createDocuSignReport(data: Array<IDocuSignReportModel>) {
  const report = [];

  for (let docuSignReport of data) {
    const reportItem = {
      "DocuSign Sent Date": docuSignReport.docSentDate
        ? notApplicableDateFunc(docuSignReport.docSentDate)
        : "",
      "DocuSign Sign Date": docuSignReport.docuSignDate
        ? notApplicableDateFunc(docuSignReport.docuSignDate)
        : "",
      "Envelope id": docuSignReport.envelopeId,
      isDelete: docuSignReport.isDelete ? "Yes" : "No",
      "Module Name": docuSignReport.moduleName,
      "Organization Name": docuSignReport.organizationName,
      "Provider Name": docuSignReport.providerName,
      "Template Id": docuSignReport.templateId,
    };

    report.push(reportItem);
  }

  return report;
}

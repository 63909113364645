import React from 'react';

import { Modal } from 'react-bootstrap';

import { IInstructionsDialogProps } from 'helpers/uiInterfaces/common';

import { ReactComponent as InformationIcon } from '@images/information-sign.svg';
import { ReactComponent as CloseIcon } from '@images/close-icon.svg';

import styles from './InstructionsDialog.module.scss';

const InstructionsDialog: React.FC<IInstructionsDialogProps> = (props) => {

  const { instructions, renderInstructionsElement, open, onClose } = props;

  function renderModalHeader() {

    const closeControlAttributes = {
      className: styles.closeControl,
      onClick: onClose
    };

    return (
      <div className={styles.dialogHeader}>
        <InformationIcon className={styles.infoIcon} />
        <h4 className={styles.instructionsHeading}>Instructions</h4>
        <button {...closeControlAttributes}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
    );

  }

  function renderModalContent() {

    if (renderInstructionsElement || typeof instructions === 'undefined') {

      return (
        <div className={styles.dialogContent}>
          {renderInstructionsElement}
        </div>
      );

    }

    return (
      <div className={styles.dialogContent}>
        <ul>
          {
            instructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))
          }
        </ul>
      </div>
    );
  }

  const modalAttributes = {
    show: open,
    onHide: onClose
  };

  return (
    <Modal {...modalAttributes}>
      <div id={styles.instructionsDialogMain}>
        {renderModalHeader()}
        {renderModalContent()}
      </div>
    </Modal>
  );
};

export default InstructionsDialog;
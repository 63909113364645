import { combineReducers } from '@reduxjs/toolkit';

import TrainingLibraryReducer from './TrainingLibrary';
import TrainingReportReducer from './TrainingReport';

const trainingReducer = combineReducers({
  trainingLibrary: TrainingLibraryReducer,
  trainingReport: TrainingReportReducer
});

export default trainingReducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IVeritableScreeningSetupDetailsModel } from '@helpers/interfaces/setupIntegrations/VeritableScreening';

const initialState = {
  veritableScreeningSetupDetails: {} as IVeritableScreeningSetupDetailsModel,
  error: null as any,
  loading: false
}

const veritableScreeningSetupSlice = createSlice({
  name: 'veritableScreeningSetup',
  initialState,
  reducers: {

    updateVeritableScreeningSetupDetails: (state, action: PayloadAction<IVeritableScreeningSetupDetailsModel>) => {
      state.veritableScreeningSetupDetails = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.veritableScreeningSetupDetails = {} as IVeritableScreeningSetupDetailsModel;
    }

  }
});

export const veritableScreeningSetupActions = veritableScreeningSetupSlice.actions;
export default veritableScreeningSetupSlice.reducer;
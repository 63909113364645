import { combineReducers } from '@reduxjs/toolkit';

import ServiceSiteReducer from './ServiceSites';
import PaymentRemittanceReducer from './PaymentRemittance';
import OpenPracticeStatusReducer from './OpenPracticeStatus';
import OfficeManagerReducer from './OfficeManager';
import LanguagesReducer from './Languages';
import AccessiblitiesReducer from './Accessibilities';
import ServicesReducer from './Services';
import PractitionerDetailsReducer from './PractitionerDetails';
import PartnerAssociatesReducer from './PartnerAssociates';
import OfficeHoursReducer from './OfficeHours';
import BillingContactReducer from './BillingContact';
import PrimaryCredentialingContactReducer from './PrimaryCredentialingContact';

const serviceSiteReducers = combineReducers({
  serviceSiteState: ServiceSiteReducer,
  paymentRemittance: PaymentRemittanceReducer,
  openPracticeStatus: OpenPracticeStatusReducer,
  officeManager: OfficeManagerReducer,
  languages: LanguagesReducer,
  accessiblities: AccessiblitiesReducer,
  services: ServicesReducer,
  practitionerDetails: PractitionerDetailsReducer,
  partnerAssociates: PartnerAssociatesReducer,
  officeHours: OfficeHoursReducer,
  billingContact: BillingContactReducer,
  primaryCredentialingContact: PrimaryCredentialingContactReducer
});

export default serviceSiteReducers;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServSitePaymentRemittanceModel } from '@helpers/interfaces/serviceSites/PaymentRemittance';
import { setDefaultPaymentRemittance } from '@helpers/configMiddlewares/serviceSite';

const initialState = {
  paymentRemittanceData: {} as IServSitePaymentRemittanceModel,
  error: null as any,
  loading: false
}

const paymentRemittanceSlice = createSlice({
  name: 'paymentRemittance',
  initialState,
  reducers: {

    updatePaymentRemittanceData: (state, action: PayloadAction<IServSitePaymentRemittanceModel>) => {
      state.paymentRemittanceData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.paymentRemittanceData = setDefaultPaymentRemittance();
    }

  }
});

export const paymentRemittanceAction = paymentRemittanceSlice.actions;
export default paymentRemittanceSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDefaultActivityData } from '@helpers/configMiddlewares/activityChecklist/Activity';
import { IActivitiesPaginationModel, IActivityModel } from '@helpers/interfaces/activityChecklist/Activity';

const initialState = {
  activityData: {} as IActivityModel,
  activityListData: {} as IActivitiesPaginationModel,
  error: null as any,
  loading: false,
  editorLoading: false
}

const activity = createSlice({
  name: 'activity',
  initialState,
  reducers: {

    updateActivityData: (state, action: PayloadAction<IActivityModel>) => {
      state.activityData = action.payload;
    },

    updateActivityListData: (state, action: PayloadAction<IActivitiesPaginationModel>) => {
      state.activityListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.activityData = setDefaultActivityData();
    }

  }
});

export const activityActions = activity.actions;
export default activity.reducer;
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function fitToColumn(data: Array<any>) {
  const columnWidths = [];

  for (const property in data[0]) {
    columnWidths.push({
      wch: Math.max(
        property ? property.toString().length : 0,
        ...data.map((obj) =>
          obj[property] ? obj[property].toString().length : 0
        )
      ),
    });
  }

  return columnWidths;
}

export async function exportToExcel(
  excelData: Array<any>,
  fileName: string,
  sheetName: string
) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(excelData);

  ws["!cols"] = fitToColumn(excelData);

  for (let i in ws) {
    if (typeof ws[i] != "object") {
      continue;
    }

    let cell = XLSX.utils.decode_cell(i);

    //styling for first row
    if (cell.r === 0) {
      ws[i].s = {
        font: {
          bold: true,
        },
      };
    }
  }

  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + fileExtension);
}

export async function exportToExcelWithCustomString(
  excelData: Array<any>,
  fileName: string,
  sheetName: string,
  customTextStart: string,
  customTextEnd: string
) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Create a new worksheet
  const ws = XLSX.utils.aoa_to_sheet([[]]); // Create an empty worksheet

  // Set bold styling for custom text at the start (A1)
  ws.A1 = { v: customTextStart, t: "s", s: { font: { bold: true } } };

  // Convert data to worksheet starting from A2
  XLSX.utils.sheet_add_json(ws, excelData, { origin: "A2" });

  // Calculate column widths based on maximum content length
  const colWidths = excelData.reduce((acc, row) => {
    Object.keys(row).forEach((key) => {
      const cellContent = row[key] ? row[key].toString() : "";
      const contentLength = cellContent.length;
      const currentWidth = acc[key] || 10; // Minimum width
      acc[key] = Math.max(currentWidth, contentLength + 2); // Add some padding
    });
    return acc;
  }, {});

  // Set column widths
  ws["!cols"] = Object.keys(colWidths).map((col) => ({
    wch: colWidths[col],
  }));

  // Find the last used row in the worksheet
  let lastUsedRow = 1;
  for (const cellAddress in ws) {
    if (cellAddress.startsWith("!ref")) {
      const range = XLSX.utils.decode_range(ws[cellAddress]);
      lastUsedRow = range.e.r + 1;
    }
  }

  // Insert the custom text at the end
  XLSX.utils.sheet_add_aoa(ws, [[customTextEnd]], {
    origin: `A${lastUsedRow + 1}`, // Start from the row after the last data row
  });

  // Set bold styling for custom text at the start (A${lastUsedRow + 1})
  ws[`A${lastUsedRow + 1}`] = {
    v: customTextEnd,
    t: "s",
    s: { font: { bold: true } },
  };

  // Apply bold styling to the data header row elements
  const headerRow = Object.keys(excelData[0]).map((key) => ({
    v: key,
    t: "s",
    s: { font: { bold: true } },
  }));
  XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: "A2" });

  // Assign the worksheet to the workbook with the specified sheet name
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  // Convert the workbook to Excel data
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  // Save the data as a file with the specified file name
  FileSaver.saveAs(data, fileName + fileExtension);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {ITrainingDetailsModel} from '@helpers/interfaces/credentialProfile/EduTrainTab'
import { setDefaultTrainingDetails } from '@helpers/configMiddlewares/credentialingProfile/eduTrainTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  trainingDetailsData: {} as ITrainingDetailsModel,
  trainingDetailsListData: [] as Array <ITrainingDetailsModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const trainingDetailsSlice = createSlice({
  name: 'trainingDetails',
  initialState,
  reducers: {

    updateTrainingDetailsData: (state, action: PayloadAction<ITrainingDetailsModel>) => {
      state.trainingDetailsData = action.payload;
    },

    updateTrainingDetailsListData: (state, action: PayloadAction<Array<ITrainingDetailsModel>>) => {
      state.trainingDetailsListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.trainingDetailsData = setDefaultTrainingDetails(userRecord);
    }

  }
});

export const trainingDetailsAction = trainingDetailsSlice.actions;
export default trainingDetailsSlice.reducer;
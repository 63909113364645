const documentsCredentialingSubTabs = [
  {
    label: "Add / Edit Document",
    index: 1,
  },
  {
    label: "Gaps in Credentialing",
    index: 2,
  },
  // {
  //   label: "Payer Enrollment",
  //   index: 3,
  // },
  {
    label: "Doc Comment",
    index: 4,
  },
];

export default documentsCredentialingSubTabs;

import {
  IManageDocumentChildModel,
  IManageDocumentsParentModel,
} from "@helpers/interfaces/manageDocuments/AddEditDocuments";

export function setDefaultManageDocumentData(
  hrmrUserId?: number,
  activityId?: number
): IManageDocumentsParentModel {
  const data = {
    manageActId: 0,
    hrmrUserId: hrmrUserId ? hrmrUserId : 0,
    activityId: activityId ? activityId : 0,
    description: "",
    showHiddenItems: false,
    manageActiviyDocs: [],
  };

  return data;
}

export function setDefaultManageDocumentChildData(): IManageDocumentChildModel {
  const data = {
    manageActDocId: 0,
    manageActId: 0,
    docTypeId: 0,
    docType: null,
    fileName: "",
    filePath: "",
    file: null,
    actualFileName: "",
    verifiedDate: null,
    expiryDate: null,
    activityNumber: "",
    isHide: false,
    foreignId: 0,
    verifiedBy: null,
    isRequired: '',
    verificationMethod: 0,
    verificationStatus: 0,
    comment: "",
    isNotifyFlag: false,
  };

  return data;
}

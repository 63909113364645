import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICAQHSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/CAQH";

const initialState = {
  caqhSetupDetails: {} as ICAQHSetupDetailsModel,
  error: null as any,
  loading: false,
};

const caqhSetupSlice = createSlice({
  name: "amaSetup",
  initialState,
  reducers: {
    updateCAQHSetupDetails: (
      state,
      action: PayloadAction<ICAQHSetupDetailsModel>
    ) => {
      state.caqhSetupDetails = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.caqhSetupDetails = {} as ICAQHSetupDetailsModel;
    },
  },
});

export const caqhSetupActions = caqhSetupSlice.actions;
export default caqhSetupSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IHospAffil_HospitalPrivilegesModel } from '@helpers/interfaces/credentialProfile/HospitalAffiliations';
import { setDefaultHospitalPrivileges } from '@helpers/configMiddlewares/credentialingProfile/hospitalAffilicationsTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  hospitalPrivilegesData: {} as IHospAffil_HospitalPrivilegesModel,
  hospitalPrivilegesDataList: [] as Array <IHospAffil_HospitalPrivilegesModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const hospitalPrivilegesSlice = createSlice({
  name: 'hospitalPrivileges',
  initialState,
  reducers: {

    updateHospitalPrivilegesData: (state, action: PayloadAction<IHospAffil_HospitalPrivilegesModel>) => {
      state.hospitalPrivilegesData = action.payload;
    },

    updateHospitalPrivilegesDataList: (state, action: PayloadAction<Array<IHospAffil_HospitalPrivilegesModel>>) => {
      state.hospitalPrivilegesDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorForm: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.hospitalPrivilegesData = setDefaultHospitalPrivileges(userRecord);
    }

  }
});

export const hospitalPrivilegesAction = hospitalPrivilegesSlice.actions;
export default hospitalPrivilegesSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_OptionalInfoModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultOptionalInfo } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  optionalInfoData: {} as IPerProf_OptionalInfoModel,
  error: null as any,
  loading: false
}

const optionalInfo = createSlice({
  name: 'optionalInfo',
  initialState,
  reducers: {

    updateOptionalInfoData: (state, action: PayloadAction<IPerProf_OptionalInfoModel>) => {
      state.optionalInfoData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.optionalInfoData = setDefaultOptionalInfo(userRecord);
      state.error = null;
      state.loading = false;
    }

  }
});

export const optionalInfoAction = optionalInfo.actions;
export default optionalInfo.reducer;
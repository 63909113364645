import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_StateLicenseModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultStateLicenseInfo } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  stateLicenseData: {} as IPerProf_StateLicenseModel,
  stateLicenseDataList: [] as Array <IPerProf_StateLicenseModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const stateLicenseSlice = createSlice({
  name: 'stateLicenseSlice',
  initialState,
  reducers: {

    updateStateLicenseData: (state, action: PayloadAction<IPerProf_StateLicenseModel>) => {
      state.stateLicenseData = action.payload;
    },

    updateStateLicenseDataList: (state, action: PayloadAction<Array<IPerProf_StateLicenseModel>>) => {
      state.stateLicenseDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.stateLicenseData = setDefaultStateLicenseInfo(userRecord);
    }

  }
});

export const stateLicenseAction = stateLicenseSlice.actions;
export default stateLicenseSlice.reducer;
import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";
import messages from "@constants/messages";

import {
  IActivityModel,
  IHideUnhideActivityParamsModel,
} from "@helpers/interfaces/activityChecklist/Activity";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { activityActions } from "@features/activityChecklist/Activity";

import {
  handleCreateServiceResponse,
  handleOtherServiceResponses,
  handleServerInteractionError,
} from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(activityActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(activityActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(activityActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(activityActions.updateEditorLoading(false));
}

export const getActivityData =
  (activityId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showEditorLoading(dispatch);

      try {
        const response = await axiosClient.get(
          "api/ManageDocuments/GetActivity?ActivityId=" + activityId
        );
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(activityActions.updateActivityData(response.data));
          resolve(response);
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: data.title,
              type: toastTypes.DANGER,
            })
          );
        }

        hideEditorLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const getAllActivities =
  (
    pageNumber: number,
    pageSize: number,
    includeHidden: boolean,
    search?: string
  ) =>
  async (dispatch: any) => {
    const hiddenValue = includeHidden ? "IncludeHidden" : "ExcludeHidden";

    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        let url = `api/ManageDocuments/GetActivityPageable?PageNumber=${pageNumber}&PageSize=${pageSize}&IncludeHidden=${hiddenValue}`;

        if (search) {
          url += `&Search=${search}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(activityActions.updateActivityListData(data));

          resolve(response);
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const createActivity =
  (data: IActivityModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showEditorLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/ManageDocuments/CreateActivity",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          activityActions,
          status,
          response.data
        );

        hideEditorLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideEditorLoading(dispatch);
      }
    });
  };

export const hideUnhideActivity =
  (data: IHideUnhideActivityParamsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/ManageDocuments/HideUnhideActivity",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleOtherServiceResponses(
          dispatch,
          status,
          response.data,
          messages.statusChangedSuccessfully
        );

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

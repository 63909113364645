const statusTypes = [
  {
    label: 'Active',
    value: '1'
  },
  {
    label: 'Inactive',
    value: '0'
  },
  {
    label: 'All',
    value: 'All'
  },
];

export default statusTypes;
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

import { IFormDateControlProps } from "helpers/uiInterfaces/form-controls";
import { isValidDate } from "@utilities/index";

const FormDateInputControl: React.FC<IFormDateControlProps> = (props) => {
  const {
    label,
    name,
    value,
    maxDate,
    disabled,
    required,
    error,
    minDate,
    onChange,
    onBlur,
    onFocus,
  } = props;

  const [internalError, setInternalError] = useState<string | null>(null);
  const [internalValue, setInternalValue] = useState<string>(value || "");

  function renderErrorMessage() {
    if (!error && !internalError) {
      return null;
    }

    return <label className="error-message">{error || internalError}</label>;
  }

  function renderAsterisk() {
    if (!required) {
      return;
    }

    return <FontAwesomeIcon icon={faAsterisk} className="required-asterisk" />;
  }

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    setInternalValue(newValue);

    if (newValue === "") {
      onChange(null);
      setInternalError(null);
    } else if (!isValidDate(newValue)) {
      setInternalError(
        "Invalid date. Please enter a valid date after 1900 in the format YYYY-MM-DD."
      );
    } else {
      setInternalError(null);
      onChange(newValue);
    }
  };

  const inputControlAttributes = {
    type: "date",
    className: error || internalError ? "error" : "",
    name,
    max: maxDate,
    min: minDate,
    disabled: disabled ? true : false,
    value:  value || internalValue,
    onChange(e: any) {
      handleChange(e);
    },
    onBlur,
    onFocus,
  };

  return (
    <div className="form-group">
      <div className="lbl">
        <label className="d-flex align-center">
          {renderAsterisk()} {label}
        </label>
      </div>
      <input {...inputControlAttributes} />
      {renderErrorMessage()}
    </div>
  );
};

export default FormDateInputControl;

import React from "react";

import { IFormRadioControlsProps } from "helpers/uiInterfaces/form-controls";
import styles from "./FormRadioControl.module.scss";

const radioData = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

const FormRadioControl: React.FC<IFormRadioControlsProps> = (props) => {
  const { label, name, value, data, onChange, columnDisplay, displayInWrap } =
    props;

  function handleInputControlChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { name, value } = event.target;
    onChange(name, value);
  }

  function renderRadioControl(item: any, index: number) {
    const radioControlAttributes = {
      type: "radio",
      name,
      value: item.value,
      checked: value === item.value ? true : false,
      onChange: handleInputControlChange,
    };

    const showInColumn =
      displayInWrap && columnDisplay
        ? `col-md-4 col-sm-12 col-xs-12 d-inline-table ${styles.inlineTable}`
        : columnDisplay
        ? "d-flex justifyItems-baseline flexDirection-row mb-3"
        : "d-flex"; // To show Radio buttons in column

    return (
      <label key={index} className={showInColumn}>
        <input {...radioControlAttributes} style={{ width: "auto" }} />
        <span className="ms-2 me-4">{item.label}</span>
      </label>
    );
  }

  const _data = typeof data === "undefined" ? radioData : data;
  const radioWidth = columnDisplay ? "w-0" : "form-group"; // To manage width of radio buttons in column display

  return (
    <div className={radioWidth} id={styles.radioControl}>
      <div className="lbl">
        <label>{label}</label>
      </div>
      <div className={columnDisplay ? "" : styles.radioControlContainer}>
        {_data.map((item, index) => renderRadioControl(item, index))}
      </div>
    </div>
  );
};

export default FormRadioControl;

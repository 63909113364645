import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProfMedical_PracticeInterestsModel } from '@helpers/interfaces/credentialProfile/ProfMedical';
import { setDefaultPracticeInterestsData } from '@helpers/configMiddlewares/credentialingProfile/profMedicalSpecialtyTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  practiceInterestsData: {} as IProfMedical_PracticeInterestsModel,
  error: null as any,
  loading: false
}

const practiceInterests = createSlice({
  name: 'practiceInterests',
  initialState,
  reducers: {

    updatePracticeInterest: (state, action: PayloadAction<IProfMedical_PracticeInterestsModel>) => {
      state.practiceInterestsData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.error = null;
      state.loading = false;
      state.practiceInterestsData = setDefaultPracticeInterestsData(userRecord);
    }

  }
});

export const practiceInterestsActions = practiceInterests.actions;
export default practiceInterests.reducer;
import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import {
  ITrainingDetailsModel,
  IUnderGraduateModel,
  IUSCanadianModel
} from "@helpers/interfaces/credentialProfile/EduTrainTab";

export function setDefaultUndergraduateRecord(userRecord: IUserRecordModel): IUnderGraduateModel {

  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    ugSchoolAddress: '',
    ugSchoolCity: 0,
    ugSchoolCountry: 0,
    ugSchoolDegreeAwarded: '',
    ugSchoolDegreeCompleted: false,
    ugSchoolEndDate: null,
    ugSchoolFax: '',
    ugSchoolName: '',
    ugSchoolStartDate: null,
    ugSchoolState: 0,
    ugSchoolTelephone: '',
    ugSchoolZip: '',
    programDirectorName: '',
    programDirectorEmail: ''
  };

  return data;
}

export function setDefaultUSCanadianData(_userRecord: IUserRecordModel): IUSCanadianModel {

  const data = {
    hrmrUserId: _userRecord.hrmrUserId,
    ucSchooId: 0,
    ucSchoolCode: '',
    ucSchoolName: '',
    ucSchoolAddress: '',
    ucSchoolCity: 0,
    ucSchoolState: 0,
    ucSchoolCountry: 0,
    ucSchoolPostal: '',
    ucSchoolStartDate: null,
    ucSchoolEndDate: null,
    ucSchoolDegreeAwarded: '',
    ucSchoolDegreeCompleted: false,
    ucSchoolDirectorName: '',
    ucSchoolDirectorEmail: '',
  };

  return data;
}

export function setDefaultTrainingDetails(_userRecord: IUserRecordModel): ITrainingDetailsModel {

  const data = {
    trainingId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    institutionName: '',
    trainingType: '',
    startDate: null,
    endDate: null,
    departmentORSpecialty: '',
    directorName: '',
    directorEmail: '',
    country: 0,
    state: 0,
    city: 0,
    number: '',
    street: '',
    building: '',
    zip: '',
    telephone: '',
    fax: '',
    completeTrainingProgram: false,
    isDelete: 0,
    seqNo: 0
  };

  return data;
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IServSiteOpenPracticeStatusModel } from '@helpers/interfaces/serviceSites/OpenPracticeStatus';
import { setDefaultOpenPracticeStatus } from '@helpers/configMiddlewares/serviceSite';

const initialState = {
  openPracticeStatusData: {} as IServSiteOpenPracticeStatusModel,
  error: null as any,
  loading: false
}

const openPracticeStatusSlice = createSlice({
  name: 'openPracticeStatus',
  initialState,
  reducers: {

    updateOpenPracticeStatusData: (state, action: PayloadAction<IServSiteOpenPracticeStatusModel>) => {
      state.openPracticeStatusData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.openPracticeStatusData = setDefaultOpenPracticeStatus();
    }

  }
});

export const openPracticeStatusAction = openPracticeStatusSlice.actions;
export default openPracticeStatusSlice.reducer;
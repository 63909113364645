import { combineReducers } from "@reduxjs/toolkit";

import NPDBSetupReducer from "./NPDB";
import AMASetupReducer from "./AMA";
import VeritableScreeningSetupReducer from "./VeritableScreening";
import DocuSignSetupReducer from "./DocuSign";
import ComplianceWatchdog from "./ComplianceWatchdog";
import CAQHReducer from "./CAQH";

const setupIntegrationsReducers = combineReducers({
  npdbSetup: NPDBSetupReducer,
  amaSetup: AMASetupReducer,
  veritableScreeningSetup: VeritableScreeningSetupReducer,
  docuSignSetup: DocuSignSetupReducer,
  complianceWatchdogSetup: ComplianceWatchdog,
  caqhSetup: CAQHReducer,
});

export default setupIntegrationsReducers;

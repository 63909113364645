import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import {
  IClinicalWorkHistoryModel,
  IGapHistoryModel,
  IMilitaryDutyModel,
  IProfessionalReferencesModel,
} from "@helpers/interfaces/credentialProfile/WorkHistoryRefTab";

export function setDefaultMilitaryDutyData(
  userRecord: IUserRecordModel
): IMilitaryDutyModel {
  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    militaryReserve: false,
  };

  return data;
}

export function setDefaultClinicalHistoryData(
  _userRecord: IUserRecordModel
): IClinicalWorkHistoryModel {
  const data = {
    workHistoryId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    employerName: "",
    number: "",
    street: "",
    building: "",
    countryCode: 0,
    state: 0,
    city: 0,
    zipCode: "",
    telephone: "",
    fax: "",
    startDate: null,
    endDate: null,
    currentWorkPlace: false,
    reasonForDeparture: "",
    email: "",
  };

  return data;
}

export function setDefaultGapHistoryData(
  _userRecord: IUserRecordModel
): IGapHistoryModel {
  const data = {
    gapHistoryId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    gapStartDate: null,
    gapEndDate: null,
    explain: "",
  };

  return data;
}

export function setDefaultProfessionalReferencesData(
  _userRecord: IUserRecordModel
): IProfessionalReferencesModel {
  const data = {
    professionalReferencesId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    lastName: "",
    firstName: "",
    providerType: "",
    specialtyCode: "",
    professionalRelationship: "",
    organizationName: "",
    number: "",
    street: "",
    building: "",
    country: 0,
    state: 0,
    city: 0,
    zipCode: "",
    telephone: "",
    fax: "",
    referenceEmail: "",
  };

  return data;
}

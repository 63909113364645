import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IExistingPracticeLocationDataModel, IPracticeLocationModel } from '@helpers/interfaces/credentialProfile/PracticeLocationInfoTab';
import { setDefaultPracticeLocationInfo } from '@helpers/configMiddlewares/credentialingProfile/practLocationInfoTab';

import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  practiceLocationData: {} as IPracticeLocationModel,
  practiceLocationListData: [] as Array<IPracticeLocationModel>,
  error: null as any,
  loading: false,
  editorLoading: false
}

const practiceLocationSlice = createSlice({
  name: 'practiceLocation',
  initialState,
  reducers: {

    updatePracticeLocationData: (state, action: PayloadAction<IPracticeLocationModel>) => {
      state.practiceLocationData = action.payload;
    },

    updatePracticeLocationListData: (state, action: PayloadAction<Array<IPracticeLocationModel>>) => {
      state.practiceLocationListData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      const userRecord = getApplicationStorage();
      state.editorLoading = false;
      state.error = null;
      state.practiceLocationData = setDefaultPracticeLocationInfo(userRecord);
    }

  }
});

export const practiceLocationAction = practiceLocationSlice.actions;
export default practiceLocationSlice.reducer;
import React from "react";

import { INoContentSectionProps } from "helpers/uiInterfaces/common";

import noDataImage from "@images/sorry-image.png";

import styles from "./NoContentSection.module.scss";

const NoContentSection: React.FC<INoContentSectionProps> = (props) => {
  const {
    message,
    buttonText,
    disableButton,
    onClick,
    notApplicable,
    onNotApplicableClick,
    sectionName,
    showNotApplicableMsg,
  } = props;

  function renderAddHereControl() {
    if (!onClick) {
      return;
    }

    const _buttonText = buttonText ? buttonText : "Add Here";

    const addHereControlAttributes = {
      className: `btn btnorg w120  btn-space ${
        notApplicable ? styles.addHereWithNotApplicable : styles.addHereControl
      }`,
      disabled: disableButton ? disableButton : false,
      onClick,
    };

    const notApplicableControlAttributes = {
      className: `btn btnorg w120 ${styles.addHereControl}`,
      onClick() {
        onNotApplicableClick && onNotApplicableClick();
      },
    };

    return (
      <div className="d-flex justify-content-center">
        <button {...addHereControlAttributes}>{_buttonText}</button>
        {notApplicable && (
          <div className={styles.notApplicableContainer}>
            <span className={styles.textSpace}>Or</span>
            <button {...notApplicableControlAttributes}>Not Applicable</button>
          </div>
        )}
      </div>
    );
  }

  const _message = message ? message : "No data available";

  const noDataImageAttributes = {
    className: styles.noDataImage,
    src: noDataImage,
  };

  return (
    <div>
      <div className={styles.noContentSectionMain}>
        <img {...noDataImageAttributes} alt="no-data-image" />
        <label className={styles.noContentMessage}>{_message}</label>
        {showNotApplicableMsg && (
          <div className={styles.credSectionName}>
            {sectionName}: Not Applicable
          </div>
        )}
        {renderAddHereControl()}
      </div>
    </div>
  );
};

export default NoContentSection;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPerProf_GeneralInfoModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';
import { setDefaultGeneralInfo } from '@helpers/configMiddlewares/credentialingProfile/perProfInfoTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  generalInfoData: {} as IPerProf_GeneralInfoModel,
  error: null as any,
  loading: false
}

const generalInfoSlice = createSlice({
  name: 'generalInfo',
  initialState,
  reducers: {

    updateGeneralInfo: (state, action: PayloadAction<IPerProf_GeneralInfoModel>) => {
      state.generalInfoData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.generalInfoData = setDefaultGeneralInfo(userRecord);
    }

  }
});

export const generalInfoAction = generalInfoSlice.actions;
export default generalInfoSlice.reducer;
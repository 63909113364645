import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";

export function getCheckboxSelectButtonConfigurations(data: Array<ICheckboxSelectDataStructure>) {

  const startingFourSelectedValues = [];
  let selectedItemsCount = 0;

  for (let item of data) {

    if (item.selected === false) {
      continue;
    }

    // storing only starting four values into an array
    if (startingFourSelectedValues.length < 4) {
      startingFourSelectedValues.push(item.label);
    }

    selectedItemsCount += 1;
  }

  return {
    selectedItemsCount: selectedItemsCount,
    totalItemsCount: data.length,
    startingFourSelectedValues: startingFourSelectedValues.join(', ')
  };

}

export function handleSelectAllDataParsing(data: Array<ICheckboxSelectDataStructure>, selectAllDefaultValue: boolean) {

  const parsedData = [];

  for (let item of data) {

    const _item = {
      ...item,
      selected: !selectAllDefaultValue
    };

    parsedData.push(_item);
  }

  return parsedData;
}

export function handleItemSelectDataParsing(data: Array<ICheckboxSelectDataStructure>, index: number) {

  const _data = [...data];

  _data[index].selected = !_data[index].selected;

  return _data;
}
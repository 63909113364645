import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMilitaryDutyModel } from '@helpers/interfaces/credentialProfile/WorkHistoryRefTab';
import { setDefaultMilitaryDutyData } from '@helpers/configMiddlewares/credentialingProfile/workHistoryRefTab';
import { getApplicationStorage } from '@utilities/storage';

const initialState = {
  militaryDutyData: {} as IMilitaryDutyModel,
  error: null as any,
  loading: false
}

const militaryDuty = createSlice({
  name: 'militaryDuty',
  initialState,
  reducers: {

    updateMilitaryDutyData: (state, action: PayloadAction<IMilitaryDutyModel>) => {
      state.militaryDutyData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      const userRecord = getApplicationStorage();
      state.loading = false;
      state.error = null;
      state.militaryDutyData = setDefaultMilitaryDutyData(userRecord);
    }

  }
});

export const militaryDutyActions = militaryDuty.actions;
export default militaryDuty.reducer;
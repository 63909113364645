import { combineReducers } from '@reduxjs/toolkit';

import CredentialProfileReducers from './CredentialProfile';
import PerProfInfoReducer from './perProfInfoTab/PerProfInfoReducers';
import EduTrainReducers from './eduTrainTab/EduTrainReducers';
import ProfMedicalSpecialtyReducers from './profMedicalSpecialtyTab/ProfMedicalSpecialtyReducers';
import PracticeLocationInfoReducers from './practiceLocationInfoTab/PracticeLocationInfoReducers';
import HospitalAffiliationsReducers from './hospitalAffiliations/HospitalAffiliationsReducers';
import ProfLiabilityInsuranceReducers from './profLiabilityInsuranceTab/ProfLiabilityInsuranceReducers';
import WorkHistoryRefReducers from './workHistoryRefTab/WorkHistoryRefReducers';
import DisclosuresReducer from './disclosures/DisclosuresReducers';

const credentialProfileReducer = combineReducers({
  credentialP: CredentialProfileReducers,
  perProf: PerProfInfoReducer,
  eduTrain: EduTrainReducers,
  profMedicalSpecialty: ProfMedicalSpecialtyReducers,
  practiceLocationInfo: PracticeLocationInfoReducers,
  hospitalAffiliations: HospitalAffiliationsReducers,
  profLiabilityInsuranceCarrier: ProfLiabilityInsuranceReducers,
  workHistoryReference: WorkHistoryRefReducers,
  disclosuresReducer: DisclosuresReducer
});

export default credentialProfileReducer;
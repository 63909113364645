import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import credentialProfileTabIds from "@constants/credential-profile-tab-ids";

const initialState = {
  currentTabIndex: credentialProfileTabIds.perProfInfoTab as number,
  firstManipulation: true as boolean,
};

const credentialProfileTabs = createSlice({
  name: "credentialProfileTabs",
  initialState,
  reducers: {
    updateCurrentTabId: (state, action: PayloadAction<number>) => {
      state.currentTabIndex = action.payload;
    },

    updateFirstManipulation: (state, action: PayloadAction<boolean>) => {
      state.firstManipulation = action.payload;
    },

    resetTabIndexState: (state) => {
      state.currentTabIndex = credentialProfileTabIds.perProfInfoTab;
    },
  },
});

export const credentialProfileTabsActions = credentialProfileTabs.actions;
export default credentialProfileTabs.reducer;

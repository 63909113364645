import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import { IProfMedical_CertificationsModel, IProfMedical_PracticeInterestsModel, IProfMedical_SpecialtyModel } from "@helpers/interfaces/credentialProfile/ProfMedical";

export function setDefaultMedicalSpecialtyData(_userRecord: IUserRecordModel): IProfMedical_SpecialtyModel {

  const data = {
    certificationsId: 0,
    hrmrUserId: _userRecord.hrmrUserId,
    displayName: '',
    taxonomyCode: '',
    isPrimary: false,
    specialtyName: '',
    boardCertified: false,
    resultPending: false,
    sitFonAnExam: false,
    certifyingBoardExam: false,
    certifyingBoardCodeExplain: '',
    intialCertificationDate: null,
    recertificationDate: null,
    expirationDate: null,
    certificationNumber: '',
    boardStatus: '',
    boardName: ''
  };

  return data;
}

export function setDefaultCertificationsData(userRecord: IUserRecordModel): IProfMedical_CertificationsModel {

  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    basicLifeSupport: false,
    basicLifeSupportIssueDate: null,
    basicLifeSupportExpDate: null,
    advLifeSupportInOB: false,
    advLifeSupportInOBIssueDate: null,
    advLifeSupportInOBExpDate: null,
    cpr: false,
    cprIssueDate: null,
    cprExpDate: null,
    advTraumaLifeSupport: false,
    advTraumaLifeSupportIssueDate: null,
    advTraumaLifeSupportExpDate: null,
    advCardacLifeSPT: false,
    advCardacLifeSPTIssueDate: null,
    advCardacLifeSPTExpDate: null,
    pediatricAdvancedLifeSPT: false,
    pediatricAdvancedLifeSPTIssueDate: null,
    pediatricAdvancedLifeSPTExpDate: null,
    neonatalAdvancedLifeSPT: false,
    neonatalAdvancedLifeSPTIssueDate: null,
    neonatalAdvancedLifeSPTExpDate: null
  };

  return data;
}

export function setDefaultPracticeInterestsData(userRecord: IUserRecordModel): IProfMedical_PracticeInterestsModel {

  const data = {
    hrmrUserId: userRecord.hrmrUserId,
    practiceInterests: ''
  };

  return data;
}